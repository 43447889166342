import * as React from 'react';
import '../Style.css';

import logo from "../images/wyzth-swap-beta-white-01.png";
import video from "../images/finalout.mp4";

import { FiTwitter,FiGithub,FiFacebook,FiLinkedin } from 'react-icons/fi';
import { FaHandshake } from 'react-icons/fa';
import money from '../images/save-money1.gif';
import time from '../images/save-time1.gif';
import exchange from '../images/exchange1.gif';
import { MotionAnimate } from 'react-motion-animate'

import { RiFileList3Line,RiShieldCheckLine } from 'react-icons/ri';
import { MdOutlineLibraryBooks,MdOutlineEditNote } from 'react-icons/md';
import { BsPlayBtn,BsQuestionDiamond } from 'react-icons/bs';


class Banner extends React.Component {

    render() {
        return (

            <div className="home-banner-section-top">
                <video autoPlay={true} muted loop={true} id="myVideo" >
                    <source src={video} type="video/mp4" />
                </video>
                <div className="home-banner-section">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <div className="container-fluid col-lg-10 col-11 pt-lg-3">
                            <a className="navbar-brand" href="/"><img src={logo} alt="logo" /></a>
                            <div className="button-container-2 mt-lg-0 mt-3">
                                <span className="mas">Launch</span>
                                <a href="/swap" type="button" name="Hover">Launch</a>
                            </div>
                        </div>
                    </nav>

                    <section>
                        <div className='container-fluid col-lg-10 col-11'>
                            <div className="row align-items-start g-4 justify-content-center text-center min-vh-100">
                                <div className="col-lg-7 top-banner-section pb-lg-0 pb-5">
                                    <p className="hero-banner-section-1">Be a DeFi Chef with whitelabel</p>
                                    <p className="hero-banner-section-2 mt-3 mb-5">Swap, earn, stack yields, lend, borrow, leverage all on one decentralized, community driven platform. Welcome home to DeFi.</p>
                                    <a className="nav-link launch-button-herobanner" href="/swap"><img src={"https://cdn3d.iconscout.com/3d/premium/thumb/usdc-coin-4722958-3917984.png"} alt="logo" className='launch-button-herobanner-image-1' /><img src={"https://cdn3d.iconscout.com/3d/premium/thumb/usdc-coin-4722958-3917984.png"} alt="logo" className='launch-button-herobanner-image-2' />Launch</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <section>
                    <div className='container container-two-content-styling'>
                        <h1 className='heading-before-after'>Easily Swap</h1>
                        <div className="boxes row row-cols-lg-3 row-cols-1">
                            <MotionAnimate animation='fadeInUp' reset={true}>
                                <div className="box mx-3 mx-lg-0 col mt-lg-0 mt-5 m-0">
                                    <img src="https://cdn3d.iconscout.com/3d/premium/thumb/bitcoin-4059079-3364002.png" className='second-section-card-images-1' alt="logo" />
                                    <h2>BTC</h2>
                                    <p className="piece-count">$ 1719.42</p>
                                    <a href="/swap">
                                     <button className='mb-4 swap-button-card'>Swap BTC</button>
                                    </a>
                                    {/* <p className='piece-count-para'>
                                    The King can move just one square at a time in any direction. It should be defended at all times by the other pieces as once checkmated the game is lost.
                                </p> */}
                                </div>
                            </MotionAnimate>
                            <MotionAnimate animation='fadeInUp' reset={true}>
                                <div className="box mx-3 mx-lg-0 col mt-lg-0 mt-5 m-0">
                                    {/* <FaEthereum className="icon" /> */}
                                    <img src="https://cdn3d.iconscout.com/3d/premium/thumb/ethereum-4059136-3364022.png" className='second-section-card-images-1' alt="logo" />

                                    <h2>ETH</h2>
                                    <p className="piece-count">$ 23179.00</p>
                                    <a href="/swap">
                                        <button className='mb-4 swap-button-card'>Swap ETH</button>
                                    </a>
                                    {/* <p className='piece-count-para'>
                                    The King can move just one square at a time in any direction. It should be defended at all times by the other pieces as once checkmated the game is lost.
                                </p> */}
                                </div>
                            </MotionAnimate>
                            <MotionAnimate animation='fadeInUp' reset={true}>
                                <div className="box mx-3 mx-lg-0 col mt-lg-0 mt-5 m-0">
                                <img src="https://cdn3d.iconscout.com/3d/premium/thumb/binance-coin-4999112-4160024.png" className='second-section-card-images-2' alt="logo" />

                                    {/* <BsCoin className="icon" /> */}
                                    <h2>BNB</h2>
                                    <p className="piece-count">$ 1719.42</p>
                                    <a href="/swap">
                                        <button className='mb-4 swap-button-card'>Swap BNB</button>
                                    </a>
                                    {/* <p className='piece-count-para'>
                                    The King can move just one square at a time in any direction. It should be defended at all times by the other pieces as once checkmated the game is lost.
                                </p> */}
                                </div>
                            </MotionAnimate>
                        </div>
                    </div>
                </section>

                <section className='home-card-section py-5'>
                    <div className='container-fluid col-lg-10 col-11'>
                        <h1 className='heading-before-after mb-5 text-center'>Features</h1>
                        {/* <h1 className='top-hero-section-heading text-center mb-5'><img src="https://aura.network/assets/imgs/pages/dashboard/Stars.png" className='top-hero-section-star-image' alt="home-img-1" />Features</h1> */}

                        <div className="row align-items-center justify-content-center g-4 ">
                            <div className="col-lg-5">
                                <img src="https://alcor.trade/core/templates/public/img/antares-cryptobot-1.png" alt="home-img-1" />
                            </div>
                            <div className="col-lg-5">
                                <div className="row row-cols-1 row-cols-lg-1 g-4">
                                    <div className="col">
                                        <MotionAnimate animation='fadeInUp' reset={true}>

                                            <div className="card">
                                                <div className="card-body">
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <h5 className="card-title">Security</h5>
                                                            <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p>
                                                        </div>
                                                        <div className='col-3'>
                                                            <img src="https://cdn3d.iconscout.com/3d/premium/thumb/safety-3274369-2732765@0.png" alt="home-img-1" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </MotionAnimate>
                                    </div>
                                    <div className="col ml-150 mt-lg-5">
                                        <MotionAnimate animation='fadeInUp' reset={true}>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className='row'>

                                                        <div className='col'>
                                                            <h5 className="card-title">Privacy</h5>
                                                            <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p>
                                                        </div>
                                                        <div className='col-3'>
                                                            <img src="https://cdn3d.iconscout.com/3d/premium/thumb/cloud-lock-4065319-3361351.png" alt="home-img-1" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </MotionAnimate>
                                    </div>
                                    <div className="col mt-lg-5">
                                        <MotionAnimate animation='fadeInUp' reset={true}>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <h5 className="card-title">Maintenance</h5>
                                                            <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p>
                                                        </div>
                                                        <div className='col-3'>
                                                            <img src="https://cdn3d.iconscout.com/3d/premium/thumb/restore-4721389-3927970.png" alt="home-img-1" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </MotionAnimate>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='py-5'>
                    <div className='container'>
                        <h1 className='heading-before-after mb-5 text-center'>Heading</h1>

                        {/* <h1 className='top-hero-section-heading text-center mb-5'><img src="https://aura.network/assets/imgs/pages/dashboard/Stars.png" className='top-hero-section-star-image' alt="home-img-1" />Heading</h1> */}
                        <div className='row row-cols-lg-4 ps-3 justify-content-evenly align-items-center'>
                            <div className='col-lg-4 ps-lg-5'>
                                <MotionAnimate animation='fadeInUp' reset={true}>

                                    <div className='row two-row-1'>
                                        <h1 className='two-row-heading-style'>01</h1>
                                        <p className='two-row-heading-para-style'>Buy & Sell Online 24/7</p>
                                        <p className='two-row-para-style'>
                                            Our proprietary platform enables you to self-trade crypto anytime, anywhere, so you can take action right when the market moves.
                                        </p>
                                    </div>
                                    <div className='row two-row-2'>
                                        <h1 className='two-row-heading-style'>02</h1>
                                        <p className='two-row-heading-para-style'>Up To $700M² Custody Insurance</p>
                                        <p className='two-row-para-style'>
                                            Assets are insured for up to $700 million with BitGo Trust and their insurance provider Lloyd’s of London, the world’s specialist insurance and reinsurance market.                                    </p>
                                    </div>
                                </MotionAnimate>
                            </div>
                            <div className='col'>
                                <div className='row'>
                                    <img src={"https://1inch.io/img/wallet/defiEcosystem/swap.png"} alt="home-img-1" className="mobile-image-styling-banner-page" />
                                </div>

                            </div>
                            <div className='col-lg-4 ps-lg-5'>
                                <MotionAnimate animation='fadeInUp' reset={true}>

                                    <div className='row two-row-1'>
                                        <h1 className='two-row-heading-style'>03</h1>
                                        <p className='two-row-heading-para-style'>Tax-Free Investing</p>
                                        <p className='two-row-para-style'>
                                            One of the best advantages of investing with your IRA are the tax-breaks. Maximize your retirement growth in a tax-free IRA.
                                        </p>
                                    </div>
                                    <div className='row two-row-2'>
                                        <h1 className='two-row-heading-style'>04</h1>
                                        <p className='two-row-heading-para-style'>Price Tracking, Reports & More</p>
                                        <p className='two-row-para-style'>
                                            Our one-of-a-kind dashboard offers many features such as live price tracking, portfolio performance, videos, education and more.                                    </p>
                                    </div>
                                </MotionAnimate>
                            </div>
                        </div>
                    </div>
                </section>

                <section className=' py-5'>
                    <div className='container'>
                        <h1 className='heading-before-after mb-5 text-center'>Heading</h1>

                        {/* <h1 className='top-hero-section-heading text-center mb-5'><img src="https://aura.network/assets/imgs/pages/dashboard/Stars.png" className='top-hero-section-star-image' alt="home-img-1" />Heading</h1> */}

                        <div className='card-bottom-section row justify-content-center text-center'>
                            <div className='col-lg-4 col-12'>
                                <div className='row'>
                                    <h1>SAVE TIME</h1>
                                </div>
                                <div className='row'>
                                    <MotionAnimate animation='fadeInUp' reset={true}>
                                        <img src={time} alt="home-img-1" />
                                    </MotionAnimate>
                                </div>
                                <div className='row'>
                                    <p className='mt-4'>Maximum exchange speed due to the full automation</p>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12 my-lg-0 my-5'>
                                <div className='row'>
                                    <h1>SAVE MONEY</h1>
                                </div>
                                <div className='row'>
                                    <MotionAnimate animation='fadeInUp' reset={true}>
                                        <img src={money} alt="home-img-1" />
                                    </MotionAnimate>
                                </div>
                                <div className='row'>
                                    <p className='mt-4'>Best exchange rates and minimum commissions</p>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className='row'>
                                    <h1>MAKE AN EXCHANGE</h1>
                                </div>
                                <div className='row'>
                                    <MotionAnimate animation='fadeInUp' reset={true}>
                                        <img src={exchange} alt="home-img-1" />
                                    </MotionAnimate>
                                </div>
                                <div className='row'>
                                    <p className='mt-4'>Pick the right strategy and make favourable trades.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* =================================FOOTER====================================================== */}

                <section className='footer-bottom-section'>
                    <div className='container'>
                        <div className='row g-4 align-items-start'>
                            <div className='col-lg-4 col-12'>
                                <h2 className='footer-text-1'>Start Trading with</h2>
                                <img src={logo} alt="logo" />
                            </div>
                            <div className='col-lg-3 col-12 footer-community-column'>
                                <h1>Community</h1>
                                <a href="#" rel="noreferrer" target="_blank" className='footer-icons-heading'><FiTwitter className="footer-community-icons" /> Twitter</a>
                                <a href="#" rel="noreferrer" target="_blank" className='footer-icons-heading'><FiGithub className="footer-community-icons" /> GitHub</a>
                                <a href="#" rel="noreferrer" target="_blank" className='footer-icons-heading'><FiFacebook className="footer-community-icons" /> Facebook</a>
                                <a href="#" rel="noreferrer" target="_blank" className='footer-icons-heading'><FiLinkedin className="footer-community-icons" /> LinkedIn</a>
                            </div>
                            <div className='col-lg-3 col-12'>
                                <div className='col footer-community-column'>
                                    <h1>About</h1>
                                    <a href="#" rel="noreferrer" target="_blank" className='footer-icons-heading'><RiFileList3Line className="footer-community-icons" />Privacy policy</a>
                                    <a href="#" rel="noreferrer" target="_blank" className='footer-icons-heading'><FaHandshake className="footer-community-icons" />Terms of service</a>
                                    <a href="#" rel="noreferrer" target="_blank" className='footer-icons-heading'><MdOutlineLibraryBooks className="footer-community-icons" />User conduct code</a>
                                    <a href="#" rel="noreferrer" target="_blank" className='footer-icons-heading'><RiShieldCheckLine className="footer-community-icons" />Disclaimer</a>
                                </div>
                            </div>
                            <div className='col-lg-2 col-12'>
                                <div className='col footer-community-column'>
                                    <h1>Contact</h1>
                                    <a href="#" rel="noreferrer" target="_blank" className='footer-icons-heading'><MdOutlineEditNote className="footer-community-icons" />Blog</a>
                                    <a href="#" rel="noreferrer" target="_blank" className='footer-icons-heading'><BsPlayBtn className="footer-community-icons" />Video</a>
                                    <a href="#" rel="noreferrer" target="_blank" className='footer-icons-heading'><BsQuestionDiamond className="footer-community-icons" />FAQ</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        )
    }
}

export default Banner;