import React from 'react'
import '../Style.css';
import $ from "jquery";
import { AiOutlineSetting } from 'react-icons/ai';
import Wyzth from '../images/Wyzth-01.png';

import logo from "../images/wyzth-swap-beta-black-01.png";
import { CgArrowsExchange, CgPushChevronRightR } from 'react-icons/cg';
import { TbArrowAutofitRight, TbChartDots } from 'react-icons/tb';
import { BsWallet2, BsGrid1X2, BsBoxArrowRight, BsArrowDownRightSquare, BsArrowRepeat, BsBoxArrowUpRight } from 'react-icons/bs';
import { CgMenuRightAlt } from 'react-icons/cg';
import { BiBell } from 'react-icons/bi';
import { RiSendPlane2Line } from 'react-icons/ri';
import { IoMdAdd } from 'react-icons/io';
import { ImQrcode } from 'react-icons/im';
import { MdBluetoothSearching } from 'react-icons/md';
import { TbPlaylistAdd } from 'react-icons/tb';
import { VscChromeClose, VscCircleLargeFilled } from 'react-icons/vsc';
import { AiFillCopy,AiOutlineCopy } from 'react-icons/ai';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Web3 from 'web3'; 
import { AwesomeQR } from "awesome-qr";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import axios from 'axios'
import ether from '../images/ethereum.png'

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


const LightTooltip = styled(({ className, ...props }) => (<
    Tooltip {...props}
    classes={
        { popper: className }}
/>
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));


class Swap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ethaccountaddress:'',
            maticaccountaddress:'',
            bscaccountaddress:'',
            setchainfunction:'',
            data:"",
            accountAddress:"",
            url:"https://etherscan.io/address/",
            wyzthprice:"",
            wyzthfixed:""
        }
    }
    networkcheck= async () => {
        if(window.location.hash==="#matic"){
            this.bsctomatic();        
        }
        else if(window.location.hash==="#eth"){
            this.ethtobsc();
        }
        else if(window.location.hash==="#bsc"){
            this.metamask();
        }
   }

    componentDidMount() {
        this.showhidebtn();
        this.connectauto();
        this.priceapi();
        $(document).ready(function () {
            $(".eth-connect-wallet-button").addClass("connect-wallet-active");
            $(".eth-connect-wallet-button").click(function () {
                $(".eth-connect-wallet-button").addClass("connect-wallet-active");
                $(".bsc-connect-wallet-button").removeClass("connect-wallet-active");
                $(".wyzth-connect-wallet-button").removeClass("connect-wallet-active");
            });
            $(".bsc-connect-wallet-button").click(function () {
                $(".bsc-connect-wallet-button").addClass("connect-wallet-active");
                $(".eth-connect-wallet-button").removeClass("connect-wallet-active");
                $(".wyzth-connect-wallet-button").removeClass("connect-wallet-active");
            });
            $(".wyzth-connect-wallet-button").click(function () {
                $(".wyzth-connect-wallet-button").addClass("connect-wallet-active");
                $(".eth-connect-wallet-button").removeClass("connect-wallet-active");
                $(".bsc-connect-wallet-button").removeClass("connect-wallet-active");
            });
        });
    }

    priceapi = async() => {
        axios.get("https://api.etherscan.com/api?module=stats&action=ethprice&apikey=Q15HRHD2546QF1HIY155EPTAGPGPKANIJU").then(async(response)=> { 
            this.setState({
                wyzthfixed:response.data.result.ethusd
            })
        })
    }

    disconnectbutton =async() => {
        this.showhidebtn()
    }

    liquiditystaking = async (value) => {
        this.setState({
            setchainfunction:value
        })
    }

    connectauto = async() => {
        if(window.ethereum.chainId == '0x5') { 
            this.ethereum()
        } 
        if(window.ethereum.chainId == '0x13881') { 
            this.polygon()
        }
        if(window.ethereum.chainId == '0x61') {
            this.bsc()
        }
    }
    connectwallet = async () => {
        if (this.state.setchainfunction == 'ethereum') {
            this.ethereum();
            $("#connectbutton").hide();
            $("#connectedbutton").show(); 
        }
        else if (this.state.setchainfunction == 'polygon') {
            this.polygon();
            $("#connectbutton").hide();
            $("#connectedbutton").show(); 
        }
        else if (this.state.setchainfunction == 'bsc') {
            this.bsc();
            $("#connectbutton").hide();
            $("#connectedbutton").show(); 
        }
        else {
            $("#connectbutton").show();
            $("#connectedbutton").hide(); 
        }
    }

    ethereum = async () => {
        const chainId = 0x5;
        var web3js = new Web3(window.ethereum);
        const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
        if (currentChainId !== chainId) {
            try {
                window.location.hash = 'ethereum';
                var btn = document.getElementById("connectbutton");
                btn.innerHTML = 'Eth Network Connected';
                $("#connectbutton").hide();
                $("#connectedbutton").show(); 
                const currentChainId = await window.ethereum.request({ method: 'wallet_switchEthereumChain' , params: [{ chainId: web3js.utils.toHex(chainId) }] });
                const account = await window.ethereum.request({ method: 'eth_requestAccounts' });         
                var canvas = document.getElementById("stylized");
                var dataURL = canvas.toDataURL();
                $("#qrImgsrc").attr("src", dataURL);
                const imageurl = await new AwesomeQR({
                  text:account[0],
                  size:400,
                  whiteMargin:true,
                  autoColor:true,
                  dotScale:0.6
                }).draw()
                var ethurl = ("https://goerli.etherscan.io/address/"+account[0])       
                this.setState({
                  ethaccountaddress: account[0],
                  data:imageurl,
                  accountAddress:account[0],
                  url:ethurl
                })
            } catch (switchError) {
                if (switchError.code === 4902) {
                    Swal.fire('Please Add goerli Testnet Network to your Metamask')     
                }
            }
        }
    }

    polygon = async () => {
        const chainId = 0x13881;
        var web3js = new Web3(window.ethereum);
        const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
        if (currentChainId !== chainId) {
            try {
                var btn = document.getElementById("connectbutton");
                btn.innerHTML = 'polygon Network Connected';
                window.location.hash = 'polygon';
                $("#connectbutton").hide();
                $("#connectedbutton").show(); 
                const currentChainId = await window.ethereum.request({ method: 'wallet_switchEthereumChain' , params: [{ chainId: web3js.utils.toHex(chainId) }] });
                const account = await window.ethereum.request({ method: 'eth_requestAccounts' }); 
                var canvas = document.getElementById("stylized");
                var dataURL = canvas.toDataURL();
                $("#qrImgsrc").attr("src", dataURL);
                const imageurl = await new AwesomeQR({
                  text:account[0],
                  size:400,
                  whiteMargin:true,
                  autoColor:true,
                  dotScale:0.6
                }).draw()
                var maticurl = ("https://mumbai.polygonscan.com/"+account[0])
                this.setState({
                  maticaccountaddress: account[0],
                  data:imageurl,
                  accountAddress:account[0],
                  url:maticurl
                })
            } catch (switchError) {
                if (switchError.code === 4902) {
                    Swal.fire('Please Add polygon Testnet Network to your Metamask')     
                }
            }
        }
    }

    bsc = async () => {
        const chainId = 0x61;
        var web3js = new Web3(window.ethereum);
        const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
        if (currentChainId !== chainId) {
            try {
                var btn = document.getElementById("connectbutton");
                btn.innerHTML = 'Bsc Network Connected';
                window.location.hash = 'bsc';
                $("#connectbutton").hide();
                $("#connectedbutton").show(); 
                const currentChainId = await window.ethereum.request({ method: 'wallet_switchEthereumChain' , params: [{ chainId: web3js.utils.toHex(chainId) }] });
                const account = await window.ethereum.request({ method: 'eth_requestAccounts' });  
                var canvas = document.getElementById("stylized");
                var dataURL = canvas.toDataURL();
                $("#qrImgsrc").attr("src", dataURL);
                const imageurl = await new AwesomeQR({
                  text:account[0],
                  size:400,
                  whiteMargin:true,
                  autoColor:true,
                  dotScale:0.6
                }).draw()
                var bscurl = ("https://testnet.bscscan.com/address/"+account[0])
                this.setState({
                  bscaccountaddress: account[0],
                  data:imageurl,
                  accountAddress:account[0],
                  url:bscurl
                })
            } catch (switchError) {
                if (switchError.code === 4902) {
                    Swal.fire('Please Add bsc Testnet Network to your Metamask')     
                }
            }
        }
    }

    showhidebtn = () => {
        $("#connectbutton").show();
        $("#connectedbutton").hide();  
    }

    render() {
        return (
            <div className="App">
                <section className="home-banner-section">
                    <div className='container-fluid'>
                        <div className='row mx-auto justify-content-around'>
                            <div className='col-lg-2'>
                                <div id="mySidenav" className="sidenav">
                                    <div className='row'>
                                        <div className='col text-end'>
                                            <span className="closebtn-sidebar-navbar"><VscChromeClose className="closebtn-icons" /></span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col text-center'>
                                            <img src={logo} className="sidebar-logo-image" alt="logo"  />
                                        </div>
                                    </div>
                                    <span className="sidebar-trade-text">Trade</span>
                                    <ul className="nav sidebar-section flex-column mb-4">
                                        <li className="nav-item">
                                            <a className="nav-link sidebar-section-swap" href={"/swap#"+this.state.setchainfunction}><CgArrowsExchange className="sidebar-section-icons" />SWAP</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link sidebar-section-deposit" href={"/deposit#"+this.state.setchainfunction}><TbArrowAutofitRight className="sidebar-section-icons" />DEPOSIT</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link sidebar-section-liquidity" href={"/liquidity#"+this.state.setchainfunction}><TbChartDots className="sidebar-section-icons" />LIQUIDITY</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link sidebar-section-wallet" href={"/wallet#"+this.state.setchainfunction}><BsWallet2 className="sidebar-section-icons" />WALLET</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link sidebar-section-send" href={"/send#"+this.state.setchainfunction} ><CgPushChevronRightR className="sidebar-section-icons" />SEND</a>
                                        </li>
                                    </ul>

                                    <span className="sidebar-trade-text">Stats</span>
                                    <ul className="nav sidebar-section-2 flex-column">
                                        <li className="nav-item">
                                            <a className="nav-link sidebar-section-dashboard" href={"/dashboard#"+this.state.setchainfunction}><BsGrid1X2 className="sidebar-section-icons" />DASHBOARD</a>
                                        </li>
                                        {/* <li className="nav-item">
                                                <a className="nav-link sidebar-section-nodes" href="/nodes"><BsShieldCheck className="sidebar-section-icons" />WYZTHNode</a>
                                            </li> */}
                                        <li className="nav-item">
                                            <a className="nav-link sidebar-section-stake" href={"/stake#"+this.state.setchainfunction}><MdBluetoothSearching className="sidebar-section-icons" />STAKING</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className='col-lg-9 col-12 '>
                                <div className="top-navbar-section">
                                    <nav className="navbar navbar-expand-lg navbar-light ">
                                        <div className="container-fluid p-0">
                                            <span className="openbtn-sidebar-navbar"><CgMenuRightAlt className="openbtn-icons" /></span>

                                            <ul className="d-flex me-auto ms-lg-3 ms-2">
                                                <span className="nav-link top-navbar-value">1 ETH = ${this.state.wyzthfixed}</span>
                                                <span className="dropdown ms-lg-3 ms-2">
                                                    <button className="top-navbar-dropdown border-0 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <VscCircleLargeFilled className="top-navbar-dropdown-icon fc-y" />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li>
                                                            <a className="dropdown-item" href="#">
                                                                <div className="row align-items-center">
                                                                    <div className="col-2">
                                                                        <VscCircleLargeFilled className="top-navbar-dropdown-icon fc-g" />
                                                                    </div>
                                                                    <div className="col">
                                                                        <p className="dropdown-list-1">LIQUIDITY CAP</p>
                                                                        <p className="dropdown-list-2">FUNDS CAP AVAILABLE</p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="#">
                                                                <div className="row align-items-center">
                                                                    <div className="col-2">
                                                                        <VscCircleLargeFilled className="top-navbar-dropdown-icon fc-g" />
                                                                    </div>
                                                                    <div className="col">
                                                                        <p className="dropdown-list-1">BINANCE CHAIN</p>
                                                                        <p className="dropdown-list-2">online</p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="#">
                                                                <div className="row align-items-center">
                                                                    <div className="col-2">
                                                                        <VscCircleLargeFilled className="top-navbar-dropdown-icon fc-g" />
                                                                    </div>
                                                                    <div className="col">
                                                                        <p className="dropdown-list-1">POLYGON CHAIN</p>
                                                                        <p className="dropdown-list-2">online</p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="#">
                                                                <div className="row align-items-center">
                                                                    <div className="col-2">
                                                                        <VscCircleLargeFilled className="top-navbar-dropdown-icon fc-g" />
                                                                    </div>
                                                                    <div className="col">
                                                                        <p className="dropdown-list-1">ETH CHAIN</p>
                                                                        <p className="dropdown-list-2">online</p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </span>
                                            </ul>

                                            <ul className="d-flex ms-auto">
                                                <button className="wallet-button" data-bs-toggle="modal" data-bs-target="#connectwallet" id="connectbutton">Connect</button>
                                                <button className="wallet-button" data-bs-toggle="offcanvas" href="#offcanvasExample" aria-controls="offcanvasExample" id="connectedbutton">Connected</button>
                                                <LightTooltip title="Announcements" placement="bottom-start">
                                                    <span className="nav-link"><BiBell className="top-left-navbar-icons"/></span>
                                                </LightTooltip>
                                                <LightTooltip title="Global Settings" placement="bottom">
                                                    <span className="nav-link"><AiOutlineSetting className="top-left-navbar-icons"/></span>
                                                </LightTooltip>
                                                <LightTooltip title="No transaction in history" placement="bottom-end" >
                                                    <span className="nav-link"><TbPlaylistAdd className="top-left-navbar-icons"/></span>
                                                </LightTooltip>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="connected-wallet-select-section" className='swap-coin-select-section'>
                    <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasExampleLabel"></h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="row my-lg-3 mt-2 px-2">
                            <div className="col-6">
                                <span className='connected-text-1'>Wallet</span>
                                <LightTooltip title="Refresh Data" placement="bottom-start">
                                    <button className='connected-text-reload'><BsArrowRepeat className='connected-reload-button' /></button>
                                </LightTooltip>
                            </div>
                            <div className="col-6 text-end">
                                <LightTooltip title="Connect another wallet" placement="bottom-end">
                                    <button className='connected-text-add' data-bs-toggle="modal" data-bs-target="#connectwallet"><IoMdAdd className='connected-reload-button' /></button>
                                </LightTooltip>
                                <LightTooltip title="Connect another wallet" placement="bottom-end">
                                    <button className='connected-text-disconnect'><BsArrowRepeat className='connected-reload-button' /></button>
                                </LightTooltip>
                            </div>
                        </div>
                        <div className="offcanvas-body">
                            <div className="row connected-wallet-display-section align-items-center">
                                <div className="col-6">
                                    <LightTooltip title="Refresh Data" placement="top-start">
                                        <button className='connected-text-reload-2'><BsArrowRepeat /></button>
                                    </LightTooltip>
                                    <LightTooltip title="METAMASK Connected" placement="top-start">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png" className='connected-wallet-metamask' alt='metamask-logo' />
                                    </LightTooltip>
                                    <span className='connected-text-2'>Ethereum</span>
                                </div>
                                <div className="col-6 text-end">
                                    <span><label className='connected-text-3'>{this.state.accountAddress}</label>
                                        <LightTooltip title="Copy Address" placement="top-end">                                       
                                        <button className="connected-text-copy">
                                            <CopyToClipboard text={this.state.accountAddress}
                                                onCopy={() => this.setState({copied: true})}><AiFillCopy className='connected-copy-icon'/></CopyToClipboard>
                                        </button>
                                        </LightTooltip>
                                    </span>
                                    <span>
                                        <LightTooltip title="Show Qr Code" placement="top-end">
                                            <button className='connected-text-copy' data-bs-toggle="modal" data-bs-target="#connected-qrcode"> <ImQrcode className='connected-copy-icon' /></button>
                                        </LightTooltip>
                                    </span>
                                    <span>
                                        <LightTooltip title="Go to Account" placement="top-end">
                                         <a href={this.state.url} target="_blank" rel="noreferrer">
                                            <button className='connected-text-copy'> <BsBoxArrowUpRight className='connected-copy-icon' /></button>
                                         </a>
                                        </LightTooltip>
                                    </span>
                                    <span>
                                        <LightTooltip title="Disconnect" placement="top-end">
                                            <button className='connected-text-copy'> <BsBoxArrowRight className='connected-copy-icon' onClick={this.disconnectbutton}/></button>
                                        </LightTooltip>
                                    </span>
                                </div>
                            </div>

                            <div className="row g-4">
                                <div className="col-12 connected-wallet-token-section px-3 py-2">
                                    <div className="d-flex flex-row align-items-center text-start">
                                        <div>
                                            <img src="https://seeklogo.com/images/E/ethereum-logo-EC6CDBA45B-seeklogo.com.png" alt="eth-logo" />
                                        </div>
                                        <div className='ms-3'>
                                            <p className='connected-text-4'>ETH</p>
                                            <span className='connected-text-5'>Native</span>
                                        </div>
                                        <div className='ms-4'>
                                            <p className='connected-text-6'>0</p>
                                        </div>
                                        <div className='ms-auto'>
                                            <span className='connected-text-6'>
                                                <LightTooltip title="Send" placement="bottom-end">
                                                 <a href="/send">
                                                    <button className='connected-text-send'><RiSendPlane2Line className='connected-reload-button' /></button>
                                                 </a>
                                                </LightTooltip>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="connect-wallet-select-section" className='swap-coin-select-section'>
                    <div className="modal fade Swap-coin-select-section-modal" id="connectwallet" tabindex="-1" aria-labelledby="connectwalletLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header modal-header-heading border-0 px-0">
                                    <h5 className="swap-coin-select-text-1">Select a Chain</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body connect-wallet-section pb-lg-5 pt-lg-4">
                                    <div className="row g-4 justify-content-center">
                                        <div className="col-lg-4 col-6">
                                            <button className='eth-connect-wallet-button connect-wallet-active' onClick={()=>this.liquiditystaking("polygon")} >
                                                <div className="d-flex flex-row justify-content-center text-start">
                                                    <div className='mt-1'>
                                                        <img src="https://seeklogo.com/images/P/polygon-matic-logo-86F4D6D773-seeklogo.com.png" alt="eth-logo" />
                                                    </div>
                                                    <div className='ps-1'>
                                                        <p className='connect-wallet-text-1'>MATIC</p>
                                                        <span className='connect-wallet-text-2'>{this.state.maticaccountaddress?"Connected":"Not Connected"}</span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                            <button className='bsc-connect-wallet-button' onClick={()=>this.liquiditystaking("bsc")}>
                                                <div className="d-flex flex-row justify-content-center text-start">
                                                    <div className='mt-1'>
                                                        <img src="https://seeklogo.com/images/B/binance-coin-bnb-logo-CD94CC6D31-seeklogo.com.png" alt="eth-logo" />
                                                    </div>
                                                    <div className='ps-1'>
                                                        <p className='connect-wallet-text-1'>BSC</p>
                                                        <span className='connect-wallet-text-2'>{this.state.bscaccountaddress?"Connected":"Not Connected"}</span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                            <button className='wyzth-connect-wallet-button' onClick={()=>this.liquiditystaking("ethereum")}>
                                                <div className="d-flex flex-row justify-content-center text-start">
                                                    <div className='mt-1'>
                                                        <img src={ether} width="10%" alt="eth-logo" />
                                                    </div>
                                                    <div className='ps-1'>
                                                        <p className='connect-wallet-text-1'>ETH</p>
                                                        <span className='connect-wallet-text-2'>{this.state.ethaccountaddress?"Connected":"Not Connected"}</span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 pt-0">
                                    <button className="btn btn-dark-blue button btn-lg d-block" onClick={() => {
                                        this.connectwallet();
                                    }} >CONNECT WALLET</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="connect-wallet-qrcode-section">
                    <div className="modal fade" id="connected-qrcode" tabindex="-1" aria-labelledby="connected-qrcodeLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header modal-header-heading border-0 px-0">
                                    <h5 className="swap-coin-select-text-1"></h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body p-0">
                                <canvas id="stylized"></canvas>
                                <img src={this.state.data} className=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Swap;