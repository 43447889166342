import React from 'react'
import { useState, useEffect } from 'react';
import '../Style.css';
import { BsInfoCircle, BsQuestionCircle } from 'react-icons/bs';
import { CgArrowsExchangeAltV, CgArrowsExchangeAlt } from 'react-icons/cg';
import { CgList } from 'react-icons/cg';
import { AiOutlineSetting, AiOutlineStar } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import { VscGraphLine } from 'react-icons/vsc';
import Topnav from "./Topnav"

import $ from "jquery";
import {factoryAbi} from './factoryabi'
import {ercAbi} from './erc20abi'
import {routerAbi} from './routerabi'
import {pairAbi} from './pairaddressabi'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Web3 from 'web3';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import axios from 'axios'

const MySwal = withReactContent(Swal)

const LightTooltip = styled(({ className, ...props }) => (<
    Tooltip {...props}
    classes={
        { popper: className }}
/>
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));





class Swap extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            count: 60,
            accountaddress:'',
            fromtokennames: "VRS",
            fromtokensymbols: "",
            fromcontractaddress: "",

            totokennames: "CLT",
            totokensymbols: "",
            tocontractaddress: "",
            totokenimg: "",

            swapimgsrcfrom: "https://clarisco.s3.ap-south-1.amazonaws.com/clarisco+images/clarisco-logo.svg",
            swaptokennamefrom: "VRS",
            swaptokenvaluefrom: "",

            swapimgsrcto: "https://clarisco.s3.ap-south-1.amazonaws.com/clarisco+images/clarisco-logo.svg",
            swaptokennameto: "CLT",
            swaptokenvalueto: "",

            fromdecimals: "18",
            fromaddress: '',
            toaddress: '',
            fromsymbol: '',
            toAmounts: '',
            wyzthbtc:'0',
            // setaddresspath:'',
            settimer : '60'
        };
    }

        //  clock function ======

        componentWillUnmount() {
            clearInterval(this.counter);
        }
        //  Clock increment function=======
        increment() {
            if (this.state.count >= 1) {
                this.setState({
                    count: 60
                });
            } else {
                return this.setState({
                    count: 60
                });
            }
            this.setState({
                count: this.state.count - 1
            });
    
        }
    
    reset = () => {
        this.setState({ count: 60 });
    }

    metamask = async() => {
        const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
        if(currentChainId == '0x5') {
            this.setState({
                accountaddress:"address"
            })

        } else if(currentChainId == '0x61') {
            this.setState({
                accountaddress:"address"
            })
        } else if(currentChainId == '0x13881') {
            this.setState({
                accountaddress:"address"
            })
        } else {
            MySwal.fire('Please connect to goerli,bsctestnet or polygontestnet')
        }
    }

    componentDidMount() {
        this.counter = setInterval(() => this.increment(), 1000);
        this.getprice();
        this.metamask();
        $(function () {
            $('.slippage-settings-button-05').css('background', 'transparent');
            $('.slippage-settings-button-1').css('background', '#0000 linear-gradient(268deg,#3989f9be,#3a3ad64f) 0 0 no-repeat');
            $('.slippage-settings-button-3').css('background', 'transparent');
            $('.slippage-percentage-section').css('border', '1px solid #7c859f');
            $('.slippage-settings-button-05').click(function () {
                $('.slippage-settings-button-1').css('background', 'transparent');
                $('.slippage-settings-button-05').css('background', '#0000 linear-gradient(268deg,#3989f9be,#3a3ad64f) 0 0 no-repeat');
                $('.slippage-settings-button-3').css('background', 'transparent');
                $('.slippage-percentage-section').css('border', '1px solid #7c859f');
            });
            $('.slippage-settings-button-1').click(function () {
                $('.slippage-settings-button-05').css('background', 'transparent');
                $('.slippage-settings-button-1').css('background', '#0000 linear-gradient(268deg,#3989f9be,#3a3ad64f) 0 0 no-repeat');
                $('.slippage-settings-button-3').css('background', 'transparent');
                $('.slippage-percentage-section').css('border', '1px solid #7c859f');
            });
            $('.slippage-settings-button-3').click(function () {
                $('.slippage-settings-button-05').css('background', 'transparent');
                $('.slippage-settings-button-3').css('background', '#0000 linear-gradient(268deg,#3989f9be,#3a3ad64f) 0 0 no-repeat');
                $('.slippage-settings-button-1').css('background', 'transparent');
                $('.slippage-percentage-section').css('border', '1px solid #7c859f');
            });
            $('.slippage-percentage-section').click(function () {
                $('.slippage-settings-button-05').css('background', 'transparent');
                $('.slippage-settings-button-3').css('background', 'transparent');
                $('.slippage-settings-button-1').css('background', 'transparent');
                $('.slippage-percentage-section').css('border', '2px solid #3988f9');
            });
        });
        $(function () {
            $(".fee-fast-button").addClass("slippage-settings-color");
            $('.fee-normal-button').click(function () {
                $(".fee-normal-button").addClass("slippage-settings-color");
                $(".fee-fast-button").removeClass("slippage-settings-color");
                $(".fee-instant-button").removeClass("slippage-settings-color");
            });
            $('.fee-fast-button').click(function () {
                $(".fee-fast-button").addClass("slippage-settings-color");
                $(".fee-normal-button").removeClass("slippage-settings-color");
                $(".fee-instant-button").removeClass("slippage-settings-color");
            });
            $('.fee-instant-button').click(function () {
                $(".fee-instant-button").addClass("slippage-settings-color");
                $(".fee-fast-button").removeClass("slippage-settings-color");
                $(".fee-normal-button").removeClass("slippage-settings-color");
            });
        });
        $(document).ready(function () {
            $(".expert-mode-off-button").addClass("on-off-bg-color");
            $(".expert-mode-off-button").click(function () {
                $(".expert-mode-off-button").addClass("on-off-bg-color");
                $(".expert-mode-on-button").removeClass("on-off-bg-color");
            });
            $(".expert-mode-on-button").click(function () {
                $(".expert-mode-on-button").addClass("on-off-bg-color");
                $(".expert-mode-off-button").removeClass("on-off-bg-color");
            });
            $(".recipient-off-button").addClass("on-off-bg-color");
            $(".recipient-off-button").click(function () {
                $(".recipient-off-button").addClass("on-off-bg-color");
                $(".recipient-on-button").removeClass("on-off-bg-color");
            });
            $(".recipient-on-button").click(function () {
                $(".recipient-on-button").addClass("on-off-bg-color");
                $(".recipient-off-button").removeClass("on-off-bg-color");
            });
        });
        $(document).ready(function () {
            $(".swap-select-form-filter-button-1").addClass("swap-select-form-filter-button-color");
            $(".swap-select-form-filter-button-1").click(function () {
                $(".swap-select-form-filter-button-1").addClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").removeClass("swap-select-form-filter-button-color");
            });
            $(".swap-select-form-filter-button-2").click(function () {
                $(".swap-select-form-filter-button-1").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").addClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").removeClass("swap-select-form-filter-button-color");
            });
            $(".swap-select-form-filter-button-3").click(function () {
                $(".swap-select-form-filter-button-1").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").addClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").removeClass("swap-select-form-filter-button-color");
            });
            $(".swap-select-form-filter-button-4").click(function () {
                $(".swap-select-form-filter-button-1").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").addClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").removeClass("swap-select-form-filter-button-color");
            });
            $(".swap-select-form-filter-button-5").click(function () {
                $(".swap-select-form-filter-button-1").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").addClass("swap-select-form-filter-button-color");
            });
        });
    }


    getprice = async() => {    
        axios.get("https://api.etherscan.com/api?module=stats&action=ethprice&apikey=Q15HRHD2546QF1HIY155EPTAGPGPKANIJU").then(async(response)=> {
            this.setState({
                wyzthprice:response.data.result.ethusd
            })
        })

        axios.get("https://min-api.cryptocompare.com/data/price?fsym=BTC&tsyms=USD")
          .then(async(response) => {
            this.setState({
              btcprice:response.data.USD
            })
        })
        const prices = ((this.state.btcprice / this.state.wyzthprice).toFixed(2));
          this.setState({
            wyzthbtc:prices
          })
    }
    

    Swapfromvalue = () => {
        this.setState({
            swaptokennamefrom: document.getElementById('fromtokensymbol').innerHTML,
            swapimgsrcfrom: "https://clarisco.s3.ap-south-1.amazonaws.com/clarisco+images/clarisco-logo.svg"
        });
    }

    Swaptovalue = () => {
        this.setState({
            swaptokennameto: document.getElementById('totokensymbol').innerHTML,
            swapimgsrcto: "https://clarisco.s3.ap-south-1.amazonaws.com/clarisco+images/clarisco-logo.svg"
        })
    }

    gettoAmountvalue = async () => {
        if (window.ethereum.chainId == '0x5') {
            var web3js = new Web3(window.ethereum);
            const fromtoken = document.getElementById('fromtokenaddress').value;
            const totoken = document.getElementById('totokenaddress').value;
            const fromamount = document.getElementById('from_amount').value;
            const factorycontract = new web3js.eth.Contract(factoryAbi, '0x84b8b3c7043b9b2e3ea9d979ef135dce3a3653c4');
            const routercontract = new web3js.eth.Contract(routerAbi, '0xdbc38ca23cc773e4c2b483cfb3d7283e1c54d766');
            const pairAddress = await factorycontract.methods.getPair(fromtoken,totoken).call()
            var amounta = web3js.utils.toWei(fromamount, 'ether')
            const amountb = await routercontract.methods.getAmountsOut(amounta, pairAddress).call()
            const to_amount1 = web3js.utils.fromWei(amountb, 'ether');
            const to_amount = parseFloat(to_amount1).toFixed(3);
            document.getElementById("to_amount").value = to_amount;
        } 
        else if(window.ethereum.chainId == '0x61') {
            var web3js = new Web3(window.ethereum);
            const fromtoken = document.getElementById('fromtokenaddress').value;
            const totoken = document.getElementById('totokenaddress').value;
            const fromamount = document.getElementById('from_amount').value;
            const factorycontract = new web3js.eth.Contract(factoryAbi, '0x805c33B357A8201F7524f9019aeFA8f30c8ff5e0');
            const routercontract = new web3js.eth.Contract(routerAbi, '0x4bc464f8632535e4a09426bc529d312c03029ba0');
            const pairAddress = await factorycontract.methods.getPair(fromtoken,totoken).call()
                console.log("amountb--",pairAddress)
            var amounta = web3js.utils.toWei(fromamount, 'ether')
            const amountb = await routercontract.methods.getAmountsOut(amounta, pairAddress).call()
                console.log("amountb--",amountb)
            const to_amount1 = web3js.utils.fromWei(amountb, 'ether');
            const to_amount = parseFloat(to_amount1).toFixed(3);
            document.getElementById("to_amount").value = to_amount;
        } 
        else if(window.ethereum.chainId == '0x13881') {
            var web3js = new Web3(window.ethereum);
            const fromtoken = document.getElementById('fromtokenaddress').value;
            const totoken = document.getElementById('totokenaddress').value;
            const fromamount = document.getElementById('from_amount').value;
            const factorycontract = new web3js.eth.Contract(factoryAbi, '0xb2679e2896b5531d64c11551c77533b779553b94');
            const routercontract = new web3js.eth.Contract(routerAbi, '0xe2485ab08e90a3f3b569c837daccc30c250e585a');
            const pairAddress = await factorycontract.methods.getPair(fromtoken,totoken).call()
            var amounta = web3js.utils.toWei(fromamount, 'ether')
            const amountb = await routercontract.methods.getAmountsOut(amounta, pairAddress).call()
            const to_amount1 = web3js.utils.fromWei(amountb, 'ether');
            const to_amount = parseFloat(to_amount1).toFixed(3);
            document.getElementById("to_amount").value = to_amount;
        } else {
            MySwal.fire('Please connect to goerli,bsctestnet or polygontestnet')
        }
    }

    getfromvaluecontract = async(event) => {
        event.preventDefault()
        var web3js = new Web3(window.ethereum);
        const erc1contract = new web3js.eth.Contract(ercAbi, event.target.value);
        const name = await erc1contract.methods.name().call();
        const symbol = await erc1contract.methods.symbol().call();
            this.setState({
                fromtokennames : symbol,
                fromtokensymbols : symbol,
                fromcontractaddress: event.target.value
            })
        $("#fromtokenimg").attr("src","https://clarisco.s3.ap-south-1.amazonaws.com/clarisco+images/clarisco-logo.svg");
    }

    gettovaluecontract = async(event) => {
        event.preventDefault()
        var web3js = new Web3(window.ethereum);
        const erc1contract = new web3js.eth.Contract(ercAbi, event.target.value);
        const name = await erc1contract.methods.name().call();
        const symbol = await erc1contract.methods.symbol().call();
            this.setState({
                totokennames : symbol,
                totokensymbols : symbol,
                tocontractaddress: event.target.value 
            })
        $("#totokenimg").attr("src","https://clarisco.s3.ap-south-1.amazonaws.com/clarisco+images/clarisco-logo.svg");
    }

    swap = async() => {
        if (window.ethereum.chainId == '0x5') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const erc1contract = new web3js.eth.Contract(ercAbi, this.state.fromcontractaddress);
            const routercontract = new web3js.eth.Contract(routerAbi, '0xdbc38ca23cc773e4c2b483cfb3d7283e1c54d766');
            const fromtoken = document.getElementById('fromtokenaddress').value;
            const totoken = document.getElementById('totokenaddress').value;
            const fromamount = document.getElementById('from_amount').value;
                var amounta = web3js.utils.toWei(fromamount, 'ether')
            await erc1contract.methods.approve('0xdbc38ca23cc773e4c2b483cfb3d7283e1c54d766', amounta).send({ from: account[0] });
            var getamount = await routercontract.methods.swapExactTokensForTokens(
                amounta,
                fromtoken,
                totoken,
                account[0]
            ).send({ from: account[0] })
            Swal.fire({
                icon: 'success',
                background: 'linear-gradient(268deg,#3988f9,#3b3ad6)',
                color: "#ffffff",
                title: 'Transaction confirmed',
                html:'Your transaction hash is:<br/><br/>'+ 
                `<a href=${"https://goerli.etherscan.io/tx/"+getamount.transactionHash} target="_blank" style="color:white;">${getamount.transactionHash}</a>` ,
                confirmButtonColor: "#3b3ad6"
            })
        } 
        else if(window.ethereum.chainId == '0x61') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const erc1contract = new web3js.eth.Contract(ercAbi, this.state.fromcontractaddress);
            var name = await erc1contract.methods.name().call()
            const routercontract = new web3js.eth.Contract(routerAbi, '0x4bc464f8632535e4a09426bc529d312c03029ba0');
            const fromtoken = document.getElementById('fromtokenaddress').value;
            const totoken = document.getElementById('totokenaddress').value;
           
            const fromamount = document.getElementById('from_amount').value;
            var amounta = web3js.utils.toWei(fromamount, 'ether')
            await erc1contract.methods.approve('0x4bc464f8632535e4a09426bc529d312c03029ba0', amounta).send({ from: account[0] });
            var getamount = await routercontract.methods.swapExactTokensForTokens(
                amounta,
                fromtoken,
                totoken,
                account[0]
            ).send({ from: account[0] })
            Swal.fire({
                icon: 'success',
                background: 'linear-gradient(268deg,#3988f9,#3b3ad6)',
                color: "#ffffff",
                title: 'Transaction confirmed',
                html:'Your transaction hash is: <br/><br/>'+
                `<a href=${"https://testnet.bscscan.com/tx/"+getamount.transactionHash} target="_blank" style="color:white;">${getamount.transactionHash}</a>` ,
                confirmButtonColor: "#3b3ad6"
            })
        } 
        else if(window.ethereum.chainId == '0x13881') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const erc1contract = new web3js.eth.Contract(ercAbi, this.state.fromcontractaddress);
            const erc2contract = new web3js.eth.Contract(ercAbi, this.state.tocontractaddress);
            var name = await erc1contract.methods.name().call()
          
            const routercontract = new web3js.eth.Contract(routerAbi, '0xe2485ab08e90a3f3b569c837daccc30c250e585a');
            const fromtoken = document.getElementById('fromtokenaddress').value;
            const totoken = document.getElementById('totokenaddress').value;
           
            const fromamount = document.getElementById('from_amount').value;
            var amounta = web3js.utils.toWei(fromamount, 'ether')
            await erc1contract.methods.approve('0xe2485ab08e90a3f3b569c837daccc30c250e585a', amounta).send({ from: account[0] });
            var getamount = await routercontract.methods.swapExactTokensForTokens(
                amounta,
                fromtoken,
                totoken,
                account[0]
            ).send({ from: account[0] })
            Swal.fire({
                icon: 'success',
                background: 'linear-gradient(268deg,#3988f9,#3b3ad6)',
                color: "#ffffff",
                title: 'Transaction confirmed',
                html:'Your transaction hash is: <br/><br/>'+
                `<a href=${"https://mumbai.polygonscan.com/tx/"+getamount.transactionHash} target="_blank" style="color:white;">${getamount.transactionHash}</a>` ,
                confirmButtonColor: "#3b3ad6"
            })
        }
        else {
            MySwal.fire('Please connect to goerli,bsctestnet or polygontestnet')
        }
    }

    getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        return {
            total, seconds
        };
    }
  
    startTimer = (e) => {
        let { total,  seconds } = this.getTimeRemaining(e);
        if (total >= 0) {
            this.setState({
                settimer:seconds > 9 ? seconds : '0' + seconds  
            })
        }
    }
  
    clearTimer = (e) => {
        this.setState({
            settimer:'60'
        });
        const id = setInterval(() => {
            this.startTimer(e);
        }, 1000)
    }
  
    getDeadTime = () => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 60);
        return deadline;
    }

    render() {
        return (
        <div className="App">
            <Topnav />
                <section className="swap-top-banner-section" >
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-5'>
                                <div className='row'>
                                    <div className='col'>
                                        <span className='swap-section-text-1 ms-4'>Swap</span>
                                    </div>
                                    <div className='col text-end'>
                                        {/* <LightTooltip title="Refresh Data" placement="top">
                                            <span className='top-right-swap-countdown' onClick={this.getwyzth}>{this.state.settimer}</span>
                                        </LightTooltip> */}
                                        <LightTooltip title="Refresh Data" placement="top">
                                            <span className='top-right-swap-countdown' id="timer" onClick={this.reset}>
                                                {this.state.count}
                                            </span>
                                        </LightTooltip>
                                        <LightTooltip title="view pool analytics" placement="top">
                                            <span><VscGraphLine className="top-right-swap-graph-icons" /></span>
                                        </LightTooltip>
                                        <LightTooltip title="settings" placement="top" >
                                            <span data-bs-toggle="modal" data-bs-target="#settingsslippage"><AiOutlineSetting className="top-right-swap-settings-icons" /></span>
                                        </LightTooltip>
                                    </div>
                                </div>

                                <div className='swap-section-background mt-3'>
                                    <div className='row from-section-swap-top'>
                                        <div className='col'>
                                            <div className='row'>
                                            <input className="form-control-swap-text" placeholder='0' id='from_amount' ref="from-amount" onChange={this.gettoAmountvalue}></input>
                                            </div>
                                            <div className='row mt-2'>
                                                {/* <span className='swap-section-text-2'>$ <span>0.00</span></span> */}
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='row justify-content-end'>
                                                <div className="col-12 from-section-coin-background" data-bs-toggle="modal" data-bs-target="#Swapcoinfrom">
                                                    <div className="d-flex flex-row">
                                                        <div className="">
                                                            <span><img  src={this.state.swapimgsrcfrom}  className='swap-from-image'/></span></div>
                                                        <div className="px-2">
                                                            {/* <p className='swap-section-text-3'>WYZTH</p>
                                                            <p className='swap-section-text-4'>ERC20</p> */}
                                                            <p className='swap-section-text-3' value={this.state.fromtokennames}>{this.state.fromtokennames}</p>
                                                        </div>
                                                        <div className="">
                                                            <span><FaChevronDown className='swap-arrow-icons' /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col text-end'>
                                                    <button type="button" className="swap-from-btn-max">Max</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row swap-button-rotate-section'>
                                        <div className='col text-center'>
                                            <CgArrowsExchangeAltV className='swap-button-rotate' />
                                        </div>
                                    </div>

                                    <div className='row from-section-swap-top m-22'>
                                        <div className='col'>
                                            <div className='row'>
                                            <input className="form-control-swap-text" type="number" placeholder="0" id='to_amount'  aria-label="example" disabled></input>
                                            </div>
                                            <div className='row mt-2'>
                                                <span className='swap-section-text-2'>$ <span>0.00</span></span>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='row justify-content-end'>
                                                <div className="col-12 from-section-coin-background" data-bs-toggle="modal" data-bs-target="#Swapcointo">
                                                    <div className="d-flex  flex-row">
                                                        <div className="">
                                                            <span><img src={this.state.swapimgsrcto} className='swap-from-image' id="totokenname"/></span>
                                                        </div>
                                                        <div className="px-2">
                                                            <p className='swap-section-text-3'value={this.state.totokennames}>{this.state.totokennames}</p>
                                                        </div>
                                                        <div className="">
                                                            <span><FaChevronDown className='swap-arrow-icons' /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row more-info-swap-accordian mt-2'>
                                        <div className='col'>
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingTwo">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                            aria-expanded="false"
                                                            aria-controls="collapseTwo">
                                                            <span className='more-info-swap-accordian-text-1 me-auto'>
                                                                <LightTooltip title="Swap assets rates" placement="top">
                                                                    <CgArrowsExchangeAlt className='more-info-swap-accordian-icons me-1' />
                                                                </LightTooltip>1 BTC = {this.state.wyzthbtc} ETH <span className='more-info-swap-accordian-text-2'>($ 0.00)</span></span>
                                                            <span className='more-info-swap-accordian-text-3 ms-auto'>
                                                                <LightTooltip title="Total fee" placement="top">
                                                                    <CgList className='fc-gray fs-16 me-1' />
                                                                </LightTooltip>$ 3.44</span>
                                                        </button>
                                                    </h2>
                                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className='row align-items-center border-bottom-gray pb-2'>
                                                                <div className='col-7'>
                                                                    <span className='more-info-swap-accordian-text-4'>Expected Output</span>
                                                                </div>
                                                                <div className='col text-end'>
                                                                    <span className='more-info-swap-accordian-text-5'>0 BTC
                                                                        <LightTooltip title="Output amount based on current price rate" placement="top" >
                                                                            <BsInfoCircle className='more-info-swap-accordian-info-icons' />
                                                                        </LightTooltip>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='row align-items-center border-bottom-gray py-2'>
                                                                <div className='col-7'>
                                                                    <span className='more-info-swap-accordian-text-4'>Price Impact</span>
                                                                </div>
                                                                <div className='col text-end'>
                                                                    <span className='more-info-swap-accordian-text-5 fc-g'>0 %
                                                                        <LightTooltip title="The difference between the market price and estimated price due to trade size." placement="top">
                                                                            <BsInfoCircle className='more-info-swap-accordian-info-icons' />
                                                                        </LightTooltip>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='row align-items-center border-bottom-gray py-2'>
                                                                <div className='col-8'>
                                                                    <span className='more-info-swap-accordian-text-4'>Minimum received after slippage(0 % )</span>
                                                                </div>
                                                                <div className='col text-end'>
                                                                    <span className='more-info-swap-accordian-text-5'>-0.0001995 BTC
                                                                        <LightTooltip title="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." placement="top">
                                                                            <BsInfoCircle className='more-info-swap-accordian-info-icons' />
                                                                        </LightTooltip>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='row align-items-center border-bottom-gray py-2'>
                                                                <div className='col-7'>
                                                                    <span className='more-info-swap-accordian-text-4'>Network Fee</span>
                                                                </div>
                                                                <div className='col text-end'>
                                                                    <span className='more-info-swap-accordian-text-5'>$4 .76
                                                                        <LightTooltip title="Thorchain network fee used to pay outbound transaction" placement="top">

                                                                            <BsInfoCircle className='more-info-swap-accordian-info-icons' />
                                                                        </LightTooltip>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='row align-items-center py-2'>
                                                                <div className='col-7'>
                                                                    <span className='more-info-swap-accordian-text-4'>Exchange Fee</span>
                                                                </div>
                                                                <div className='col text-end'>
                                                                    <span className='more-info-swap-accordian-text-5 fc-g'>FREE
                                                                        <LightTooltip title="0.3% affiliate fee is charged by THORSwap. 75% of fees are redistributed back to vTHOR stakers" placement="top">

                                                                            <BsInfoCircle className='more-info-swap-accordian-info-icons' />

                                                                        </LightTooltip>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row connect-wallet-button-section mt-lg-5 my-4 mx-2'>
                                        <button className="btn btn-dark-blue button btn-lg d-block" onClick={this.swap}>{this.state.accountaddress?"SWAP":"CONNECT WALLET"}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section id="Swap-coin-select-section" className='swap-coin-select-section'>
                    <div className="modal fade Swap-coin-select-section-modal" id="Swapcoinfrom" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header border-0">
                                    <h5 className="swap-coin-select-text-1">Select a Token</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-header d-block py-2">
                                    <div className='row'>
                                        <div className='col'>
                                        <input id="myInput1" type="text" className="swap-select-form" placeholder='Select Token Name' onChange={this.getfromvaluecontract}/>
                                        </div>
                                    </div>
                                    <div className='row swap-select-form-filter-section mt-2'>
                                        <div className='col'>
                                            <button type="button" className="swap-select-form-filter-button-1">All</button>
                                            <button type="button" className="swap-select-form-filter-button-2">Synth</button>
                                            <button type="button" className="swap-select-form-filter-button-3">Native</button>
                                            <button type="button" className="swap-select-form-filter-button-4">Erc20</button>
                                            <button type="button" className="swap-select-form-filter-button-5">Bep2</button>
                                        </div>
                                    </div>

                                </div>

                                <div className="modal-body p-0 token-select-section">
                                    <ul id="myUL1" className="list-group">
                                    <li id="fromlist" className="list-group-item"><a onClick={this.Swapfromvalue} data-bs-dismiss="modal">
                                            <div className='row align-items-center'>
                                                <div className='col-1 text-end'>
                                                    <AiOutlineStar className='token-select-section-icon' />
                                                </div>
                                                <div className='col-1'>
                                                    <img id="fromtokenimg" src="https://clarisco.s3.ap-south-1.amazonaws.com/clarisco+images/clarisco-logo.svg" alt="logo" className='token-select-image' />
                                                </div>
                                                <div className='col pt-2'>
                                                <span className='token-select-section-text-1' id="fromtokensymbol" value={this.state.fromtokennames}>{this.state.fromtokennames}</span><br />
                                                        <input type="hidden" id="fromtokenaddress" value={this.state.fromcontractaddress}></input>
                                                        <input type="hidden" id="fromtokensymbol"></input>
                                                        <span className="fs-10 text-muted" id="fromtokensymbol"></span>
                                                </div>
                                            </div>
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                                <div className="modal-footer py-4">

                                </div>


                            </div>
                        </div>
                    </div>
                </section>

                <section id="Swap-coin-select-section" className='swap-coin-select-section'>
                    <div className="modal fade Swap-coin-select-section-modal" id="Swapcointo" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header border-0">
                                    <h5 className="swap-coin-select-text-1">Select a Token</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-header d-block py-2">
                                    <div className='row'>
                                        <div className='col'>
                                        <input id="myInput2" type="text" className="swap-select-form" placeholder='Select Token Name' autoComplete="off" onChange={this.gettovaluecontract} />
                                        </div>
                                    </div>
                                    <div className='row swap-select-form-filter-section mt-2'>
                                        <div className='col'>
                                            <button type="button" className="swap-select-form-filter-button-1">All</button>
                                            <button type="button" className="swap-select-form-filter-button-2">Synth</button>
                                            <button type="button" className="swap-select-form-filter-button-3">Native</button>
                                            <button type="button" className="swap-select-form-filter-button-4">Erc20</button>
                                            <button type="button" className="swap-select-form-filter-button-5">Bep2</button>
                                        </div>
                                    </div>

                                </div>

                                <div className="modal-body p-0 token-select-section">
                                    <ul id="myUL2" className="list-group">
                                    <li id="tolist" className="list-group-item"><a onClick={this.Swaptovalue} data-bs-dismiss="modal">
                                            <div className='row align-items-center'>
                                                <div className='col-1 text-end'>
                                                    <AiOutlineStar className='token-select-section-icon' />
                                                </div>
                                                <div className='col-1'>
                                                    <img id="totokenimg" src="https://clarisco.s3.ap-south-1.amazonaws.com/clarisco+images/clarisco-logo.svg" className='token-select-image' />
                                                </div>
                                                <div className='col'>
                                                <input type="hidden" id="totokenaddress" value={this.state.tocontractaddress}></input>                                                         
                                                    <span className='token-select-section-text-1' id="totokenname" value={this.state.totokennames}>{this.state.totokennames}</span>
                                                    <span className='token-select-section-text-2' id="totokensymbol"></span>
                                                </div>
                                            </div>
                                            </a>
                                        </li>
                                        
                                    </ul>
                                </div>
                                <div className="modal-footer py-4">

                                </div>


                            </div>
                        </div>
                    </div>
                </section>





                <section id="slippage-settings-section" className='slippage-settings-section'>
                    <div className="modal fade" id="settingsslippage" tabIndex="-1" aria-labelledby="settingsslippageLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <p className='slippage-settings-text-1'>Transaction Settings</p>
                                    <p className='slippage-settings-text-2'>Slippage Tolerance
                                        <LightTooltip title="Your transaction will revert if the price changes unfavorably by more than this percentage." placement="top">

                                            <BsQuestionCircle className='slippage-icon-text-1' />
                                        </LightTooltip>
                                    </p>
                                    <div className='row justify-content-center align-items-center'>
                                        <div className='col-3 d-flex slippage-percentage-section'>
                                            <span><input type="text" className="form-slippage-percentage"
                                                placeholder="0.0" /></span><span className='slippage-settings-text-3'>%</span>

                                        </div>

                                        <div className='col'>
                                            <button type="button" className="slippage-settings-button-05">0.5 %</button>
                                            <button type="button" className="slippage-settings-button-1">1 %</button>
                                            <button type="button" className="slippage-settings-button-3">3 %</button>
                                        </div>
                                    </div>

                                    <p className='slippage-settings-text-2 mt-4'>Transaction Fee
                                        <LightTooltip title="Accelerating a transaction by using a higher gas price increases its chances of getting processed by the network faster." placement="top">

                                            <BsQuestionCircle className='slippage-icon-text-1' />

                                        </LightTooltip></p>
                                    <div className='row justify-content-start align-items-center'>
                                        <div className='col'>
                                            <button type="button" className="fee-normal-button">Normal</button>
                                            <button type="button" className="fee-fast-button">Fast</button>
                                            <button type="button" className="fee-instant-button">Instant</button>
                                        </div>
                                    </div>

                                    <p className='slippage-settings-text-1 mt-4'>Transaction Mode</p>

                                    <div className='row justify-content-end align-items-center'>
                                        <div className='col-6'>
                                            <span className='slippage-settings-text-2'>Expert Mode
                                                <LightTooltip title="You are able to pool RUNE + Asset with the dynamic ratio in Expert Mode." placement="top">

                                                    <BsQuestionCircle className='slippage-icon-text-1' />

                                                </LightTooltip>
                                            </span>
                                        </div>
                                        <div className='col text-end'>
                                            <div className='bg-mode-0n-0ff '>
                                                <button type="button" className="expert-mode-on-button">ON</button>
                                                <button type="button" className="expert-mode-off-button">OFF</button>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='row justify-content-end align-items-center mt-3'>
                                        <div className='col-7'>
                                            <span className='slippage-settings-text-2'>Set Custom Recipient
                                                <LightTooltip title="Receive asset to your custom address." placement="top">

                                                    <BsQuestionCircle className='slippage-icon-text-1' />

                                                </LightTooltip>
                                            </span>
                                        </div>
                                        <div className='col text-end'>
                                            <div className='bg-mode-0n-0ff '>
                                                <button type="button" className="recipient-on-button">ON</button><button type="button" className="recipient-off-button">OFF</button></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>



            </div>

        )
    }
}

export default Swap;