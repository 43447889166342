import * as React from 'react';
import './Style.css';

import $ from "jquery";
import Swap from "./Pages/Swap";
import Deposit from "./Pages/Deposit";
import Liquidity from "./Pages/Liquidity";
import Send from "./Pages/Send";
import Wallet from "./Pages/Wallet";
import Dashboard from "./Pages/Dashboard";
import Staking from "./Pages/Staking";
import Create from "./Pages/Create";
import Banner from "./Pages/Banner";


import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";



class Home extends React.Component {

    componentDidMount() {
        $(document).ready(function () {
            $(".eth-connect-wallet-button").addClass("connect-wallet-active");
            $(".eth-connect-wallet-button").click(function () {
                $(".eth-connect-wallet-button").addClass("connect-wallet-active");
                $(".bsc-connect-wallet-button").removeClass("connect-wallet-active");
                $(".wyzth-connect-wallet-button").removeClass("connect-wallet-active");
            });
            $(".bsc-connect-wallet-button").click(function () {
                $(".bsc-connect-wallet-button").addClass("connect-wallet-active");
                $(".eth-connect-wallet-button").removeClass("connect-wallet-active");
                $(".wyzth-connect-wallet-button").removeClass("connect-wallet-active");
            });
            $(".wyzth-connect-wallet-button").click(function () {
                $(".wyzth-connect-wallet-button").addClass("connect-wallet-active");
                $(".eth-connect-wallet-button").removeClass("connect-wallet-active");
                $(".bsc-connect-wallet-button").removeClass("connect-wallet-active");
            });
        });

        if (window.location.pathname === "/") {
            $("body").css("background-color", "#061A4A");
            $("body").css("color", "#FFF");
        }
        if (window.location.pathname === "/swap") {
            $(".sidebar-section-swap").addClass("navlink-active-color");
            $(".sidebar-section-deposit").removeClass("navlink-active-color");
            $(".sidebar-section-liquidity").removeClass("navlink-active-color");
            $(".sidebar-section-wallet").removeClass("navlink-active-color");
            $(".sidebar-section-send").removeClass("navlink-active-color");
            $(".sidebar-section-dashboard").removeClass("navlink-active-color");
            $(".sidebar-section-nodes").removeClass("navlink-active-color");
            $(".sidebar-section-stake").removeClass("navlink-active-color");
        }
        if (window.location.pathname === "/deposit") {
            $(".sidebar-section-swap").removeClass("navlink-active-color");
            $(".sidebar-section-deposit").addClass("navlink-active-color");
            $(".sidebar-section-liquidity").removeClass("navlink-active-color");
            $(".sidebar-section-wallet").removeClass("navlink-active-color");
            $(".sidebar-section-send").removeClass("navlink-active-color");
            $(".sidebar-section-dashboard").removeClass("navlink-active-color");
            $(".sidebar-section-nodes").removeClass("navlink-active-color");
            $(".sidebar-section-stake").removeClass("navlink-active-color");
        }
        if (window.location.pathname === "/liquidity") {
            $(".sidebar-section-swap").removeClass("navlink-active-color");
            $(".sidebar-section-deposit").removeClass("navlink-active-color");
            $(".sidebar-section-liquidity").addClass("navlink-active-color");
            $(".sidebar-section-wallet").removeClass("navlink-active-color");
            $(".sidebar-section-send").removeClass("navlink-active-color");
            $(".sidebar-section-dashboard").removeClass("navlink-active-color");
            $(".sidebar-section-nodes").removeClass("navlink-active-color");
            $(".sidebar-section-stake").removeClass("navlink-active-color");
        }

        if (window.location.pathname === "/wallet") {
            $(".sidebar-section-swap").removeClass("navlink-active-color");
            $(".sidebar-section-deposit").removeClass("navlink-active-color");
            $(".sidebar-section-liquidity").removeClass("navlink-active-color");
            $(".sidebar-section-wallet").addClass("navlink-active-color");
            $(".sidebar-section-send").removeClass("navlink-active-color");
            $(".sidebar-section-dashboard").removeClass("navlink-active-color");
            $(".sidebar-section-nodes").removeClass("navlink-active-color");
            $(".sidebar-section-stake").removeClass("navlink-active-color");
        }

        if (window.location.pathname === "/send") {
            $(".sidebar-section-swap").removeClass("navlink-active-color");
            $(".sidebar-section-deposit").removeClass("navlink-active-color");
            $(".sidebar-section-liquidity").removeClass("navlink-active-color");
            $(".sidebar-section-wallet").removeClass("navlink-active-color");
            $(".sidebar-section-send").addClass("navlink-active-color");
            $(".sidebar-section-dashboard").removeClass("navlink-active-color");
            $(".sidebar-section-nodes").removeClass("navlink-active-color");
            $(".sidebar-section-stake").removeClass("navlink-active-color");
        }
        if (window.location.pathname === "/dashboard") {
            $(".sidebar-section-swap").removeClass("navlink-active-color");
            $(".sidebar-section-deposit").removeClass("navlink-active-color");
            $(".sidebar-section-liquidity").removeClass("navlink-active-color");
            $(".sidebar-section-wallet").removeClass("navlink-active-color");
            $(".sidebar-section-send").removeClass("navlink-active-color");
            $(".sidebar-section-dashboard").addClass("navlink-active-color");
            $(".sidebar-section-nodes").removeClass("navlink-active-color");
            $(".sidebar-section-stake").removeClass("navlink-active-color");
        }
        if (window.location.pathname === "/nodes") {
            $(".sidebar-section-swap").removeClass("navlink-active-color");
            $(".sidebar-section-deposit").removeClass("navlink-active-color");
            $(".sidebar-section-liquidity").removeClass("navlink-active-color");
            $(".sidebar-section-wallet").removeClass("navlink-active-color");
            $(".sidebar-section-send").removeClass("navlink-active-color");
            $(".sidebar-section-dashboard").removeClass("navlink-active-color");
            $(".sidebar-section-nodes").addClass("navlink-active-color");
            $(".sidebar-section-stake").removeClass("navlink-active-color");
        }
        if (window.location.pathname === "/stake") {
            $(".sidebar-section-swap").removeClass("navlink-active-color");
            $(".sidebar-section-deposit").removeClass("navlink-active-color");
            $(".sidebar-section-liquidity").removeClass("navlink-active-color");
            $(".sidebar-section-wallet").removeClass("navlink-active-color");
            $(".sidebar-section-send").removeClass("navlink-active-color");
            $(".sidebar-section-dashboard").removeClass("navlink-active-color");
            $(".sidebar-section-nodes").removeClass("navlink-active-color");
            $(".sidebar-section-stake").addClass("navlink-active-color");
        }

        $(document).ready(function () {
            $(".openbtn-sidebar-navbar").click(function () {
                $("#mySidenav").css("width", "200px");
            });
            $(".closebtn-sidebar-navbar").click(function () {
                $("#mySidenav").css("width", "0px");
            });

        });
    }

    render() {
        return (
            <Router>
                <div className="App">

                    <div className='switching-section'>
                        <Routes>
                            <Route path="/" element={<Banner />} />
                            <Route path="/swap" element={<Swap />} />
                            <Route path="/deposit" element={<Deposit />} />
                            <Route path="/liquidity" element={<Liquidity />} />
                            <Route path="/send" element={<Send />} />
                            <Route path="/wallet" element={<Wallet />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/stake" element={<Staking />} />
                            <Route path="/create" element={<Create />} />
                        </Routes>

                    </div>


                </div>
            </Router>

        )
    }
}

export default Home;
