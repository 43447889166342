import * as React from 'react';
import '../Style.css';
import { BsQuestionCircle } from 'react-icons/bs';
import { AiOutlineSetting, AiOutlineStar, AiOutlineReload } from 'react-icons/ai';
import Topnav from "./Topnav"

import Web3 from 'web3';

import $ from "jquery";

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


const LightTooltip = styled(({ className, ...props }) => (<
    Tooltip {...props}
    classes={
        { popper: className }}
/>
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));



class Liquidity extends React.Component {

    constructor(props) {
        super(props);
    }

    showhidebtn = () => {
        $("#depositbutton").hide();
        $("#createbutton").hide();
        $("#connectbutton").show();  
    }

    metamask = async() => {
        var web3js = new Web3(window.ethereum);
        const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
        if(account[0] == "") {
        } else {
            $("#connected").hide();
            $("#create").show();
        }
    }

    componentDidMount() {
        this.showhidebtn();
      
        $("#connected").show();
        $("#create").hide();

        $(function () {
            $('.slippage-settings-button-05').css('background', 'transparent');
            $('.slippage-settings-button-1').css('background', '#0000 linear-gradient(268deg,#3989f9be,#3a3ad64f) 0 0 no-repeat');
            $('.slippage-settings-button-3').css('background', 'transparent');
            $('.slippage-percentage-section').css('border', '1px solid #7c859f');
            $('.slippage-settings-button-05').click(function () {
                $('.slippage-settings-button-1').css('background', 'transparent');
                $('.slippage-settings-button-05').css('background', '#0000 linear-gradient(268deg,#3989f9be,#3a3ad64f) 0 0 no-repeat');
                $('.slippage-settings-button-3').css('background', 'transparent');
                $('.slippage-percentage-section').css('border', '1px solid #7c859f');
            });
            $('.slippage-settings-button-1').click(function () {
                $('.slippage-settings-button-05').css('background', 'transparent');
                $('.slippage-settings-button-1').css('background', '#0000 linear-gradient(268deg,#3989f9be,#3a3ad64f) 0 0 no-repeat');
                $('.slippage-settings-button-3').css('background', 'transparent');
                $('.slippage-percentage-section').css('border', '1px solid #7c859f');
            });
            $('.slippage-settings-button-3').click(function () {
                $('.slippage-settings-button-05').css('background', 'transparent');
                $('.slippage-settings-button-3').css('background', '#0000 linear-gradient(268deg,#3989f9be,#3a3ad64f) 0 0 no-repeat');
                $('.slippage-settings-button-1').css('background', 'transparent');
                $('.slippage-percentage-section').css('border', '1px solid #7c859f');
            });
            $('.slippage-percentage-section').click(function () {
                $('.slippage-settings-button-05').css('background', 'transparent');
                $('.slippage-settings-button-3').css('background', 'transparent');
                $('.slippage-settings-button-1').css('background', 'transparent');
                $('.slippage-percentage-section').css('border', '2px solid #3988f9');
            });
        });
        $(function () {
            $(".fee-fast-button").addClass("slippage-settings-color");
            $('.fee-normal-button').click(function () {
                $(".fee-normal-button").addClass("slippage-settings-color");
                $(".fee-fast-button").removeClass("slippage-settings-color");
                $(".fee-instant-button").removeClass("slippage-settings-color");
            });
            $('.fee-fast-button').click(function () {
                $(".fee-fast-button").addClass("slippage-settings-color");
                $(".fee-normal-button").removeClass("slippage-settings-color");
                $(".fee-instant-button").removeClass("slippage-settings-color");
            });
            $('.fee-instant-button').click(function () {
                $(".fee-instant-button").addClass("slippage-settings-color");
                $(".fee-fast-button").removeClass("slippage-settings-color");
                $(".fee-normal-button").removeClass("slippage-settings-color");
            });
        });
        $(document).ready(function () {
            $(".expert-mode-off-button").addClass("on-off-bg-color");
            $(".expert-mode-off-button").click(function () {
                $(".expert-mode-off-button").addClass("on-off-bg-color");
                $(".expert-mode-on-button").removeClass("on-off-bg-color");
            });
            $(".expert-mode-on-button").click(function () {
                $(".expert-mode-on-button").addClass("on-off-bg-color");
                $(".expert-mode-off-button").removeClass("on-off-bg-color");
            });
            $(".recipient-off-button").addClass("on-off-bg-color");
            $(".recipient-off-button").click(function () {
                $(".recipient-off-button").addClass("on-off-bg-color");
                $(".recipient-on-button").removeClass("on-off-bg-color");
            });
            $(".recipient-on-button").click(function () {
                $(".recipient-on-button").addClass("on-off-bg-color");
                $(".recipient-off-button").removeClass("on-off-bg-color");
            });
        });
        $(document).ready(function () {
            $(".swap-select-form-filter-button-1").addClass("swap-select-form-filter-button-color");
            $(".swap-select-form-filter-button-1").click(function () {
                $(".swap-select-form-filter-button-1").addClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").removeClass("swap-select-form-filter-button-color");
            });
            $(".swap-select-form-filter-button-2").click(function () {
                $(".swap-select-form-filter-button-1").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").addClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").removeClass("swap-select-form-filter-button-color");
            });
            $(".swap-select-form-filter-button-3").click(function () {
                $(".swap-select-form-filter-button-1").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").addClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").removeClass("swap-select-form-filter-button-color");
            });
            $(".swap-select-form-filter-button-4").click(function () {
                $(".swap-select-form-filter-button-1").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").addClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").removeClass("swap-select-form-filter-button-color");
            });
            $(".swap-select-form-filter-button-5").click(function () {
                $(".swap-select-form-filter-button-1").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").addClass("swap-select-form-filter-button-color");
            });
        });
    }

    render() {
        return (
            <div className="App">
               <Topnav />
                <section className="swap-top-banner-section">
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-5 ms-lg-5'>
                                <div className='row'>
                                    <div className='col'>
                                        <span className='swap-section-text-1 ms-4'>My Liquidity Position</span>
                                    </div>
                                    <div className='col-4 text-end'>
                                        {/* <LightTooltip title="Refresh Data" placement="top">
                                            <span className='top-right-swap-countdown'>60</span>
                                       </LightTooltip> */}
                                        <LightTooltip title="Reload" placement="top">

                                            <span><AiOutlineReload className="top-right-swap-graph-icons" /></span>

                                        </LightTooltip>

                                        <LightTooltip title="settings" placement="top" >

                                            <span data-bs-toggle="modal" data-bs-target="#settingsslippage"><AiOutlineSetting className="top-right-swap-settings-icons" /></span>

                                        </LightTooltip>
                                    </div>
                                </div>

                                <div className='swap-section-background mt-3'>
                                    <div className='row connect-wallet-button-section mx-2' id="connected">
                                        <button className="btn btn-dark-blue button btn-lg d-block" id="connectbutton" onClick={this.metamask}>CONNECT WALLET</button>
                                    </div>
                                    <div className='row connect-wallet-button-section' id="create">
                                        <div className='col'>
                                            <a href="/deposit">
                                                <button className="btn btn-dark-blue button btn-lg d-block" id="depositbutton">Deposit</button>
                                            </a>
                                        </div>
                                        <div className='col'>
                                            <a href="/create">
                                                <button className="btn btn-dark-blue button btn-lg d-block" id="createbutton">Create</button>
                                            </a>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Liquidity;