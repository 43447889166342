import * as React from 'react';
import '../Style.css';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { AiOutlineLock, AiOutlineClockCircle, AiOutlineAreaChart } from 'react-icons/ai';
import { BsInfoCircle } from 'react-icons/bs';
import { RiPercentLine } from 'react-icons/ri';
import { MdAreaChart } from 'react-icons/md';
import { HiChartBar } from 'react-icons/hi';
import { TbAdjustmentsAlt } from 'react-icons/tb';
import  Chart  from 'chart.js/auto'
import { SymbolOverview } from "react-tradingview-embed";
import Topnav from "./Topnav"
import $ from "jquery";
import logo from '../images/ethereum.png'
import bnbimage from "../images/bscimages.svg.png";
import maticimage from "../images/polygon.svg";
import  wyzthswap  from '../images/wyzth-swap.png'
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {factoryAbi} from './factoryabi'
import {ercAbi} from './erc20abi'
import {routerAbi} from './routerabi'
import {pairAbi} from './pairaddressabi'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Web3 from 'web3';
import axios from 'axios'
import { Bar, Line} from 'react-chartjs-2'
import NumberFormat from 'react-number-format';
import InputDataDecoder from 'ethereum-input-data-decoder';

const LightTooltip = styled(({ className, ...props }) => (<
    Tooltip {...props}
    classes={
        { popper: className }}
/>
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));


class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            blocksdata:[<tr key={""}>
                <td><img src="https://clarisco.s3.ap-south-1.amazonaws.com/clarisco+images/clarisco-logo.svg" className='token-dashboard-table-image' alt="home-img-1" />Tl1</td>
                <td>$100</td>
                <td>$100</td>
                <td>$613.55 K</td>
                <td>11 %</td>
                <td>
                <div className="btn-group d-flex" role="group" aria-label="Basic example">
                <a href="/swap">
                  <button type="button" className="dashboard-table-swap-button me-3">Swap</button>
                </a>
                <a href="/deposit">
                  <button type="button" className="dashboard-table-liquidity-button">Add Lquidity</button>
                </a>
                  </div>
                </td>
                </tr>],
            amountdetail: '',
            timedetail:'',
            setaddresspath:[''],
            gradientstroke:"",
            gradientfill:"",
            liquid:"",
            setConImg:[],
            stakeamount:"",
            getliquidsamount:"",
            hexToDecimals:"",
            volumepath:[],
            tokenArrays:[""],
            barthick:'',
            monthdatasbar:''
        }
    }

    volumedate = async() => {   
        const amountpush = [] 
        const monthdatas = []
        axios.get("http://13.235.57.149:4000/api?module=account&action=txlist&address=0xd9e830a5a7cbeeff24fcc871d2feb0c1210083fd&startblock=1403284&endblock=99999999&page=1&offset=100&sort=asc")
            .then(async(response) => {      
        const decoder = new InputDataDecoder(routerAbi);
        for(var i=0; i<=response.data.result.length; i++) {
          var car = {}  
          const data = response.data.result[i].input;
          var date = new Date(response.data.result[i].timeStamp * 1000);
            var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            var monthdate = months[date.getMonth()]+" "+ date.getDate();    
            monthdatas.push(monthdate)    
            const result = decoder.decodeData(data);      
            if(result.method == 'addliquid') {      
              const hexToDecimals = (parseInt(result.inputs[1]._hex, 16))/1e18;       
            }
            if(result.method == 'addLiquidity') {
              const hexToDecimal2 = (parseInt(result.inputs[2]._hex, 16))/1e18;
              const hexToDecimal3 = (parseInt(result.inputs[3]._hex, 16))/1e18; 
              const hexToDecimals = hexToDecimal2 + hexToDecimal3    
            }
            if(result.method == 'swapExactTokensForTokens') {
              var hexToDecimals = (parseInt(result.inputs[0]._hex, 16))/1e18;         
            }
            car["x"] = monthdate
            car["y"] = hexToDecimals
            amountpush.push(car)
            const volumesetpaths = amountpush.filter(Boolean);
            this.setState({
                volumepath:volumesetpaths,
                barthick:2,
                monthdatasbar:monthdatas
            })
        }    
      })
    }

    volumebardata = async() => { 
        const amountpush = [] 
        const monthdatas = []
        axios.get("http://13.235.57.149:4000/api?module=account&action=txlist&address=0xd9e830a5a7cbeeff24fcc871d2feb0c1210083fd&startblock=1403284&endblock=99999999&page=1&offset=100&sort=asc")
            .then(async(response) => {      
        const decoder = new InputDataDecoder(routerAbi);
        for(var i=0; i<=response.data.result.length; i++) {
          var car = {}  
          const data = response.data.result[i].input;
          var date = new Date(response.data.result[i].timeStamp * 1000);
            var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            var monthdate = months[date.getMonth()]+" "+ date.getDate();    
                monthdatas.push(monthdate)  
            const result = decoder.decodeData(data);      
            if(result.method == 'addliquid') {      
              const hexToDecimals = (parseInt(result.inputs[1]._hex, 16))/1e18;  
              this.setState({
                hexToDecimals:hexToDecimals
              })       
            }
            if(result.method == 'addLiquidity') {
              const hexToDecimal2 = (parseInt(result.inputs[2]._hex, 16))/1e18;
              const hexToDecimal3 = (parseInt(result.inputs[3]._hex, 16))/1e18; 
              const hexToDecimals = hexToDecimal2 + hexToDecimal3    
              this.setState({
                hexToDecimals:hexToDecimals
              }) 
            }
            if(result.method == 'swapExactTokensForTokens') {
              var hexToDecimals = (parseInt(result.inputs[0]._hex, 16))/1e18;          
              this.setState({
                hexToDecimals:hexToDecimals
              }) 
            }
            car["x"] = monthdate
            car["y"] = this.state.hexToDecimals
            amountpush.push(car)
            const volumesetpaths = amountpush.filter(Boolean);
            this.setState({
                volumepath:volumesetpaths,
                barthick:15,
                monthdatasbar:monthdatas
            })
        }    
      })
    }

    graphdata = async() => {
        const ctx = document.getElementById('lineChart').getContext('2d');
        const getimagenft = ['']   
        var getliquid = ['']         
        axios.get("http://13.235.57.149:4000/api?module=account&action=tokentx&address=0x7AA04A9934d68a9Bf973e24592d3d9295208D217&startblock=0&endblock=99999999&page=1&offset=1000&sort=asc")  
             .then(async (response) => {
                var amounts = 0;   
                    for(var i=0; i <= response.data.result.length; i++) {  
                      var car = {}         
                      var date = new Date(response.data.result[i].timeStamp * 1000);    
                      var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                      var monthdate = months[date.getMonth()]+" "+ date.getDate();
                      const amountsend = (parseInt(response.data.result[i].value))/1e18
                        car["x"] = monthdate
                        car["y"] = amountsend
                      getimagenft.push(car);
                      const setpaths = getimagenft.filter(Boolean);
                      var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
                      gradientStroke.addColorStop(0, '#3988f9');
                      gradientStroke.addColorStop(1, 'rgba(244, 144, 128, 0.6)');
                      var gradientFill = ctx.createLinearGradient(500, 0, 100, 0);
                        gradientFill.addColorStop(0, "#3b3ad6");
                        gradientFill.addColorStop(1, "rgba(244, 144, 128, 0.6)");
                        var liquidity = (parseInt(response.data.result[i].value))/1e18;
                        var intamount = parseInt(liquidity)
                        amounts += intamount;
                     const perdayliquid = (parseInt(response.data.result[i].value))/1e18
                     const  perdayliquid_fixed =  parseFloat(perdayliquid).toFixed(2);
                        getliquid.push(perdayliquid_fixed)
                        const setpathsliquid = getliquid.filter(Boolean);             
                    this.setState({
                        liquid:amounts,
                        getliquidsamount: setpathsliquid,
                       gradientstroke:gradientStroke,
                       gradientfill:gradientFill,
                       setConImg:setpaths   
                    })       
            }    
        })         
    }

    alldataline = async() => {
        const ctx = document.getElementById('lineChart').getContext('2d');
        const getimagenft = ['']   
        var getliquid = ['']         
        axios.get("http://13.235.57.149:4000/api?module=account&action=tokentx&address=0x7AA04A9934d68a9Bf973e24592d3d9295208D217&startblock=0&endblock=99999999&page=1&offset=1000&sort=asc")  
             .then(async (response) => {
                var amounts = 0;   
                    for(var i=0; i <= response.data.result.length; i++) {  
                      var car = {}         
                      var date = new Date(response.data.result[i].timeStamp * 1000);    
                      var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                      var monthdate = months[date.getMonth()]+" "+ date.getDate();
                      const amountsend = (parseInt(response.data.result[i].value))/1e18
                        car["x"] = monthdate
                        car["y"] = amountsend
                      getimagenft.push(car);
                      const setpaths = getimagenft.filter(Boolean);
                      var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
                      gradientStroke.addColorStop(0, '#3988f9');
                      gradientStroke.addColorStop(1, 'rgba(244, 144, 128, 0.6)');
                      var gradientFill = ctx.createLinearGradient(500, 0, 100, 0);
                        gradientFill.addColorStop(0, "#3b3ad6");
                        gradientFill.addColorStop(1, "rgba(244, 144, 128, 0.6)");
                        var liquidity = (parseInt(response.data.result[i].value))/1e18;
                        var intamount = parseInt(liquidity)
                        amounts += intamount;
                     const perdayliquid = (parseInt(response.data.result[i].value))/1e18
                     const  perdayliquid_fixed =  parseFloat(perdayliquid).toFixed(2);
                    getliquid.push(perdayliquid_fixed)
                        const setpathsliquid = getliquid.filter(Boolean);   
                       
                    this.setState({
                        liquid:amounts,
                        getliquidsamount: setpathsliquid,
                       gradientstroke:gradientStroke,
                       gradientfill:gradientFill,
                       setConImg:setpaths
                    })       
            }    
        })         
    
    }

    bscresgister = async() => {
        var web3js = new Web3(window.ethereum);
        axios.get("http://13.235.57.149:4000/api?module=account&action=tokenlist&address=0x7AA04A9934d68a9Bf973e24592d3d9295208D217")
            .then(async(response) => {
            const transactions  = response.data.result;
            var blocks = [];
            for (var i = 0; i < response.data.result.length; i++) {
                const tx = transactions[i];
                const balance = web3js.utils.fromWei(tx.balance, 'ether')
                const  balance_fixed =  parseFloat(balance).toFixed(2);
                blocks.push(
                <tr key={i}>
                <td><img src="http://clarisco-ecosystem.s3-website.ap-south-1.amazonaws.com/favicon.ico" className='token-dashboard-table-image' alt="home-img-1" />{tx.symbol}</td>
                <td>${balance_fixed}</td>
                <td>${balance_fixed}</td>
                <td>$613.55 K</td>
                <td>11 %</td>
                <td>
                <div className="btn-group d-flex" role="group" aria-label="Basic example">
                <a href="/swap">
                    <button type="button" className="dashboard-table-swap-button me-3">Swap</button>
                </a>
                <a href="/deposit">
                    <button type="button" className="dashboard-table-liquidity-button">Add Lquidity</button>
                </a>
                    </div>
                </td>
                </tr>
            )}
            this.setState({
                blocksdata:blocks
            })
        })  
    }

    stake = async() => {
        var web3js = new Web3(window.ethereum); 
        axios.get("http://13.235.57.149:4000/api?module=account&action=tokentx&address=0x148a0054CfC12a8742CBCD2fB668dd1Cd606CeF3&startblock=1&endblock=99999999&page=1&offset=100&sort=asc")
          .then(async (response) => {
            var amounts = 0;
            for(var i=0;i<=response.data.result.length;i++) {
                const amountsend = (parseInt(response.data.result[i].value))/1e18
                var intamount = parseInt(amountsend)
                amounts += intamount;
                this.setState({
                    stakeamount: amounts
                })
            }          
        }) 
        
    }

    componentDidMount() {
        this.bscresgister();
        this.graphdata();
        this.volumedate()
       
        this.stake();     
        $(function () {
            $(".breadcrumb-total").addClass("volume-breadcrumb-active");
            $('.breadcrumb-total').click(function () {
                $(".breadcrumb-total").addClass("volume-breadcrumb-active");
                $(".breadcrumb-swap").removeClass("volume-breadcrumb-active");
                $(".breadcrumb-add").removeClass("volume-breadcrumb-active");
                $(".breadcrumb-withdraw").removeClass("volume-breadcrumb-active");
            });
            $('.breadcrumb-swap').click(function () {
                $(".breadcrumb-total").removeClass("volume-breadcrumb-active");
                $(".breadcrumb-swap").addClass("volume-breadcrumb-active");
                $(".breadcrumb-add").removeClass("volume-breadcrumb-active");
                $(".breadcrumb-withdraw").removeClass("volume-breadcrumb-active");
            });
            $('.breadcrumb-add').click(function () {
                $(".breadcrumb-total").removeClass("volume-breadcrumb-active");
                $(".breadcrumb-swap").removeClass("volume-breadcrumb-active");
                $(".breadcrumb-add").addClass("volume-breadcrumb-active");
                $(".breadcrumb-withdraw").removeClass("volume-breadcrumb-active");
            });
            $('.breadcrumb-withdraw').click(function () {
                $(".breadcrumb-total").removeClass("volume-breadcrumb-active");
                $(".breadcrumb-swap").removeClass("volume-breadcrumb-active");
                $(".breadcrumb-add").removeClass("volume-breadcrumb-active");
                $(".breadcrumb-withdraw").addClass("volume-breadcrumb-active");
            });
        });
        $(function () {
            $(".breadcrumb-liquidity").addClass("liquidity-breadcrumb-active");
            $('.breadcrumb-liquidity').click(function () {
                $(".breadcrumb-liquidity").addClass("liquidity-breadcrumb-active");
                $(".breadcrumb-lpearn").removeClass("liquidity-breadcrumb-active");
                $(".breadcrumb-bondearn").removeClass("liquidity-breadcrumb-active");
            });
            $('.breadcrumb-lpearn').click(function () {
                $(".breadcrumb-liquidity").removeClass("liquidity-breadcrumb-active");
                $(".breadcrumb-lpearn").addClass("liquidity-breadcrumb-active");
                $(".breadcrumb-bondearn").removeClass("liquidity-breadcrumb-active");
            });
            $('.breadcrumb-bondearn').click(function () {
                $(".breadcrumb-liquidity").removeClass("liquidity-breadcrumb-active");
                $(".breadcrumb-lpearn").removeClass("liquidity-breadcrumb-active");
                $(".breadcrumb-bondearn").addClass("liquidity-breadcrumb-active");
            });
        });
        $(function () {
            $(".dashboard-section-all-button-2").addClass("dashboard-filter-button-color");
            $(".dashboard-section-all-button-1").addClass("dashboard-filter-button-color");

            $('.dashboard-section-all-button-1').click(function () {
                $(".dashboard-section-all-button-1").addClass("dashboard-filter-button-color");
                $(".dashboard-section-week-button-1").removeClass("dashboard-filter-button-color");
            });
            $('.dashboard-section-week-button-1').click(function () {
                $(".dashboard-section-week-button-1").addClass("dashboard-filter-button-color");
                $(".dashboard-section-all-button-1").removeClass("dashboard-filter-button-color");
            });
            $('.dashboard-section-all-button-2').click(function () {
                $(".dashboard-section-all-button-2").addClass("dashboard-filter-button-color");
                $(".dashboard-section-week-button-2").removeClass("dashboard-filter-button-color");
            });
            $('.dashboard-section-week-button-2').click(function () {
                $(".dashboard-section-week-button-2").addClass("dashboard-filter-button-color");
                $(".dashboard-section-all-button-2").removeClass("dashboard-filter-button-color");
            });
        });
    }


    render() {
        return (
            <div className="App">
            <Topnav />
                <section className="dashboard-top-section">
                    <div className='container-fluid'>
                        <div className='row justify-content-end'>
                          <div className='col-lg-9 col-12 me-lg-5'>
                            <div className=" d-flex flex-row mt-3 mb-2 align-items-center justify-content-between">
                                <div className=''>
                                    <h5 className="swap-section-text-1 ms-4">Stats</h5>
                                </div>
                            </div>
                            <div className="">
                                <div className="row justify-content-center">
                                    <div className="col">
                                        <Swiper
                                            spaceBetween={30}
                                            navigation={true}
                                            modules={[Navigation]}
                                            breakpoints={{
                                                "@0.00": {
                                                    slidesPerView: 2,
                                                    spaceBetween: 10,
                                                },
                                                "@0.75": {
                                                    slidesPerView: 2,
                                                    spaceBetween: 20,
                                                },
                                                "@1.00": {
                                                    slidesPerView: 3,
                                                    spaceBetween: 20,
                                                },
                                                "@1.50": {
                                                    slidesPerView: 5,
                                                    spaceBetween: 20,
                                                },
                                            }}
                                            className="mySwiper py-3 px-lg-5">
                                            <SwiperSlide className='dashboard-top-section-Swiper-Slide-1'>
                                                <div className="d-flex flex-row align-items-center">
                                                    <div>
                                                        <AiOutlineLock className='swiper-slide-icons-1' />
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <p className='dashboard-section-text-1'>TVL
                                                                <LightTooltip title="Total Value Locked amount in the network (Total Liquidity + Total Node Bond)." placement="top" >
                                                                    <BsInfoCircle className='swiper-slide-icons-info' />
                                                                </LightTooltip>
                                                            </p>
                                                            <p className='dashboard-section-text-2'>
                                                            <NumberFormat value={this.state.liquid + this.state.stakeamount} thousandSeparator={true} displayType={'text'} prefix={'$'}/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className='dashboard-top-section-Swiper-Slide-2'>
                                                <div className="d-flex flex-row align-items-center">
                                                    <div>
                                                        <MdAreaChart className='swiper-slide-icons-2' />
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <p className='dashboard-section-text-1'>Total Liquidity
                                                                <LightTooltip title="Total Liquidity Added in all pools." placement="top" >
                                                                    <BsInfoCircle className='swiper-slide-icons-info' />
                                                                </LightTooltip>
                                                            </p>
                                                            <p className='dashboard-section-text-2'>
                                                            <NumberFormat value={this.state.getliquidsamount.slice(-1)[0]} thousandSeparator={true} displayType={'text'} prefix={'$'}/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className='dashboard-top-section-Swiper-Slide-3'>
                                                <div className="d-flex flex-row align-items-center">
                                                    <div>
                                                        <TbAdjustmentsAlt className='swiper-slide-icons-3' />
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <p className='dashboard-section-text-1'>Total Active Bond
                                                                <LightTooltip title="Total Bond Amounts by Active Nodes." placement="top" >
                                                                    <BsInfoCircle className='swiper-slide-icons-info' />
                                                                </LightTooltip>
                                                            </p>
                                                            <p className='dashboard-section-text-2'>${this.state.stakeamount}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className='dashboard-top-section-Swiper-Slide-4'>
                                                <div className="d-flex flex-row align-items-center">
                                                    <div>
                                                        <HiChartBar className='swiper-slide-icons-4' />
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <p className='dashboard-section-text-1'>Total Volume
                                                                <LightTooltip title="Total Trade Volume in the network." placement="top" >
                                                                    <BsInfoCircle className='swiper-slide-icons-info' />
                                                                </LightTooltip>
                                                            </p>
                                                            <p className='dashboard-section-text-2'>
                                                                <NumberFormat value={this.state.liquid - this.state.getliquidsamount.slice(-1)[0]} thousandSeparator={true} displayType={'text'} prefix={'$'}/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className='dashboard-top-section-Swiper-Slide-1'>
                                                <div className="d-flex flex-row align-items-center">
                                                    <div>
                                                        <AiOutlineClockCircle className='swiper-slide-icons-1' />
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <p className='dashboard-section-text-1'>24H Volume
                                                                <LightTooltip title="24H Volume for Swap, Add, Withdraw." placement="top" >
                                                                    <BsInfoCircle className='swiper-slide-icons-info' />
                                                                </LightTooltip>
                                                            </p>
                                                            <p className='dashboard-section-text-2'>
                                                            <NumberFormat value={this.state.getliquidsamount.slice(-1)[0]} thousandSeparator={true} displayType={'text'} prefix={'$'}/>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className='dashboard-top-section-Swiper-Slide-2'>
                                                <div className="d-flex flex-row align-items-center">
                                                    <div>
                                                        <RiPercentLine className='swiper-slide-icons-2' />
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <p className='dashboard-section-text-1'>Liquidity APY
                                                                <LightTooltip title="24H Volume for Swap, Add, Withdraw." placement="top" >
                                                                    <BsInfoCircle className='swiper-slide-icons-info' />
                                                                </LightTooltip>
                                                            </p>
                                                            <p className='dashboard-section-text-2'>10%</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className='dashboard-top-section-Swiper-Slide-3'>
                                                <div className="d-flex flex-row align-items-center">
                                                    <div>
                                                        <AiOutlineAreaChart className='swiper-slide-icons-3' />
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <p className='dashboard-section-text-1'>Total Pooled ETH
                                                                <LightTooltip title="Total Pooled ETH / Max ETH Liquidity Cap." placement="top" >
                                                                    <BsInfoCircle className='swiper-slide-icons-info' />
                                                                </LightTooltip>
                                                            </p>
                                                            <p className='dashboard-section-text-2'>$0</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className='dashboard-top-section-Swiper-Slide-4'>
                                                <div className="d-flex flex-row align-items-center">
                                                    <div>
                                                        <RiPercentLine className='swiper-slide-icons-4' />
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <p className='dashboard-section-text-1'>Bonding APY</p>
                                                            <p className='dashboard-section-text-2'>74 %</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>

                            <div className=" mt-3">
                                <div className="row g-4">
                                    <div className="col-lg-6 px-lg-5">
                                        <div className='dashboard-border-bottom'>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <div className=''>
                                                    <div><span className='dashboard-section-heading-1'>Volume</span>
                                                    <span className='dashboard-section-heading-2 fc-b'>
                                                    <NumberFormat value={this.state.getliquidsamount.slice(-1)[0]} thousandSeparator={true} displayType={'text'} prefix={'$'}/></span>
                                                    <span className='dashboard-section-heading-3 fc-r'>(-1.09%)</span></div>
                                                </div>
                                                <div className=''>
                                                    <button type="button" className="dashboard-section-week-button-1 me-2" onClick={this.volumebardata}>Week</button>
                                                    <button type="button" className="dashboard-section-all-button-1" onClick={this.volumedate}>All</button>
                                                </div>
                                            </div>
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb volume-breadcrumb">
                                                    <li className="breadcrumb-item"><a className='breadcrumb-total'  onClick={this.volumedate}>Total</a></li>
                                                    <li className="breadcrumb-item"><a className='breadcrumb-swap'  onClick={this.volumedate}>Swap</a></li>
                                                    <li className="breadcrumb-item"><a className='breadcrumb-add'  onClick={this.volumedate}>Add</a></li>
                                                    <li className="breadcrumb-item"><a className='breadcrumb-withdraw'  onClick={this.volumedate}>Withdraw</a></li>
                                                </ol>
                                            </nav>
                                        </div>
                                        <Bar className="bar-container" data={{
                                            labels: ['Aug 16','Aug 17','Aug 18','Aug 20','Aug 22','Aug 24'],
                                            datasets: [
                                            {
                                                backgroundColor:"#007aff",
                                                borderWidth: 0,
                                                barThickness:this.state.barthick,
                                                borderRadius:0,
                                                borderSkipped:true,
                                                animated:true,
                                                data: this.state.volumepath
                                            }
                                            ]
                                        }}
                                        options={{
                                            plugins: {
                                                tooltip: {
                                                titleAlign: 'center',
                                                titleFont: {weight: 'bold'},
                                                padding: 20,
                                                titleFont: {
                                                    size: 20
                                                },
                                                bodyFont: {
                                                    size: 26
                                                },
                                                },
                                                legend: {
                                                    display: false,
                                                }
                                            },
                                            scales: {
                                                x: {
                                                grid: {
                                                    display: false,
                                                }
                                                },
                                                y: {
                                                grid: {
                                                    display: false
                                                }
                                                },
                                            }
                                    }}/>
                                    </div>
                                    <div className="col-lg-6 px-lg-5">
                                        <div className='dashboard-border-bottom'>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <div className=''>
                                                    <div><span className='dashboard-section-heading-1'>Liquidity</span><span className='dashboard-section-heading-2 fc-b'>
                                                    <NumberFormat value={this.state.getliquidsamount.slice(-1)[0]} thousandSeparator={true} displayType={'text'} prefix={'$'}/></span><span className='dashboard-section-heading-3 fc-g'>(+0.13%)</span></div>
                                                </div>
                                                <div className=''>
                                                    <button type="button" className="dashboard-section-week-button-2 me-2" onClick={this.graphdata}>Week</button>
                                                    <button type="button" className="dashboard-section-all-button-2" onClick={this.alldataline}>All</button>
                                                </div>
                                            </div>
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb liquidity-breadcrumb">
                                                    <li className="breadcrumb-item"><a className='breadcrumb-liquidity'  onClick={this.graphdata}>Liquidity</a></li>
                                                    <li className="breadcrumb-item"><a className='breadcrumb-lpearn'  onClick={this.graphdata}>LP Earning</a></li>
                                                    <li className="breadcrumb-item"><a className='breadcrumb-bondearn'  onClick={this.graphdata}>Bond Earning</a></li>
                                                </ol>
                                            </nav>
                                        </div>
                                        <Line id="lineChart" data={{
                            labels: ['Aug 16','Aug 17','Aug 18','Aug 20','Aug 22','Aug 24'],
                            datasets: [
                            {
                                label: "data",
                                borderColor: this.state.gradientstroke,
                                pointBorderColor: this.state.gradientstroke,
                                pointBackgroundColor: this.state.gradientstroke,
                                pointHoverBackgroundColor: this.state.gradientstroke,
                                pointHoverBorderColor: this.state.gradientstroke,
                                pointBorderWidth: 4,
                                pointHoverRadius: 6,
                                pointHoverBorderWidth: 4,
                                pointStyle:"circle",
                                tension:0,
                                pointRadius: 2,
                                fill: true,
                                backgroundColor: this.state.gradientfill,
                                borderWidth: 3,
                                animated:true,
                                zoomEnabled: true,
                                labels: this.state.time,                       
                                data:this.state.setConImg
                                
                            }
                            ]
                        }}
                        options={{      
                        plugins: {
                            tooltip: {
                            titleAlign: 'center',
                            titleFont: {weight: 'bold'},
                            padding: 20,
                            titleFont: {
                                size: 20
                            },
                            bodyFont: {
                                size: 20
                            },
                            },
                            legend: {
                                display: false,
                            }
                        },
                        scales: {
                            x: {
                            grid: {
                                display: false,
                            }
                            },
                            y: {
                            grid: {
                                display: false
                            }
                            },
                        }
                        }}/>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row mt-3 mb-1 align-items-center justify-content-between">
                                <div className=''>
                                    <h5 className="swap-section-text-1 ms-4">Featured Pools</h5>
                                </div>
                            </div>
                            <div className="">
                                <div className="row justify-content-center">
                                    <div className="col">
                                        <Swiper
                                            spaceBetween={30}
                                            navigation={true}
                                            modules={[Navigation]}
                                            breakpoints={{
                                                "@0.00": {
                                                    slidesPerView: 2,
                                                    spaceBetween: 10,
                                                },
                                                "@0.75": {
                                                    slidesPerView: 2,
                                                    spaceBetween: 20,
                                                },
                                                "@1.00": {
                                                    slidesPerView: 2,
                                                    spaceBetween: 20,
                                                },
                                                "@1.50": {
                                                    slidesPerView: 3,
                                                    spaceBetween: 20,
                                                },
                                            }}
                                            className="mySwiper py-3 px-lg-5">
                                            <SwiperSlide className='py-lg-4'>
                                                <div className='row justify-content-center px-2 align-items-center g-4'>
                                                    <div className='col-lg-5 col-6'>
                                                        <p className='dashboard-section-text-3'>ETH</p>
                                                        <p className='dashboard-section-text-4'>$0</p>
                                                        <p className='dashboard-section-text-5 fc-g'>APR: 42 %</p>
                                                    </div>

                                                    <div className='col-5'>
                                                        <img src={logo} className='token-select-image' alt="home-img-1" />
                                                    </div>
                                                </div>
                                                <div className='row justify-content-center px-lg-4 text-center g-4 pt-3'>
                                                    <div className='col-6'>
                                                        <a href="/swap">
                                                            <button type="button" className="dashboard-section-swap-button">Swap</button>
                                                        </a>
                                                    </div>
                                                    <div className='col-6'>
                                                        <a href="/deposit">
                                                            <button type="button" className="dashboard-section-liquidity-button">Add Lquidity</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className='py-lg-4'>
                                                <div className='row justify-content-center px-2 align-items-center g-4'>
                                                    <div className='col-lg-5 col-6'>
                                                        <p className='dashboard-section-text-3'>BNB</p>
                                                        <p className='dashboard-section-text-4'>$0</p>
                                                        <p className='dashboard-section-text-5 fc-g'>APR: 42 %</p>
                                                    </div>
                                                    <div className='col-5'>
                                                        <img src={bnbimage} className='token-select-image' alt="home-img-1" />
                                                    </div>
                                                </div>
                                                <div className='row justify-content-center px-lg-4 text-center g-4 pt-3'>
                                                <div className='col-6'>
                                                        <a href="/swap">
                                                            <button type="button" className="dashboard-section-swap-button">Swap</button>
                                                        </a>
                                                    </div>
                                                    <div className='col-6'>
                                                        <a href="/deposit">
                                                            <button type="button" className="dashboard-section-liquidity-button">Add Lquidity</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </SwiperSlide>

                                            <SwiperSlide className='py-lg-4'>
                                                <div className='row justify-content-center px-2 align-items-center g-4'>
                                                    <div className='col-lg-5 col-6'>
                                                        <p className='dashboard-section-text-3'>MATIC</p>
                                                        <p className='dashboard-section-text-4'>$0</p>
                                                        <p className='dashboard-section-text-5 fc-g'>APR: 42 %</p>
                                                    </div>
                                                    <div className='col-5'>
                                                        <img src={maticimage} className='token-select-image' alt="home-img-1" />
                                                    </div>
                                                </div>
                                                <div className='row justify-content-center px-lg-4 text-center g-4 pt-3'>
                                                    <div className='col-6'>
                                                        <a href="/swap">
                                                            <button type="button" className="dashboard-section-swap-button">Swap</button>
                                                        </a>
                                                    </div>
                                                    <div className='col-6'>
                                                        <a href="/deposit">
                                                            <button type="button" className="dashboard-section-liquidity-button">Add Lquidity</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className='py-lg-4'>
                                                <div className='row justify-content-center px-2 align-items-center g-4'>
                                                    <div className='col-lg-5 col-6'>
                                                        <p className='dashboard-section-text-3'>CLT</p>
                                                        <p className='dashboard-section-text-4'>$0</p>
                                                        <p className='dashboard-section-text-5 fc-g'>APR: 42 %</p>
                                                    </div>
                                                    <div className='col-5'>
                                                        <img src={logo} className='token-select-image' alt="home-img-1" />
                                                    </div>
                                                </div>
                                                <div className='row justify-content-center px-lg-4 text-center g-4 pt-3'>
                                                    <div className='col-6'>
                                                        <a href="/swap">
                                                            <button type="button" className="dashboard-section-swap-button">Swap</button>
                                                        </a>
                                                    </div>
                                                    <div className='col-6'>
                                                        <a href="/deposit">
                                                            <button type="button" className="dashboard-section-liquidity-button">Add Lquidity</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>

                            <section className='top-dashboard-section-table'>
                                <div className="d-flex flex-row mt-3 mb-2 align-items-center justify-content-between">
                                    <div className=''>
                                        <h5 className="swap-section-text-1 ms-3">Liquidity Pools</h5>
                                    </div>
                                </div>
                                <div className=" dashboard-section-table pb-5">
                                    <div className="row justify-content-center">
                                        <div className="col">
                                            <div className="">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="table-responsive">
                                                            <table className="display" id="advance-1">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Pools</th>
                                                                        <th>USD Price</th>
                                                                        <th>Liquidity</th>
                                                                        <th>Volume 24H</th>
                                                                        <th>APR</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className=''>
                                                                {this.state.blocksdata}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                      </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Dashboard;