import * as React from 'react';
import '../Style.css';
import { IoGridOutline } from 'react-icons/io5';
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import { BsBoxArrowRight } from 'react-icons/bs';
import { AiFillCopy, AiOutlineReload, AiOutlineUnorderedList, AiOutlineSearch } from 'react-icons/ai';
import { BiArrowToBottom, BiArrowToTop } from 'react-icons/bi';
import Wyzth from '../images/Wyzth-01.png';
import { SymbolOverview } from "react-tradingview-embed";
import Topnav from "./Topnav"
import $ from "jquery";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Web3 from 'web3'; 
import axios from 'axios';
import { CoinGeckoClient } from 'coingecko-api-v3';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AwesomeQR } from "awesome-qr";
import  binance  from '../images/bscimages.svg.png'
import  polygon  from '../images/polygon.svg'
import ethereum from '../images/ethereum.png'
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {Bar} from 'react-chartjs-2'

const LightTooltip = styled(({ className, ...props }) => (<
    Tooltip {...props}
    classes={
        { popper: className }}
/>
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const MySwal = withReactContent(Swal)

function showList(e) {
    var $gridCont = $('.grid-container');
    e.preventDefault();
    $gridCont.hasClass('list-view') ? $gridCont.removeClass('list-view') : $gridCont.addClass('list-view');
}
function gridList(e) {
    var $gridCont = $('.grid-container')
    e.preventDefault();
    $gridCont.removeClass('list-view');
}
$(document).on('click', '.btn-grid', gridList);
$(document).on('click', '.btn-list', showList);



class Wallet extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            accountaddress:'',
            ethprice:'',
            maticprice:' 0',
            dataimage:'',
            bnbprice:'',
            ethaccountAddress:"",
            bscaccountAddress:"",
            polyaccountAddress:'',
            polybalance:'0',
            bscbalance:'0',
            etherbalance:'0',
            ethcolor:"",
            ethpricechange:"",
            bnbcolor:"",
            bnbpricechange:"",
            maticcolor:"",
            maticpricechange:""
        }
    }
    
    priceapi = async() => {   
        axios.get("https://api.etherscan.com/api?module=stats&action=ethprice&apikey=Q15HRHD2546QF1HIY155EPTAGPGPKANIJU").then(async(response)=> {
            this.setState({
                ethprice:response.data.result.ethusd
            })
        })
        axios.get("https://api.polygonscan.com/api?module=stats&action=maticprice&apikey=ANMMMAYVI88Z4GDZFJWF9BV9IIKDTIZDTG")
            .then(async(response) => {
            this.setState({
                maticprice:response.data.result.maticusd
            })
        })
        axios.get("https://api.bscscan.com/api?module=stats&action=bnbprice&apikey=34GWS33THPEJIITF3B4KWZF7KCR8M2MJEH")
            .then(async(response) => {
            this.setState({
                bnbprice:response.data.result.ethusd
            })
        })
    }

    changeprice = async() => {
        axios.get("https://api.binance.com/api/v1/ticker/24hr").then(async(response) => {          
            const ethprice = response.data[12].priceChangePercent
                this.setState({
                    ethpricechange:ethprice
                })
                if(ethprice >= 0) {
                    this.setState({
                        ethcolor:"green"
                    })
                } else {
                    this.setState({
                        ethcolor:"red"
                    })
                }

            const bnbprice = response.data[98].priceChangePercent
                this.setState({
                    bnbpricechange:bnbprice
                })
                if(bnbprice >= 0) {
                    this.setState({
                        bnbcolor:"green"
                    })
                } else {
                    this.setState({
                        bnbcolor:"red"
                    })
                }
            
            const maticprice = response.data[499].priceChangePercent
                this.setState({
                    maticpricechange:maticprice
                })
            if(bnbprice >= 0) {
                this.setState({
                    maticcolor:"green"
                })
            } else {
                this.setState({
                    maticcolor:"red"
                })
            }
        })
    }

    ethereum = async () => {
        const chainId = 0x5;
        var web3js = new Web3(window.ethereum);
        const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
        if (currentChainId !== chainId) {
            try {
                const currentChainId = await window.ethereum.request({ method: 'wallet_switchEthereumChain' , params: [{ chainId: web3js.utils.toHex(chainId) }] });
                const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
                $("#connect_poly").show();
                $("#connect_bsc").show();
                $("#connect_etherum").hide();    

                $("#hide_ethereum_button").show(); 
                $("#hide_poly_button").hide(); 
                $("#hide_bsc_button").hide();

                $("#etherbal").show(); 
                $("#polybal").hide(); 
                $("#bscbal").hide();
                var canvas = document.getElementById("canvaimage");
                var dataURL = canvas.toDataURL();
                $("#qrImgsrc").attr("src", dataURL);
                const imageurl = await new AwesomeQR({
                  text:account[0],
                  size:400,
                  whiteMargin:true,
                  autoColor:true,
                  dotScale:0.6
                }).draw()
                axios.get("https://api-goerli.etherscan.io/api?module=account&action=balance&address="+account[0]+"&apikey=Q15HRHD2546QF1HIY155EPTAGPGPKANIJU")
                    .then(async(response) => {
                    const etherbal = web3js.utils.fromWei(response.data.result, 'ether');
                    const etherbalfixed = parseFloat(etherbal).toFixed(3); 
                    this.setState({
                        etherbalance:etherbalfixed
                    })
                })
                this.setState({
                  accountaddress: account[0],
                  dataimage:imageurl,
                  ethaccountAddress:account[0]
                })
            } catch (switchError) {
                if (switchError.code === 4902) {
                    Swal.fire('Please Add goerli Testnet Network to your Metamask')     
                }
            }
        } 
    }

    polygon = async () => {
        const chainId = 0x13881;
        var web3js = new Web3(window.ethereum);
        const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
        if (currentChainId !== chainId) {
            try {
                const currentChainId = await window.ethereum.request({ method: 'wallet_switchEthereumChain' , params: [{ chainId: web3js.utils.toHex(chainId) }] });
                const account = await window.ethereum.request({ method: 'eth_requestAccounts' }); 
                var canvas = document.getElementById("canvaimage");
                var dataURL = canvas.toDataURL();
                $("#qrImgsrc").attr("src", dataURL);
                const imageurl = await new AwesomeQR({
                  text:account[0],
                  size:400,
                  whiteMargin:true,
                  autoColor:true,
                  dotScale:0.6
                }).draw()
                $("#connect_poly").hide();
                $("#connect_bsc").show();
                $("#connect_etherum").show();

                $("#hide_poly_button").show(); 
                $("#hide_ethereum_button").hide();
                $("#hide_bsc_button").hide();

                $("#etherbal").hide(); 
                $("#polybal").show(); 
                $("#bscbal").hide();
                axios.get("https://api-testnet.polygonscan.com/api?module=account&action=balance&address="+account[0]+"&apikey=ANMMMAYVI88Z4GDZFJWF9BV9IIKDTIZDTG")
                    .then(async(response) => {
                        const polybal = web3js.utils.fromWei(response.data.result, 'ether');
                        const polybalfixed = parseFloat(polybal).toFixed(3);  
                    this.setState({
                        polybalance:polybalfixed
                    })
                })
                this.setState({
                  accountaddress: account[0],
                  dataimage:imageurl,
                  polyaccountAddress:account[0]
                })
            } catch (switchError) {
                if (switchError.code === 4902) {
                    Swal.fire('Please Add polygon Testnet Network to your Metamask')     
                }
            }
        } 
    }

    bsc = async () => {
        const chainId = 0x61;
        var web3js = new Web3(window.ethereum);
        const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
        if (currentChainId !== chainId) {
            try {
                const currentChainId = await window.ethereum.request({ method: 'wallet_switchEthereumChain' , params: [{ chainId: web3js.utils.toHex(chainId) }] });
                const account = await window.ethereum.request({ method: 'eth_requestAccounts' });  
                var canvas = document.getElementById("canvaimage");
                var dataURL = canvas.toDataURL();
                $("#qrImgsrc").attr("src", dataURL);
                const imageurl = await new AwesomeQR({
                  text:account[0],
                  size:400,
                  whiteMargin:true,
                  autoColor:true,
                  dotScale:0.6
                }).draw()
                $("#connect_bsc").hide();
                $("#connect_poly").show();
                $("#connect_etherum").show();

                $("#hide_bsc_button").show();
                $("#hide_poly_button").hide(); 
                $("#hide_ethereum_button").hide();

                $("#etherbal").hide(); 
                $("#polybal").hide(); 
                $("#bscbal").show();

                axios.get("https://api-testnet.bscscan.com/api?module=account&action=balance&address="+account[0]+"&apikey=34GWS33THPEJIITF3B4KWZF7KCR8M2MJEH")
                .then(async(response) => {
                    const bscbal = web3js.utils.fromWei(response.data.result, 'ether');
                    const bscbalfixed = parseFloat(bscbal).toFixed(3);                      
                    this.setState({
                        bscbalance:bscbalfixed
                    })
                })
                this.setState({
                  accountaddress: account[0],
                  dataimage: imageurl,
                  bscaccountAddress: account[0]
                })
            } catch (switchError) {
                if (switchError.code === 4902) {
                    Swal.fire('Please Add bsc Testnet Network to your Metamask')     
                }
            }
        }
    }

    disconnect = async() => {
        $("#hide_ethereum_button").hide(); 
        $("#hide_poly_button").hide(); 
        $("#hide_bsc_button").hide();

        $("#connect_bsc").show();
        $("#connect_poly").show();
        $("#connect_etherum").show();

        $("#etherbal").hide(); 
        $("#polybal").hide(); 
        $("#bscbal").hide();
    }

    connectauto = async() => {
        if(window.ethereum.chainId == '0x5') { 
            this.ethereum()
        } 
        if(window.ethereum.chainId == '0x13881') { 
            this.polygon()
        }
        if(window.ethereum.chainId == '0x61') {
            this.bsc()
        }
    }

    componentDidMount() {
        $("#hide_ethereum_button").hide(); 
        $("#hide_poly_button").hide(); 
        $("#hide_bsc_button").hide(); 
        this.priceapi();
        this.changeprice();
        this.connectauto();
         $("#etherbal").hide(); 
         $("#polybal").hide(); 
         $("#bscbal").hide(); 
        $(document).ready(function () {
            $(".wallet-button-grid-list-1").addClass("on-off-bg-color1");
            $(".wallet-button-grid-list-1").click(function () {
                $(".wallet-button-grid-list-1").addClass("on-off-bg-color1");
                $(".wallet-button-grid-list-2").removeClass("on-off-bg-color1");
            });
            $(".wallet-button-grid-list-2").click(function () {
                $(".wallet-button-grid-list-2").addClass("on-off-bg-color1");
                $(".wallet-button-grid-list-1").removeClass("on-off-bg-color1");
            });
        });
    }

    componentWillUnmount() {
        clearInterval(this.interval); 
    }

    render() {
        return (
            <div className="App">
                <Topnav topconnect = {this.state.connect} />
                <section id="wallet-top-banner-section" className='mt-4'>
                    <div className='container-fluid'>
                        <div className='row justify-content-end'>
                            <div className='col-lg-9 col-12 me-lg-5'>
                                <div className="d-flex flex-row mb-4 align-items-center justify-content-between">
                                    <div className='ms-3'>
                                        <div className="input-group flex-nowrap">
                                            <span className="input-group-text" id="addon-wrapping"><AiOutlineSearch /></span>
                                            <input type="text" className="form-control-wallet-section" placeholder="Search" aria-describedby="addon-wrapping" />
                                        </div>
                                    </div>
                                    <div className='me-3'>
                                        <div className='wallet-button-grid-list-section'>
                                            <button className="btn wallet-button-grid-list-1 btn-grid"><IoGridOutline /></button>
                                            <button className="btn wallet-button-grid-list-2 btn-list"><AiOutlineUnorderedList /></button>
                                        </div>
                                    </div>
                                </div>

                                <div className=" grid-container">
                                    <div className="row g-4">
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div className='wallet-section-card'>
                                                <div className="d-flex flex-row wallet-section-card-button-section align-items-center justify-content-between">
                                                    <div>
                                                        <span className='wallet-section-text-1'>ETH</span>
                                                        <span className='wallet-section-text-9 text-info' id="etherbal">${this.state.etherbalance}</span>
                                                    </div>
                                                    <div id="hide_ethereum_button">
                                                        <span>
                                                            <label className='truncate-text'>{this.state.accountaddress}</label>
                                                            <CopyToClipboard text={this.state.accountaddress}
                                                                onCopy={() => this.setState({copied: true})}><AiFillCopy />
                                                            </CopyToClipboard>                               
                                                        </span>
                                                        <button className="reload-list-button ms-3"><AiOutlineReload /></button>
                                                        <button className="disconnect-list-button ms-2" onClick={this.disconnect}><BsBoxArrowRight /></button>
                                                    </div>
                                                    <div id="connect_etherum">
                                                        <button className="btn connect-wallet-list-button" onClick={this.ethereum}>connect</button>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <p className='list-view-all-headings'>Asset</p>
                                                        <div className="d-flex flex-row">
                                                            <div className="default-coin-name-section-1">
                                                                <span><img src={ethereum} className='wallet-section-logo' alt="wallet-logo" /></span>
                                                            </div>
                                                            <div className="default-coin-name-section-2">
                                                                <span className='wallet-section-text-2'>ETH</span><br />
                                                                <span className='wallet-section-text-3'>Native</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body top-amount-section">
                                                        <div className='row text-center'>
                                                            <div className='col'>
                                                                <p className='list-view-all-headings'>Amount</p>
                                                                <p className='wallet-section-text-8'>{this.state.etherbalance}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body price-widget-section-top">
                                                        <div className='row text-center'>
                                                            <div className='col'>
                                                                <p className='list-view-all-headings'>USD Price</p>
                                                                <p className='wallet-section-text-4'>${this.state.ethprice}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body percentage-widget-section-top">
                                                        <div className='row text-center'>
                                                            <div className='col'>
                                                                <p className='list-view-all-headings'>24h%</p>
                                                                <p className='wallet-section-text-5' style={{color : this.state.ethcolor}}>{this.state.ethpricechange}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body symboloverview-widget-section-top">
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <p className='list-view-all-headings'>Chart (7d)</p>
                                                                <div className='symboloverview-widget-section'>
                                                                <iframe id="tradingview_d02cc" src="https://s.tradingview.com/mediumwidgetembed/?symbols=BINANCE%3AETHUSDT%7C7D&amp;lineWidth=2&amp;valuesTracking=2&amp;noTimeScale=true&amp;fontFamily=-apple-system%2C+BlinkMacSystemFont%2C+Trebuchet+MS%2C+Roboto%2C+Ubuntu%2C+sans-serif&amp;bottomColor=rgba%2841%2C+98%2C+255%2C+0%29&amp;topColor=rgba%2841%2C+98%2C+255%2C+0.3%29&amp;lineColor=rgba%2849%2C+121%2C+245%2C+1%29&amp;chartType=area&amp;scaleMode=Normal&amp;scalePosition=no&amp;locale=in&amp;fontColor=rgba%28255%2C+0%2C+255%2C+1%29&amp;gridLineColor=transparent&amp;chartOnly=1&amp;width=100%25&amp;height=100%25&amp;colorTheme=light&amp;isTransparent=1&amp;hideDateRanges=1&amp;utm_source=localhost&amp;utm_medium=widget&amp;utm_campaign=symbol-overview&amp;utm_term=&amp;page-uri=http%3A%2F%2Flocalhost%3A3000%2Fwallet" style={{ margin: "0 !important", padding: "0 !important", width: "100%", height: "100%" }} frameBorder="0" allowtransparency="true" scrolling="no"></iframe>                                                               
                                                               
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card-body ">
                                                        <p className='list-view-all-headings'></p>
                                                        <div className="d-flex flex-row text-center justify-content-center">
                                                            <div className="wallet-section-buttons-section">
                                                                <a href="/send">
                                                                    <button type="button" className="wallet-section-buttons"><BiArrowToTop /></button>
                                                                </a>
                                                                <p className='wallet-section-text-6'>Send</p>                                                  
                                                            </div>
                                                            <LightTooltip title="Disconnect" placement="top">
                                                                <div className="wallet-section-buttons-section mx-3">                                                  
                                                                    <button  data-bs-toggle="modal"          
                                                                    className="btn wallet-section-buttons"  data-bs-target="#connect-qrcode" 
                                                                    disabled><BiArrowToBottom /></button>
                                                                    <p className='wallet-section-text-6'>Receive</p>
                                                                </div>
                                                            </LightTooltip>
                                                            <div className="wallet-section-buttons-section">
                                                                <a href="/swap">
                                                                    <button type="button" className="wallet-section-buttons"><CgArrowsExchangeAlt /></button>
                                                                </a>
                                                                <p className='wallet-section-text-6'>Swap</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body wallet-section-bottom-border">
                                                        <div className='row text-center py-5'>
                                                            <div className='col'>
                                                                <p className='wallet-section-text-7'>No data to show</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div className='wallet-section-card'>
                                                <div className="d-flex flex-row wallet-section-card-button-section align-items-center justify-content-between">                                         
                                                    <div>
                                                        <span className='wallet-section-text-1'>Polygon</span>
                                                        <span className='wallet-section-text-9 text-info' id="polybal">${this.state.polybalance}</span>
                                                    </div>
                                                    <div id="hide_poly_button">
                                                        <span>
                                                            <label className='truncate-text'>{this.state.accountaddress}</label>
                                                            <CopyToClipboard text={this.state.accountaddress}
                                                                    onCopy={() => this.setState({copied: true})}><AiFillCopy />
                                                            </CopyToClipboard>                                                       
                                                        </span>
                                                        <button className="reload-list-button ms-3"><AiOutlineReload /></button>
                                                        <button className="disconnect-list-button ms-2" onClick={this.disconnect}><BsBoxArrowRight /></button>
                                                    </div>
                                                    <div id="connect_poly">
                                                        <button className="btn connect-wallet-list-button"  onClick={this.polygon}>connect</button>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <p className='list-view-all-headings'>Asset</p>
                                                        <div className="d-flex flex-row">
                                                            <div className="default-coin-name-section-1">
                                                                <span><img src={polygon} className='wallet-section-logo' alt="wallet-logo" /></span>
                                                            </div>
                                                            <div className="default-coin-name-section-2">
                                                                <span className='wallet-section-text-2'>MATIC</span><br />
                                                                <span className='wallet-section-text-3'>Native</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body top-amount-section">
                                                        <div className='row text-center'>
                                                            <div className='col'>
                                                                <p className='list-view-all-headings'>Amount</p>
                                                                <p className='wallet-section-text-8'>{this.state.polybalance}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body price-widget-section-top">
                                                        <div className='row text-center'>
                                                            <div className='col'>
                                                                <p className='list-view-all-headings'>USD Price</p>
                                                                <p className='wallet-section-text-4'>${this.state.maticprice}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body percentage-widget-section-top">
                                                        <div className='row text-center'>
                                                            <div className='col'>
                                                                <p className='list-view-all-headings'>24h%</p>
                                                                <p className='wallet-section-text-5' style={{color : this.state.maticcolor}}>{this.state.maticpricechange}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body symboloverview-widget-section-top">
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <p className='list-view-all-headings'>Chart (7d)</p>
                                                                <div className='symboloverview-widget-section'>
                                                                <iframe id="tradingview_d02cc" src="https://s.tradingview.com/mediumwidgetembed/?symbols=BINANCE%3AMATICUSDT%7C7D&amp;lineWidth=2&amp;valuesTracking=2&amp;noTimeScale=true&amp;fontFamily=-apple-system%2C+BlinkMacSystemFont%2C+Trebuchet+MS%2C+Roboto%2C+Ubuntu%2C+sans-serif&amp;bottomColor=rgba%2841%2C+98%2C+255%2C+0%29&amp;topColor=rgba%2841%2C+98%2C+255%2C+0.3%29&amp;lineColor=rgba%2849%2C+121%2C+245%2C+1%29&amp;chartType=area&amp;scaleMode=Normal&amp;scalePosition=no&amp;locale=in&amp;fontColor=rgba%28255%2C+0%2C+255%2C+1%29&amp;gridLineColor=transparent&amp;chartOnly=1&amp;width=100%25&amp;height=100%25&amp;colorTheme=light&amp;isTransparent=1&amp;hideDateRanges=1&amp;utm_source=localhost&amp;utm_medium=widget&amp;utm_campaign=symbol-overview&amp;utm_term=&amp;page-uri=http%3A%2F%2Flocalhost%3A3000%2Fwallet" style={{ margin: "0 !important", padding: "0 !important", width: "100%", height: "100%" }} frameBorder="0" allowtransparency="true" scrolling="no"></iframe>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card-body ">
                                                        <p className='list-view-all-headings'></p>
                                                        <div className="d-flex flex-row text-center justify-content-center">
                                                            <div className="wallet-section-buttons-section">
                                                                <a href="/send">
                                                                    <button type="button" className="wallet-section-buttons"><BiArrowToTop /></button>
                                                                </a>
                                                                <p className='wallet-section-text-6'>Send</p>                                                            
                                                            </div>
                                                            <LightTooltip title="Disconnect" placement="top">
                                                            <div className="wallet-section-buttons-section mx-3">
                                                                <button disabled type="button" className="btn wallet-section-buttons" data-bs-toggle="modal" data-bs-target="#connect-qrcode"><BiArrowToBottom /></button>  
                                                                <p className='wallet-section-text-6'>Receive</p>
                                                            </div>
                                                            </LightTooltip>
                                                            <div className="wallet-section-buttons-section">
                                                                <a href="/swap">
                                                                    <button type="button" className="wallet-section-buttons"><CgArrowsExchangeAlt /></button>
                                                                </a>
                                                                <p className='wallet-section-text-6'>Swap</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body wallet-section-bottom-border">
                                                        <div className='row text-center py-5'>
                                                            <div className='col'>
                                                                <p className='wallet-section-text-7'>No data to show</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div className='wallet-section-card'>
                                                <div className="d-flex flex-row wallet-section-card-button-section align-items-center justify-content-between">
                                                    <div>
                                                        <span className='wallet-section-text-1'>Binance Chain</span>
                                                        <span className='wallet-section-text-9 text-info' id="bscbal">${this.state.bscbalance}</span>
                                                    </div>
                                                    <div id="hide_bsc_button">
                                                        <span>
                                                            <label className='truncate-text'>{this.state.accountaddress}</label>
                                                            <CopyToClipboard text={this.state.accountaddress}
                                                                onCopy={() => this.setState({copied: true})}><AiFillCopy />
                                                            </CopyToClipboard>   
                                                        </span>
                                                        <button className="reload-list-button ms-3"><AiOutlineReload /></button>
                                                        <button className="disconnect-list-button ms-2" onClick={this.disconnect}><BsBoxArrowRight /></button>
                                                    </div>
                                                    <div id="connect_bsc">
                                                        <button className="btn connect-wallet-list-button"  onClick={this.bsc}>connect</button>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <p className='list-view-all-headings'>Asset</p>
                                                        <div className="d-flex flex-row">
                                                            <div className="default-coin-name-section-1">
                                                                <span><img src={binance} className='wallet-section-logo' alt="wallet-logo" /></span>
                                                            </div>
                                                            <div className="default-coin-name-section-2">
                                                                <span className='wallet-section-text-2'>BNB</span><br />
                                                                <span className='wallet-section-text-3'>Native</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body top-amount-section">
                                                        <div className='row text-center'>
                                                            <div className='col'>
                                                                <p className='list-view-all-headings'>Amount</p>
                                                                <p className='wallet-section-text-8'>{this.state.bscbalance}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body price-widget-section-top">
                                                        <div className='row text-center'>
                                                            <div className='col'>
                                                                <p className='list-view-all-headings'>USD Price</p>
                                                                <p className='wallet-section-text-4'>${this.state.bnbprice}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body percentage-widget-section-top">
                                                        <div className='row text-center'>
                                                            <div className='col'>
                                                                <p className='list-view-all-headings'>24h%</p>
                                                                <p className='wallet-section-text-5' style={{color : this.state.bnbcolor}}>{this.state.bnbpricechange}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body symboloverview-widget-section-top">
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <p className='list-view-all-headings'>Chart (7d)</p>
                                                                <div className='symboloverview-widget-section'>
                                                                <iframe id="tradingview_d02cc" src="https://s.tradingview.com/mediumwidgetembed/?symbols=BINANCE%3ABNBUSDT%7C7D&amp;lineWidth=2&amp;valuesTracking=2&amp;noTimeScale=true&amp;fontFamily=-apple-system%2C+BlinkMacSystemFont%2C+Trebuchet+MS%2C+Roboto%2C+Ubuntu%2C+sans-serif&amp;bottomColor=rgba%2841%2C+98%2C+255%2C+0%29&amp;topColor=rgba%2841%2C+98%2C+255%2C+0.3%29&amp;lineColor=rgba%2849%2C+121%2C+245%2C+1%29&amp;chartType=area&amp;scaleMode=Normal&amp;scalePosition=no&amp;locale=in&amp;fontColor=rgba%28255%2C+0%2C+255%2C+1%29&amp;gridLineColor=transparent&amp;chartOnly=1&amp;width=100%25&amp;height=100%25&amp;colorTheme=light&amp;isTransparent=1&amp;hideDateRanges=1&amp;utm_source=localhost&amp;utm_medium=widget&amp;utm_campaign=symbol-overview&amp;utm_term=&amp;page-uri=http%3A%2F%2Flocalhost%3A3000%2Fwallet" style={{ margin: "0 !important", padding: "0 !important", width: "100%", height: "100%" }} frameBorder="0" allowtransparency="true" scrolling="no"></iframe>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card-body ">
                                                        <div className="d-flex flex-row text-center justify-content-center">
                                                            <div className="wallet-section-buttons-section">
                                                                <a href="/send">
                                                                    <button type="button" className="wallet-section-buttons"><BiArrowToTop /></button>
                                                                </a>
                                                                <p className='wallet-section-text-6'>Send</p>                                                            
                                                            </div>
                                                            <LightTooltip title="Disconnect" placement="top">
                                                            <div className="wallet-section-buttons-section mx-3">
                                                                <button  type="button" className="btn wallet-section-buttons" data-bs-toggle="modal" data-bs-target="#connect-qrcode" disabled={!this.state.bscaccountAddress}><BiArrowToBottom /></button>
                                                                <p className='wallet-section-text-6'>Receive</p>
                                                            </div>
                                                            </LightTooltip>
                                                            <div className="wallet-section-buttons-section">
                                                                <a href="/swap">
                                                                    <button type="button" className="wallet-section-buttons"><CgArrowsExchangeAlt /></button>
                                                                </a>
                                                                <p className='wallet-section-text-6'>Swap</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body wallet-section-bottom-border">
                                                        <div className='row text-center py-5'>
                                                            <div className='col'>
                                                                <p className='wallet-section-text-7'>No data to show</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="connect-wallet-select-section" className='swap-coin-select-section'>
                    <div className="modal fade Swap-coin-select-section-modal" id="connectwallet" tabindex="-1" aria-labelledby="connectwalletLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header modal-header-heading border-0 px-0">
                                    <h5 className="swap-coin-select-text-1">Select a Chain</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body connect-wallet-section pb-lg-5 pt-lg-4">
                                    <div className="row g-4 justify-content-center">
                                        <div className="col-lg-4 col-6">
                                            <button className='eth-connect-wallet-button connect-wallet-active' onClick={()=>this.liquiditystaking("polygon")} >
                                                <div className="d-flex flex-row justify-content-center text-start">
                                                    <div className='mt-1'>
                                                        <img src="https://seeklogo.com/images/P/polygon-matic-logo-86F4D6D773-seeklogo.com.png" alt="eth-logo" />
                                                    </div>
                                                    <div className='ps-1'>
                                                        <p className='connect-wallet-text-1'>MATIC</p>
                                                        <span className='connect-wallet-text-2'>{this.state.maticaccountaddress?"Connected":"Not Connected"}</span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                            <button className='bsc-connect-wallet-button' onClick={()=>this.liquiditystaking("bsc")}>
                                                <div className="d-flex flex-row justify-content-center text-start">
                                                    <div className='mt-1'>
                                                        <img src="https://seeklogo.com/images/B/binance-coin-bnb-logo-CD94CC6D31-seeklogo.com.png" alt="eth-logo" />
                                                    </div>
                                                    <div className='ps-1'>
                                                        <p className='connect-wallet-text-1'>BSC</p>
                                                        <span className='connect-wallet-text-2'>{this.state.bscaccountaddress?"Connected":"Not Connected"}</span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                            <button className='wyzth-connect-wallet-button' onClick={()=>this.liquiditystaking("ethereum")}>
                                                <div className="d-flex flex-row justify-content-center text-start">
                                                    <div className='mt-1'>
                                                        <img src={Wyzth} alt="eth-logo" />
                                                    </div>
                                                    <div className='ps-1'>
                                                        <p className='connect-wallet-text-1'>Wyzth</p>
                                                        <span className='connect-wallet-text-2'>{this.state.ethaccountaddress?"Connected":"Not Connected"}</span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 pt-0">
                                    <button className="btn btn-dark-blue button btn-lg d-block" onClick={() => {
                                        this.connectwallet();
                                    }}>CONNECT WALLET</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="connect-wallet-qrcode-section">
                    <div className="modal fade" id="connect-qrcode" tabindex="-1" aria-labelledby="connect-qrcodeLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header modal-header-heading border-0 px-0">
                                    <h5 className="swap-coin-select-text-1"></h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body p-0">
                                <canvas id="canvaimage"></canvas>
                                <img src={this.state.dataimage} className=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

        )
    }
}

export default Wallet;