import * as React from 'react';
import '../Style.css';
import { BsInfoCircle, BsQuestionCircle } from 'react-icons/bs';
import { RiAddLine } from 'react-icons/ri';
import { AiOutlineSetting, AiOutlineStar } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import Topnav from "./Topnav"
import $ from "jquery";

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {factoryAbi} from './factoryabi'
import {ercAbi} from './erc20abi'
import {routerAbi} from './routerabi'
import {pairAbi} from './pairaddressabi'
import Web3 from 'web3'; 
import axios from 'axios';

const MySwal = withReactContent(Swal)

const LightTooltip = styled(({ className, ...props }) => (<
    Tooltip {...props}
    classes={
        { popper: className }}
/>
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));



class Deposit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            accountaddress:'',
            fromtokennames: "",
            fromtokensymbols: "",
            fromcontractaddress: "",
            swapimgsrcfrom: "https://clarisco.s3.ap-south-1.amazonaws.com/clarisco+images/clarisco-logo.svg",
            swaptokennamefrom: "VRS",
            swaptokenvaluefrom: "",
            swapimgsrcto: "https://clarisco.s3.ap-south-1.amazonaws.com/clarisco+images/clarisco-logo.svg",
            swaptokennameto: "CLT",
            setweibalance:" 0",
            settoweibalance:" 0",
            setfirstamount:'',
            setliquidityfunction:'liquidity',
            wyzthprice:"",
            btcprice:"",
            wyzthbtc:""
        };
    }

    Swapfromvalue = () => {
        this.setState({
            swaptokennamefrom: document.getElementById('fromtokensymbol').innerHTML,
            swapimgsrcfrom: "https://clarisco.s3.ap-south-1.amazonaws.com/clarisco+images/clarisco-logo.svg"
        });
    }

    getfromvaluecontract = async(event) =>{
        event.preventDefault()
        var web3js = new Web3(window.ethereum);
        const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const erc1contract = new web3js.eth.Contract(ercAbi, event.target.value);
        const name = await erc1contract.methods.name().call();
        const symbol = await erc1contract.methods.symbol().call();
        const frombalance = await erc1contract.methods.balanceOf(account[0]).call()
        const fromweibalance = web3js.utils.fromWei(frombalance, 'ether');
        const  from_amount =  parseFloat(fromweibalance).toFixed(2);
            this.setState({
                fromtokennames : name,
                fromtokensymbols : symbol,
                fromcontractaddress: event.target.value,
                setweibalance:from_amount
            })
        $("#fromtokenimg").attr("src","https://clarisco.s3.ap-south-1.amazonaws.com/clarisco+images/clarisco-logo.svg");
    }

    fromliquidity = async() => {
        if(window.ethereum.chainId == '0x5') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const fromamount = document.getElementById('from_amount').value;
            const fromtokenAddr = document.getElementById('fromtokenaddress').value;
            const erc1contract = new web3js.eth.Contract(ercAbi, fromtokenAddr);
            const factorycontract = new web3js.eth.Contract(factoryAbi, '0x84b8b3c7043b9b2e3ea9d979ef135dce3a3653c4');
            const routercontract = new web3js.eth.Contract(routerAbi, '0xdbc38ca23cc773e4c2b483cfb3d7283e1c54d766');
            const pairAddress = await factorycontract.methods.getPair(fromtokenAddr,'0xDd3E0af4235E951b2cCeF829E688EAE3f6377614').call()
            var amounta = web3js.utils.toWei(fromamount, 'ether')
            await erc1contract.methods.approve('0xdbc38ca23cc773e4c2b483cfb3d7283e1c54d766',amounta).send({
                from:account[0]
            })
            const transfn = await routercontract.methods.addliquid(
                fromtokenAddr,
                amounta,
                account[0],
                pairAddress
            ).send({
                from:account[0]
            })
            Swal.fire({
                icon: 'success',
                background: 'linear-gradient(268deg,#3988f9,#3b3ad6)',
                color: "#ffffff",
                title: 'Transaction confirmed',
                html:'Your transaction hash is:<br/><br/>'+ 
                `<a href=${"https://goerli.etherscan.io/tx/"+transfn.transactionHash} target="_blank" style="color:white;">${transfn.transactionHash}</a>` ,
                confirmButtonColor: "#3b3ad6"
            })
        }
        else if(window.ethereum.chainId == '0x61') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const fromamount = document.getElementById('from_amount').value;
            const fromtokenAddr = document.getElementById('fromtokenaddress').value;
            const erc1contract = new web3js.eth.Contract(ercAbi, fromtokenAddr);
            const factorycontract = new web3js.eth.Contract(factoryAbi, '0x805c33B357A8201F7524f9019aeFA8f30c8ff5e0');
            const routercontract = new web3js.eth.Contract(routerAbi, '0x4bc464f8632535e4a09426bc529d312c03029ba0');
            const pairAddress = await factorycontract.methods.getPair(fromtokenAddr,'0xd9e830a5a7cbeeff24fcc871d2feb0c1210083fd').call()         
            var amounta = web3js.utils.toWei(fromamount, 'ether')
            await erc1contract.methods.approve('0x4bc464f8632535e4a09426bc529d312c03029ba0',amounta).send({
                from:account[0]
            })
            const transfn = await routercontract.methods.addliquid(
                fromtokenAddr,
                amounta,
                account[0],
                pairAddress
            ).send({
                from:account[0]
            })
            Swal.fire({
                icon: 'success',
                background: 'linear-gradient(268deg,#3988f9,#3b3ad6)',
                color: "#ffffff",
                title: 'Transaction confirmed',
                html:'Your transaction hash is: <br/><br/>'+
                `<a href=${"https://testnet.bscscan.com/tx/"+transfn.transactionHash} target="_blank" style="color:white;">${transfn.transactionHash}</a>` ,
                confirmButtonColor: "#3b3ad6"
            })
        } 
        else if(window.ethereum.chainId == '0x13881') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const fromamount = document.getElementById('from_amount').value;
            const fromtokenAddr = document.getElementById('fromtokenaddress').value;
            const erc1contract = new web3js.eth.Contract(ercAbi, fromtokenAddr);
            const factorycontract = new web3js.eth.Contract(factoryAbi, '0xb2679e2896b5531d64c11551c77533b779553b94');
            const routercontract = new web3js.eth.Contract(routerAbi, '0xe2485ab08e90a3f3b569c837daccc30c250e585a');
            const pairAddress = await factorycontract.methods.getPair(fromtokenAddr,'0xEe8173922F2b7C0B35DD0140456e45f6f286a834').call()
            var amounta = web3js.utils.toWei(fromamount, 'ether')
            await erc1contract.methods.approve('0xe2485ab08e90a3f3b569c837daccc30c250e585a',amounta).send({
                from:account[0]
            })
            const transfn = await routercontract.methods.addliquid(
                fromtokenAddr,
                amounta,
                account[0],
                pairAddress
            ).send({
                from:account[0]
            })
            Swal.fire({
                icon: 'success',
                background: 'linear-gradient(268deg,#3988f9,#3b3ad6)',
                color: "#ffffff",
                title: 'Transaction confirmed',
                html:'Your transaction hash is: <br/><br/>'+
                `<a href=${"https://mumbai.polygonscan.com/tx/"+transfn.transactionHash} target="_blank" style="color:white;">${transfn.transactionHash}</a>` ,
                confirmButtonColor: "#3b3ad6"
            })
        }
        else {
            MySwal.fire('Please connect to goerli,bsctestnet or polygontestnet')
        }
    }

    liquidity = async() => {
        if(window.ethereum.chainId == '0x5') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const erc1contract = new web3js.eth.Contract(ercAbi, this.state.fromcontractaddress);
            const erc2contract = new web3js.eth.Contract(ercAbi, '0xDd3E0af4235E951b2cCeF829E688EAE3f6377614');
            const routercontract = new web3js.eth.Contract(routerAbi, '0xdbc38ca23cc773e4c2b483cfb3d7283e1c54d766');
            const fromamount = document.getElementById('from_amountliquid').value;
            var amounta = web3js.utils.toWei(fromamount, 'ether')
            const toamount = document.getElementById('to_amountliquid').value;
            var amountb = web3js.utils.toWei(toamount, 'ether')
            await erc1contract.methods.approve('0xdbc38ca23cc773e4c2b483cfb3d7283e1c54d766', amounta).send({ from: account[0] });
            await erc2contract.methods.approve('0xdbc38ca23cc773e4c2b483cfb3d7283e1c54d766', amountb).send({ from: account[0] });
            const liquidity = await routercontract.methods.addLiquidity(
                this.state.fromcontractaddress,
                '0xDd3E0af4235E951b2cCeF829E688EAE3f6377614',
                amounta,
                amountb,
                account[0]
            ).send({
                from:account[0]
            })
            Swal.fire({
                icon: 'success',
                background: 'linear-gradient(268deg,#3988f9,#3b3ad6)',
                color: "#ffffff",
                title: 'Transaction confirmed',
                html:'Your transaction hash is:<br/><br/>'+ 
                `<a href=${"https://goerli.etherscan.io/tx/"+liquidity.transactionHash} target="_blank" style="color:white;">${liquidity.transactionHash}</a>` ,
                confirmButtonColor: "#3b3ad6"
            })
        }
        else if(window.ethereum.chainId == '0x61') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const erc1contract = new web3js.eth.Contract(ercAbi, this.state.fromcontractaddress);
            const erc2contract = new web3js.eth.Contract(ercAbi, '0xd9e830a5a7cbeeff24fcc871d2feb0c1210083fd');
            const routercontract = new web3js.eth.Contract(routerAbi, '0x4bc464f8632535e4a09426bc529d312c03029ba0');

            const fromamount = document.getElementById('from_amountliquid').value;
            var amounta = web3js.utils.toWei(fromamount, 'ether')
            const toamount = document.getElementById('to_amountliquid').value;
            var amountb = web3js.utils.toWei(toamount, 'ether')
            await erc1contract.methods.approve('0x4bc464f8632535e4a09426bc529d312c03029ba0', amounta).send({ from: account[0] });
            await erc2contract.methods.approve('0x4bc464f8632535e4a09426bc529d312c03029ba0', amountb).send({ from: account[0] });
            const liquidity = await routercontract.methods.addLiquidity(
                this.state.fromcontractaddress,
                '0xd9e830a5a7cbeeff24fcc871d2feb0c1210083fd',
                amounta,
                amountb,
                account[0]
            ).send({
                from:account[0]
            })
            Swal.fire({
                icon: 'success',
                background: 'linear-gradient(268deg,#3988f9,#3b3ad6)',
                color: "#ffffff",
                title: 'Transaction confirmed',
                html:'Your transaction hash is: <br/><br/>'+
                `<a href=${"https://testnet.bscscan.com/tx/"+liquidity.transactionHash} target="_blank" style="color:white;">${liquidity.transactionHash}</a>` ,
                confirmButtonColor: "#3b3ad6"
            })
        } else if(window.ethereum.chainId == '0x13881') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const erc1contract = new web3js.eth.Contract(ercAbi, this.state.fromcontractaddress);
            const erc2contract = new web3js.eth.Contract(ercAbi, '0xd02CeF627e900B8C5da2d99d7E5D879f0F10996e');
           
            const routercontract = new web3js.eth.Contract(routerAbi, '0xe2485ab08e90a3f3b569c837daccc30c250e585a');
           
            const fromamount = document.getElementById('from_amountliquid').value;
            var amounta = web3js.utils.toWei(fromamount, 'ether')
            const toamount = document.getElementById('to_amountliquid').value;
            var amountb = web3js.utils.toWei(toamount, 'ether')
            await erc1contract.methods.approve('0xe2485ab08e90a3f3b569c837daccc30c250e585a', amounta).send({ from: account[0] });
            await erc2contract.methods.approve('0xe2485ab08e90a3f3b569c837daccc30c250e585a', amountb).send({ from: account[0] });
            const liquidity = await routercontract.methods.addLiquidity(
                this.state.fromcontractaddress,
                '0xd02CeF627e900B8C5da2d99d7E5D879f0F10996e',
                amounta,
                amountb,
                account[0]
            ).send({
                from:account[0]
            })
            Swal.fire({
                icon: 'success',
                background: 'linear-gradient(268deg,#3988f9,#3b3ad6)',
                color: "#ffffff",
                title: 'Transaction confirmed',
                html:'Your transaction hash is: <br/><br/>'+
                `<a href=${"https://mumbai.polygonscan.com/tx/"+liquidity.transactionHash} target="_blank" style="color:white;">${liquidity.transactionHash}</a>` ,
                confirmButtonColor: "#3b3ad6"
            })
        }
        else {
            MySwal.fire('Please connect to goerli,bsctestnet or polygontestnet')
        }   
    }


    toliquidity = async () => {
        if(window.ethereum.chainId == '0x5') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const fromamount = document.getElementById('to_amount').value;
            var amounta = web3js.utils.toWei(fromamount, 'ether')
            const fromtokenAddr = document.getElementById('fromtokenaddress').value;
            const erc2contract = new web3js.eth.Contract(ercAbi, '0xDd3E0af4235E951b2cCeF829E688EAE3f6377614');
            const factorycontract = new web3js.eth.Contract(factoryAbi, '0x84b8b3c7043b9b2e3ea9d979ef135dce3a3653c4');
            const routercontract = new web3js.eth.Contract(routerAbi, '0xdbc38ca23cc773e4c2b483cfb3d7283e1c54d766');
            const pairAddress = await factorycontract.methods.getPair(fromtokenAddr,'0xDd3E0af4235E951b2cCeF829E688EAE3f6377614').call()
            await erc2contract.methods.approve('0xdbc38ca23cc773e4c2b483cfb3d7283e1c54d766', amounta).send({ from: account[0] });
            const transfn = await routercontract.methods.addliquid(
                '0xDd3E0af4235E951b2cCeF829E688EAE3f6377614',
                amounta,
                account[0],
                pairAddress
            ).send({
                from:account[0]
            })
            Swal.fire({
                icon: 'success',
                background: 'linear-gradient(268deg,#3988f9,#3b3ad6)',
                color: "#ffffff",
                title: 'Transaction confirmed',
                html:'Your transaction hash is:<br/><br/>'+ 
                `<a href=${"https://goerli.etherscan.io/tx/"+transfn.transactionHash} target="_blank" style="color:white;">${transfn.transactionHash}</a>` ,
                confirmButtonColor: "#3b3ad6"
            })
        } 
        else if(window.ethereum.chainId == '0x61') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const fromamount = document.getElementById('to_amount').value;
            var amounta = web3js.utils.toWei(fromamount, 'ether')
            const fromtokenAddr = document.getElementById('fromtokenaddress').value;
            const erc2contract = new web3js.eth.Contract(ercAbi, '0xd9e830a5a7cbeeff24fcc871d2feb0c1210083fd');
            const factorycontract = new web3js.eth.Contract(factoryAbi, '0x805c33B357A8201F7524f9019aeFA8f30c8ff5e0');
            const routercontract = new web3js.eth.Contract(routerAbi, '0x4bc464f8632535e4a09426bc529d312c03029ba0');
            const pairAddress = await factorycontract.methods.getPair(fromtokenAddr,'0xd9e830a5a7cbeeff24fcc871d2feb0c1210083fd').call()
            await erc2contract.methods.approve('0x4bc464f8632535e4a09426bc529d312c03029ba0', amounta).send({ from: account[0] });
            const transfn = await routercontract.methods.addliquid(
                '0xd9e830a5a7cbeeff24fcc871d2feb0c1210083fd',
                amounta,
                account[0],
                pairAddress
            ).send({
                from:account[0]
            })
            Swal.fire({
                icon: 'success',
                background: 'linear-gradient(268deg,#3988f9,#3b3ad6)',
                color: "#ffffff",
                title: 'Transaction confirmed',
                html:'Your transaction hash is: <br/><br/>'+
                `<a href=${"https://testnet.bscscan.com/tx/"+transfn.transactionHash} target="_blank" style="color:white;">${transfn.transactionHash}</a>` ,
                confirmButtonColor: "#3b3ad6"
            })
        } 
        else if(window.ethereum.chainId == '0x13881') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const fromamount = document.getElementById('to_amount').value;
            var amounta = web3js.utils.toWei(fromamount, 'ether')
            const fromtokenAddr = document.getElementById('fromtokenaddress').value;
            const erc2contract = new web3js.eth.Contract(ercAbi, '0xd02CeF627e900B8C5da2d99d7E5D879f0F10996e');
            const factorycontract = new web3js.eth.Contract(factoryAbi, '0xb2679e2896b5531d64c11551c77533b779553b94');
            const routercontract = new web3js.eth.Contract(routerAbi, '0xe2485ab08e90a3f3b569c837daccc30c250e585a');
            const pairAddress = await factorycontract.methods.getPair(fromtokenAddr,'0xd02CeF627e900B8C5da2d99d7E5D879f0F10996e').call()
            await erc2contract.methods.approve('0xe2485ab08e90a3f3b569c837daccc30c250e585a', amounta).send({ from: account[0] });
            const transfn = await routercontract.methods.addliquid(
                '0xd02CeF627e900B8C5da2d99d7E5D879f0F10996e',
                amounta,
                account[0],
                pairAddress
            ).send({
                from:account[0]
            })
            Swal.fire({
                icon: 'success',
                background: 'linear-gradient(268deg,#3988f9,#3b3ad6)',
                color: "#ffffff",
                title: 'Transaction confirmed',
                html:'Your transaction hash is: <br/><br/>'+
                `<a href=${"https://mumbai.polygonscan.com/tx/"+transfn.transactionHash} target="_blank" style="color:white;">${transfn.transactionHash}</a>` ,
                confirmButtonColor: "#3b3ad6"
            })
        }
        else {
            MySwal.fire('Please connect to goerli,bsctestnet or polygontestnet')
        }
    }

    liquiditystaking = async (value) => {
        this.setState({
            setliquidityfunction:value
        })
    }

    liquiditystake = async () => {
        if (this.state.setliquidityfunction == 'fromliquidity') {
            this.fromliquidity();
        }
        if (this.state.setliquidityfunction == 'liquidity') {
            this.liquidity();
        }
        if (this.state.setliquidityfunction == 'toliquidity') {
            this.toliquidity();
        }
    }

    metamask = async () => {
        if(window.ethereum.chainId == '0x5') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const erc1contract = new web3js.eth.Contract(ercAbi,'0xDd3E0af4235E951b2cCeF829E688EAE3f6377614');
            const tobalance = await erc1contract.methods.balanceOf(account[0]).call()
            const toweibalance =  web3js.utils.fromWei(tobalance, 'ether');
            const  to_amount =  parseFloat(toweibalance).toFixed(2);
                this.setState({
                    accountaddress: account[0],
                    settoweibalance:to_amount
                })
        } 
        else if(window.ethereum.chainId == '0x61') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const erc1contract = new web3js.eth.Contract(ercAbi,'0xd9e830a5a7cbeeff24fcc871d2feb0c1210083fd');
            const tobalance = await erc1contract.methods.balanceOf(account[0]).call()
            const toweibalance =  web3js.utils.fromWei(tobalance, 'ether');
            const  to_amount =  parseFloat(toweibalance).toFixed(2);
                this.setState({
                    accountaddress: account[0],
                    settoweibalance:to_amount
                })
        } 
        else if(window.ethereum.chainId == '0x13881') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const erc1contract = new web3js.eth.Contract(ercAbi,'0xd02CeF627e900B8C5da2d99d7E5D879f0F10996e');
            const tobalance = await erc1contract.methods.balanceOf(account[0]).call()
            const toweibalance =  web3js.utils.fromWei(tobalance, 'ether');
            const  to_amount =  parseFloat(toweibalance).toFixed(2);
                this.setState({
                    accountaddress: account[0],
                    settoweibalance:to_amount
                })
        }else {
            MySwal.fire('Please connect to goerli,bsctestnet or polygontestnet')
        }
        
    } 

    getprice = async() => {    
        axios.get("https://api.etherscan.com/api?module=stats&action=ethprice&apikey=Q15HRHD2546QF1HIY155EPTAGPGPKANIJU").then(async(response)=> {
            this.setState({
                wyzthprice:response.data.result.ethusd
            })
        })

        axios.get("https://min-api.cryptocompare.com/data/price?fsym=BTC&tsyms=USD")
          .then(async(response) => {
            this.setState({
              btcprice:response.data.USD
            })
        })
        const prices = ((this.state.btcprice / this.state.wyzthprice).toFixed(2));
          this.setState({
            wyzthbtc:prices
          })
    }

    componentDidMount() {
        window.addEventListener('load', this.metamask);
        this.liquiditystaking('fromliquidity');
        this.getprice();
        $(function () {
            $('.slippage-settings-button-05').css('background', 'transparent');
            $('.slippage-settings-button-1').css('background', '#0000 linear-gradient(268deg,#3989f9be,#3a3ad64f) 0 0 no-repeat');
            $('.slippage-settings-button-3').css('background', 'transparent');
            $('.slippage-percentage-section').css('border', '1px solid #7c859f');
            $('.slippage-settings-button-05').click(function () {
                $('.slippage-settings-button-1').css('background', 'transparent');
                $('.slippage-settings-button-05').css('background', '#0000 linear-gradient(268deg,#3989f9be,#3a3ad64f) 0 0 no-repeat');
                $('.slippage-settings-button-3').css('background', 'transparent');
                $('.slippage-percentage-section').css('border', '1px solid #7c859f');
            });
            $('.slippage-settings-button-1').click(function () {
                $('.slippage-settings-button-05').css('background', 'transparent');
                $('.slippage-settings-button-1').css('background', '#0000 linear-gradient(268deg,#3989f9be,#3a3ad64f) 0 0 no-repeat');
                $('.slippage-settings-button-3').css('background', 'transparent');
                $('.slippage-percentage-section').css('border', '1px solid #7c859f');
            });
            $('.slippage-settings-button-3').click(function () {
                $('.slippage-settings-button-05').css('background', 'transparent');
                $('.slippage-settings-button-3').css('background', '#0000 linear-gradient(268deg,#3989f9be,#3a3ad64f) 0 0 no-repeat');
                $('.slippage-settings-button-1').css('background', 'transparent');
                $('.slippage-percentage-section').css('border', '1px solid #7c859f');
            });
            $('.slippage-percentage-section').click(function () {
                $('.slippage-settings-button-05').css('background', 'transparent');
                $('.slippage-settings-button-3').css('background', 'transparent');
                $('.slippage-settings-button-1').css('background', 'transparent');
                $('.slippage-percentage-section').css('border', '2px solid #3988f9');
            });
        });
        $(function () {
            $(".fee-fast-button").addClass("slippage-settings-color");
            $('.fee-normal-button').click(function () {
                $(".fee-normal-button").addClass("slippage-settings-color");
                $(".fee-fast-button").removeClass("slippage-settings-color");
                $(".fee-instant-button").removeClass("slippage-settings-color");
            });
            $('.fee-fast-button').click(function () {
                $(".fee-fast-button").addClass("slippage-settings-color");
                $(".fee-normal-button").removeClass("slippage-settings-color");
                $(".fee-instant-button").removeClass("slippage-settings-color");
            });
            $('.fee-instant-button').click(function () {
                $(".fee-instant-button").addClass("slippage-settings-color");
                $(".fee-fast-button").removeClass("slippage-settings-color");
                $(".fee-normal-button").removeClass("slippage-settings-color");
            });
        });
        $(document).ready(function () {
            $(".expert-mode-off-button").addClass("on-off-bg-color");
            $(".expert-mode-off-button").click(function () {
                $(".expert-mode-off-button").addClass("on-off-bg-color");
                $(".expert-mode-on-button").removeClass("on-off-bg-color");
            });
            $(".expert-mode-on-button").click(function () {
                $(".expert-mode-on-button").addClass("on-off-bg-color");
                $(".expert-mode-off-button").removeClass("on-off-bg-color");
            });
            $(".recipient-off-button").addClass("on-off-bg-color");
            $(".recipient-off-button").click(function () {
                $(".recipient-off-button").addClass("on-off-bg-color");
                $(".recipient-on-button").removeClass("on-off-bg-color");
            });
            $(".recipient-on-button").click(function () {
                $(".recipient-on-button").addClass("on-off-bg-color");
                $(".recipient-off-button").removeClass("on-off-bg-color");
            });
        });
        $(document).ready(function () {
            $(".swap-select-form-filter-button-1").addClass("swap-select-form-filter-button-color");
            $(".swap-select-form-filter-button-1").click(function () {
                $(".swap-select-form-filter-button-1").addClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").removeClass("swap-select-form-filter-button-color");
            });
            $(".swap-select-form-filter-button-2").click(function () {
                $(".swap-select-form-filter-button-1").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").addClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").removeClass("swap-select-form-filter-button-color");
            });
            $(".swap-select-form-filter-button-3").click(function () {
                $(".swap-select-form-filter-button-1").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").addClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").removeClass("swap-select-form-filter-button-color");
            });
            $(".swap-select-form-filter-button-4").click(function () {
                $(".swap-select-form-filter-button-1").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").addClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").removeClass("swap-select-form-filter-button-color");
            });
            $(".swap-select-form-filter-button-5").click(function () {
                $(".swap-select-form-filter-button-1").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").addClass("swap-select-form-filter-button-color");
            });
        });
    }

    liquids = async() => {
        var firstamount =  this.state.setfirstamount
    }
 
    captureFile = (event)=> {
         event.preventDefault();
        this.setState({
            setfirstamount:event.target.value
        })     
    }

    render() {
        return (
            <div className="App">
                <Topnav />
                <section className="swap-top-banner-section">
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-5 ms-lg-5'>
                                <div className='row'>
                                    <div className='col'>
                                        <span className='swap-section-text-1 ms-4'>Add Liquidity</span>
                                    </div>
                                    <div className='col text-end'>
                                        <LightTooltip title="settings" placement="top">
                                            <span data-bs-toggle="modal" data-bs-target="#settingsslippage"><AiOutlineSetting className="top-right-swap-settings-icons" /></span>
                                        </LightTooltip>
                                    </div>
                                </div>

                                <div className='swap-section-background mt-3'>
                                    <div className='deposit-switch-tabs-section'>
                                        <div className='row'>
                                            <div className='col'>
                                                <ul className="nav nav-pills nav-justified deposit-switch-tabs-section-button" id="pills-tab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                        <button onClick={()=>this.liquiditystaking("fromliquidity")} className="nav-link active" id="pills-deposit1-tab" data-bs-toggle="pill" data-bs-target="#pills-deposit1" type="button" role="tab" aria-controls="pills-deposit1" aria-selected="true">{this.state.swaptokennamefrom}</button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                        <button onClick={()=>this.liquiditystaking("liquidity")} className="nav-link" id="pills-deposit2-tab" data-bs-toggle="pill" data-bs-target="#pills-deposit2" type="button" role="tab" aria-controls="pills-deposit2" aria-selected="false">{this.state.swaptokennamefrom}+CLT</button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                        <button onClick={()=>this.liquiditystaking("toliquidity")} className="nav-link" id="pills-deposit3-tab" data-bs-toggle="pill" data-bs-target="#pills-deposit3" type="button" role="tab" aria-controls="pills-deposit3" aria-selected="false">CLT</button>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="tab-content mt-3" id="pills-tabContent">
                                                {/* ================================ BTC ============================ */}
                                                <div className="tab-pane fade show active" id="pills-deposit1" role="tabpanel" aria-labelledby="pills-deposit1-tab">
                                                    <div className='row from-section-swap-top'>
                                                        <div className='col'>
                                                            <div className='row'>
                                                                <input id='from_amount' className="form-control-swap-text" placeholder='0' />
                                                            </div>
                                                            <div className='row mt-2'>
                                                                <span className='swap-section-text-2'>$ <span>0.00</span></span>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='row justify-content-end'>
                                                                <div className="col-12 from-section-coin-background" data-bs-toggle="modal" data-bs-target="#Swapcoinfrom">
                                                                    <div className="d-flex flex-row">
                                                                        <div className="">
                                                                            <span><img  src={this.state.swapimgsrcfrom}  className='swap-from-image'/></span></div>
                                                                        <div className="px-2">
                                                                            <p className='swap-section-text-3' value={this.state.fromtokennames}>{this.state.swaptokennamefrom}</p>
                                                                        </div>
                                                                        <div className="">
                                                                            <span><FaChevronDown className='swap-arrow-icons' /></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-2'>
                                                                <div className='col text-end'>
                                                                    <span className='balance-text-1 me-2'>Balance: {this.state.setweibalance}</span>
                                                                    <button type="button" className="swap-from-btn-max">Max</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row more-info-swap-accordian mt-2'>
                                                        <div className='col'>
                                                            <div className="accordion" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header" id="headingTwo">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                                            aria-expanded="false"
                                                                            aria-controls="collapseTwo">
                                                                            <span className='more-info-swap-accordian-text-1 me-auto'>
                                                                                <BsInfoCircle className='more-info-swap-accordian-info-icons me-2' />Prices and Pool Share </span>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            <div className='row align-items-center py-1'>
                                                                                <div className='col-4 text-start border-right-dotted'>
                                                                                    <span className='more-info-deposit-accordian-text-1'>ETH per BTC</span><br />
                                                                                    <span className='more-info-deposit-accordian-text-2'>{this.state.wyzthbtc} </span>
                                                                                </div>
                                                                                <div className='col-2 text-center border-right-dotted'>
                                                                                    <span className='more-info-deposit-accordian-text-1'>Slip</span><br />
                                                                                    <span className='more-info-deposit-accordian-text-2'>0 %</span>
                                                                                </div>
                                                                                <div className='col-2 text-center border-right-dotted'>
                                                                                    <span className='more-info-deposit-accordian-text-1'>Fee</span><br />
                                                                                    <span className='more-info-deposit-accordian-text-2'>$3.08</span>
                                                                                </div>
                                                                                <div className='col-4 text-end'>
                                                                                    <span className='more-info-deposit-accordian-text-1'>Share of Pool</span><br />
                                                                                    <span className='more-info-deposit-accordian-text-2'>0 %</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* ================================ BTC+ETH ============================ */}
                                                <div className="tab-pane fade" id="pills-deposit2" role="tabpanel" aria-labelledby="pills-deposit2-tab">
                                                    <div className='row from-section-swap-top'>
                                                        <div className='col'>
                                                            <div className='row'>
                                                                <input className="form-control-swap-text"  placeholder="0.0"  id='from_amountliquid' aria-label="example"></input>
                                                            </div>
                                                            <div className='row mt-2'>
                                                                <span className='swap-section-text-2'>$ <span>0.00</span></span>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='row justify-content-end'>
                                                                <div className="col-12 from-section-coin-background" data-bs-toggle="modal" data-bs-target="#Swapcoinfrom">
                                                                    <div className="d-flex flex-row">
                                                                        <div className="">
                                                                            <span><img  src={this.state.swapimgsrcfrom}  className='swap-from-image'/></span></div>
                                                                        <div className="px-2">
                                                                            <p className='swap-section-text-3' value={this.state.fromtokennames}>{this.state.swaptokennamefrom}</p>
                                                                        </div>
                                                                        <div className="">
                                                                            <span><FaChevronDown className='swap-arrow-icons' /></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-2'>
                                                                <div className='col text-end'>
                                                                    <span className='balance-text-1 me-2'>Balance: {this.state.setweibalance}</span>
                                                                    <button type="button" className="swap-from-btn-max">Max</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row swap-button-rotate-section'>
                                                        <div className='col text-center'>
                                                            <RiAddLine className='swap-button-rotate' />
                                                        </div>
                                                    </div>
                                                    <div className='row from-section-swap-top m-22'>
                                                        <div className='col'>
                                                            <div className='row'>
                                                            <input className="form-control-swap-text"  placeholder="0" id='to_amountliquid' aria-label="example" ></input>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='row justify-content-end'>
                                                                <div className="col-12 from-section-coin-background">
                                                                    <div className="d-flex  flex-row">
                                                                        <div className="">
                                                                        <span><img src="https://clarisco.s3.ap-south-1.amazonaws.com/clarisco+images/clarisco-logo.svg" className='swap-from-image' /></span>
                                                                        </div>
                                                                        <div className="px-2">
                                                                            <p className='swap-section-text-3'>CLT</p>                                           
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-2'>
                                                                <div className='col text-end'>
                                                                    <span className='balance-text-1 me-2'>Balance: {this.state.settoweibalance}</span>
                                                                    <button type="button" className="swap-from-btn-max">Max</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row more-info-swap-accordian mt-2'>
                                                        <div className='col'>
                                                            <div className="accordion" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header" id="headingTwo">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                                            aria-expanded="false"
                                                                            aria-controls="collapseTwo">
                                                                            <span className='more-info-swap-accordian-text-1 me-auto'>
                                                                                <BsInfoCircle className='more-info-swap-accordian-info-icons me-2' />Prices and Pool Share </span>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            <div className='row align-items-center py-1'>
                                                                                <div className='col-4 text-start border-right-dotted'>
                                                                                    <span className='more-info-deposit-accordian-text-1'>ETH per BTC</span><br />
                                                                                    <span className='more-info-deposit-accordian-text-2'>{this.state.wyzthbtc}</span>
                                                                                </div>
                                                                                <div className='col-2 text-center border-right-dotted'>
                                                                                    <span className='more-info-deposit-accordian-text-1'>Slip</span><br />
                                                                                    <span className='more-info-deposit-accordian-text-2'>0 %</span>
                                                                                </div>
                                                                                <div className='col-2 text-center border-right-dotted'>
                                                                                    <span className='more-info-deposit-accordian-text-1'>Fee</span><br />
                                                                                    <span className='more-info-deposit-accordian-text-2'>$3.08</span>
                                                                                </div>
                                                                                <div className='col-4 text-end'>
                                                                                    <span className='more-info-deposit-accordian-text-1'>Share of Pool</span><br />
                                                                                    <span className='more-info-deposit-accordian-text-2'>0 %</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* ================================ ETH ============================ */}
                                                <div className="tab-pane fade" id="pills-deposit3" role="tabpanel" aria-labelledby="pills-deposit3-tab">
                                                    <div className='row justify-content-lg-around from-section-swap-top'>
                                                        <div className='col-3'>
                                                            <div className='row'>
                                                                <input type="text" className="form-control-swap-text" placeholder='0'  id="to_amount" />
                                                            </div>
                                                        </div>
                                                        <div className='col-4'>
                                                            <div className='row justify-content-end'>
                                                                <div className="col-12 from-section-coin-background" data-bs-toggle="modal" data-bs-target="#Swapcoinfrom">
                                                                    <div className="d-flex flex-row">
                                                                        <div className="">
                                                                        <span><img src="https://clarisco.s3.ap-south-1.amazonaws.com/clarisco+images/clarisco-logo.svg" className='swap-from-image' /></span></div>
                                                                        <div className="px-2">
                                                                            <p className='swap-section-text-3'>CLT</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                    
                                                        <div className='col-4'>
                                                            <div className='row justify-content-end'>
                                                                <div className="col-12 from-section-coin-background" data-bs-toggle="modal" data-bs-target="#Swapcoinfrom">
                                                                    <div className="d-flex flex-row">
                                                                        <div className="">
                                                                        <span><img src={this.state.swapimgsrcfrom} className='swap-from-image' /></span></div>
                                                                        <div className="px-2">
                                                                        <p className='swap-section-text-3'>{this.state.swaptokennamefrom}</p>                                                                   
                                                                        </div>
                                                                        <div className="">
                                                                            <span><FaChevronDown className='swap-arrow-icons'/></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className='col'>
                                                                <span className='swap-section-text-2'>$ <span>0.00</span></span>
                                                            </div>
                                                            <div className='col text-end'>
                                                                <span className='balance-text-1 me-2'>Balance: {this.state.settoweibalance}</span>
                                                                <button type="button" className="swap-from-btn-max">Max</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                  

                                                    <div className='row more-info-swap-accordian mt-2'>
                                                        <div className='col'>
                                                            <div className="accordion" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header" id="headingTwo">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                                            aria-expanded="false"
                                                                            aria-controls="collapseTwo">
                                                                            <span className='more-info-swap-accordian-text-1 me-auto'>
                                                                                <BsInfoCircle className='more-info-swap-accordian-info-icons me-2' />Prices and Pool Share </span>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            <div className='row align-items-center py-1'>
                                                                                <div className='col-4 text-start border-right-dotted'>
                                                                                    <span className='more-info-deposit-accordian-text-1'>ETH per BTC</span><br />
                                                                                    <span className='more-info-deposit-accordian-text-2'>{this.state.wyzthbtc}</span>
                                                                                </div>
                                                                                <div className='col-2 text-center border-right-dotted'>
                                                                                    <span className='more-info-deposit-accordian-text-1'>Slip</span><br />
                                                                                    <span className='more-info-deposit-accordian-text-2'>0 %</span>
                                                                                </div>
                                                                                <div className='col-2 text-center border-right-dotted'>
                                                                                    <span className='more-info-deposit-accordian-text-1'>Fee</span><br />
                                                                                    <span className='more-info-deposit-accordian-text-2'>$3.08</span>
                                                                                </div>
                                                                                <div className='col-4 text-end'>
                                                                                    <span className='more-info-deposit-accordian-text-1'>Share of Pool</span><br />
                                                                                    <span className='more-info-deposit-accordian-text-2'>0 %</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className='row connect-wallet-button-section mt-lg-4 my-4 mx-2'>
                                        <button className="btn btn-dark-blue button btn-lg d-block" onClick={() => {
                                        this.liquiditystake();
                                    }}>{this.state.accountaddress ? 'ADD LIQUIDITY' : 'CONNECT WALLET'}</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>






                <section id="Swap-coin-select-section" className='swap-coin-select-section'>
                    <div className="modal fade Swap-coin-select-section-modal" id="Swapcoinfrom" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header ihuhlh border-0">
                                    <h5 className="swap-coin-select-text-1">Select a Token</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-header d-block py-2">
                                    <div className='row'>
                                        <div className='col'>
                                        <input id="myInput1" className="swap-select-form" type="text" placeholder="Search here..." autoComplete="off" onChange={this.getfromvaluecontract}/>
                                        </div>
                                    </div>
                                    <div className='row swap-select-form-filter-section mt-2'>
                                        <div className='col'>
                                            <button type="button" className="swap-select-form-filter-button-1">All</button>
                                            <button type="button" className="swap-select-form-filter-button-2">Synth</button>
                                            <button type="button" className="swap-select-form-filter-button-3">Native</button>
                                            <button type="button" className="swap-select-form-filter-button-4">Erc20</button>
                                            <button type="button" className="swap-select-form-filter-button-5">Bep2</button>
                                        </div>
                                    </div>

                                </div>

                                <div className="modal-body p-0 token-select-section">
                                    <ul className="list-group" id="myUL1">
                                        <li className="list-group-item" id="fromlist"><a onClick={this.Swapfromvalue} data-bs-dismiss="modal">
                                            <div className='row align-items-center'>
                                                <div className='col-1 text-end'>
                                                    <AiOutlineStar className='token-select-section-icon' />
                                                </div>
                                                <div className='col-1'>
                                                <img id="fromtokenimg" src="" width="30" alt="logo" className='token-select-image' onClick={this.swapimgsrcfrom} />
                                                </div>
                                                <div className='col pt-2'>
                                                <input type="hidden" id="fromtokenaddress" value={this.state.fromcontractaddress}></input>
                                                    <span className='token-select-section-text-1' value={this.state.fromtokennames} id="fromtokensymbol" >{this.state.fromtokennames}</span>
                                                </div>
                                            </div>
                                            </a>
                                        </li>                                     
                                    </ul>
                                </div>
                                <div className="modal-footer py-4">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="slippage-settings-section" className='slippage-settings-section'>
                    <div className="modal fade" id="settingsslippage" tabIndex="-1" aria-labelledby="settingsslippageLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <p className='slippage-settings-text-1'>Transaction Settings</p>
                                    <p className='slippage-settings-text-2'>Slippage Tolerance
                                        <LightTooltip title="Your transaction will revert if the price changes unfavorably by more than this percentage." placement="top">
                                            <BsQuestionCircle className='slippage-icon-text-1' />
                                        </LightTooltip>
                                    </p>
                                    <div className='row justify-content-center align-items-center'>
                                        <div className='col-3 d-flex slippage-percentage-section'>
                                            <span><input type="text" className="form-slippage-percentage"
                                                placeholder="0.0" /></span><span className='slippage-settings-text-3'>%</span>
                                        </div>

                                        <div className='col'>
                                            <button type="button" className="slippage-settings-button-05">0.5 %</button>
                                            <button type="button" className="slippage-settings-button-1">1 %</button>
                                            <button type="button" className="slippage-settings-button-3">3 %</button>
                                        </div>
                                    </div>

                                    <p className='slippage-settings-text-2 mt-4'>Transaction Fee
                                        <LightTooltip title="Accelerating a transaction by using a higher gas price increases its chances of getting processed by the network faster." placement="top">
                                            <BsQuestionCircle className='slippage-icon-text-1' />
                                        </LightTooltip></p>
                                    <div className='row justify-content-start align-items-center'>
                                        <div className='col'>
                                            <button type="button" className="fee-normal-button">Normal</button>
                                            <button type="button" className="fee-fast-button">Fast</button>
                                            <button type="button" className="fee-instant-button">Instant</button>
                                        </div>
                                    </div>

                                    <p className='slippage-settings-text-1 mt-4'>Transaction Mode</p>

                                    <div className='row justify-content-end align-items-center'>
                                        <div className='col-6'>
                                            <span className='slippage-settings-text-2'>Expert Mode
                                                <LightTooltip title="You are able to pool RUNE + Asset with the dynamic ratio in Expert Mode." placement="top">
                                                    <BsQuestionCircle className='slippage-icon-text-1' />
                                                </LightTooltip>
                                            </span>
                                        </div>
                                        <div className='col text-end'>
                                            <div className='bg-mode-0n-0ff '>
                                                <button type="button" className="expert-mode-on-button">ON</button>
                                                <button type="button" className="expert-mode-off-button">OFF</button>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='row justify-content-end align-items-center mt-3'>
                                        <div className='col-7'>
                                            <span className='slippage-settings-text-2'>Set Custom Recipient
                                                <LightTooltip title="Receive asset to your custom address." placement="top">

                                                    <BsQuestionCircle className='slippage-icon-text-1' />

                                                </LightTooltip>
                                            </span>
                                        </div>
                                        <div className='col text-end'>
                                            <div className='bg-mode-0n-0ff '>
                                                <button type="button" className="recipient-on-button">ON</button><button type="button" className="recipient-off-button">OFF</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Deposit;