import * as React from 'react';
import '../Style.css';
import { BsInfoCircle } from 'react-icons/bs';
import { HiArrowDown } from 'react-icons/hi';
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import { AiOutlineReload } from 'react-icons/ai';
import Topnav from "./Topnav"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {factoryAbi} from './factoryabi'
import {ercAbi} from './erc20abi'
import {routerAbi} from './routerabi'
import {pairAbi} from './pairaddressabi'
import { stakeAbi } from './stakingabi'
import Web3 from 'web3'; 
import { ethers } from 'ethers';
import $ from "jquery";
import logo from '../images/wyzth-img.jpeg'

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


const LightTooltip = styled(({ className, ...props }) => (<
    Tooltip {...props}
    classes={
        { popper: className }}
/>
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const MySwal = withReactContent(Swal)

class Deposit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            setInputValue : '',
            setTotalTL2contractBalance : '',
            setUserbalance : '0',
            setStakingBalance : '0',
            setAPY : '0',
            setstakefunction : 'stake',
            setUserbalanceB : '0',
            accountaddress : '',
        }
    }

    staking = async (value) => {
        this.setState({
            setstakefunction:value
        })
    }

    stake = async () => {
        if (this.state.setstakefunction == 'stake') {
            console.log("stake function")
            this.stakeHandler();
        }
        if (this.state.setstakefunction == 'unstake') {
            console.log("unstake function")
            this.unStakeHandler();
        }
    }

    addmetamask = async() => {
        if(window.ethereum.chainId == '0x5') {
            var addchain = await window.ethereum.request({method: 'wallet_watchAsset',params: {
                type: 'ERC20',
                options: {
                    address: '0x7295aF97C28E603c3a45764545d3814e0E4822B6',
                    symbol: 'VERSE',
                    decimals: 18,
                    image: 'https://clarisco.s3.ap-south-1.amazonaws.com/clarisco+images/clarisco-logo.svg',
                },
                },
            })
        } else if(window.ethereum.chainId == '0x61') {
            var addchain = await window.ethereum.request({method: 'wallet_watchAsset',params: {
                type: 'ERC20',
                options: {
                    address: '0xd02CeF627e900B8C5da2d99d7E5D879f0F10996e',
                    symbol: 'VERSE',
                    decimals: 18,
                    image: 'https://clarisco.s3.ap-south-1.amazonaws.com/clarisco+images/clarisco-logo.svg',
                },
                },
            })
        }
        else if(window.ethereum.chainId == '0x13881') {
            var addchain = await window.ethereum.request({method: 'wallet_watchAsset',params: {
                type: 'ERC20',
                options: {
                    address: '0x689de884308670995918B9c4DC1eAa168FF8F878',
                    symbol: 'VERSE',
                    decimals: 18,
                    image: 'https://clarisco.s3.ap-south-1.amazonaws.com/clarisco+images/clarisco-logo.svg',
                },
                },
            })
        } else {
            MySwal.fire('This is a demo Please connect to required network')
        }
    }

// STAKE FUNCTION
    stakeHandler = async () => {
        if(window.ethereum.chainId == '0x5') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            window.Web3 = new Web3(window.ethereum);
            const stakercontract = new web3js.eth.Contract(stakeAbi, '0x148a0054CfC12a8742CBCD2fB668dd1Cd606CeF3');
            const erc1contract = new web3js.eth.Contract(ercAbi, '0xc7a10920d8F16294B34CA9923F2a6340F1B2047F');
            var inputvalueb = ((this.state.setInputValue * 79) / 100).toFixed(3);
            const amountA = web3js.utils.toWei(this.state.setInputValue, 'ether');
            const amountB = web3js.utils.toWei(inputvalueb, 'ether');
            await erc1contract.methods.approve("0x148a0054CfC12a8742CBCD2fB668dd1Cd606CeF3", amountA).send({
                from: account[0]
            })
            const addresstake = await stakercontract.methods.stake(amountA, amountB).send({
                from: account[0]
            })
            Swal.fire({
                icon: 'success',
                background: 'linear-gradient(268deg,#3988f9,#3b3ad6)',
                color: "#ffffff",
                title: 'Transaction confirmed',
                html:'Your transaction hash is:<br/><br/>'+ 
                `<a href=${"https://goerli.etherscan.io/tx/"+addresstake.transactionHash} target="_blank" style="color:white;">${addresstake.transactionHash}</a>` ,
                confirmButtonColor: "#3b3ad6"
            })
        } 
        else if(window.ethereum.chainId == '0x61') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            window.Web3 = new Web3(window.ethereum);
            const stakercontract = new web3js.eth.Contract(stakeAbi, '0x14759fe0580beac6ffeb918e7ed31999e482b7f7');
            const erc1contract = new web3js.eth.Contract(ercAbi, '0xd02CeF627e900B8C5da2d99d7E5D879f0F10996e');
            var inputvalueb = ((this.state.setInputValue * 79) / 100).toFixed(3);
            const amountA = web3js.utils.toWei(this.state.setInputValue, 'ether');
            const amountB = web3js.utils.toWei(inputvalueb, 'ether');
            await erc1contract.methods.approve("0x14759fe0580beac6ffeb918e7ed31999e482b7f7", amountA).send({
                from: account[0]
            })
            const addresstake = await stakercontract.methods.stake(amountA, amountB).send({
                from: account[0]
            })
            Swal.fire({
                icon: 'success',
                background: 'linear-gradient(268deg,#3988f9,#3b3ad6)',
                color: "#ffffff",
                title: 'Transaction confirmed',
                html:'Your transaction hash is: <br/><br/>'+
                `<a href=${"https://testnet.bscscan.com/tx/"+addresstake.transactionHash} target="_blank" style="color:white;">${addresstake.transactionHash}</a>` ,
                confirmButtonColor: "#3b3ad6"
            })
        } 
        else if(window.ethereum.chainId == '0x13881') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            window.Web3 = new Web3(window.ethereum);
            const stakercontract = new web3js.eth.Contract(stakeAbi, '0x095a5a2ae12cd9a213c42213e07a443784919972');
            const erc1contract = new web3js.eth.Contract(ercAbi, '0x689de884308670995918B9c4DC1eAa168FF8F878');
            var inputvalueb = ((this.state.setInputValue * 79) / 100).toFixed(3);
            const amountA = web3js.utils.toWei(this.state.setInputValue, 'ether');
            const amountB = web3js.utils.toWei(inputvalueb, 'ether');
            await erc1contract.methods.approve("0x095a5a2ae12cd9a213c42213e07a443784919972", amountA).send({
                from: account[0]
            })
            const addresstake = await stakercontract.methods.stake(amountA, amountB).send({
                from: account[0]
            })
            Swal.fire({
                icon: 'success',
                background: 'linear-gradient(268deg,#3988f9,#3b3ad6)',
                color: "#ffffff",
                title: 'Transaction confirmed',
                html:'Your transaction hash is: <br/><br/>'+
                `<a href=${"https://mumbai.polygonscan.com/tx/"+addresstake.transactionHash} target="_blank" style="color:white;">${addresstake.transactionHash}</a>` ,
                confirmButtonColor: "#3b3ad6"
            })
        } else {
            MySwal.fire('This is a demo Please connect to required network')
        }
    }

// UNSTAKE FUNCTION
    unStakeHandler = async () => {
        if(window.ethereum.chainId == '0x5') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            window.web3 = new Web3(window.ethereum);
            const stakercontract = new web3js.eth.Contract(stakeAbi, '0x148a0054CfC12a8742CBCD2fB668dd1Cd606CeF3');
            const erc1contract = new web3js.eth.Contract(ercAbi, '0xEe8173922F2b7C0B35DD0140456e45f6f286a834');
            var inputvalueb = ((this.state.setInputValue * 126) / 100).toFixed(3);
            const amountA = web3js.utils.toWei(this.state.setInputValue, 'ether');
            const amountB = web3js.utils.toWei(inputvalueb, 'ether');
            await erc1contract.methods.approve("0x148a0054CfC12a8742CBCD2fB668dd1Cd606CeF3", amountA).send({
                from: account[0]
            })
            const addresstake2 = await stakercontract.methods.unstake(amountA, amountB, '0xc7a10920d8F16294B34CA9923F2a6340F1B2047F').send({
                from: account[0]
            })
            Swal.fire({
                icon: 'success',
                background: 'linear-gradient(268deg,#3988f9,#3b3ad6)',
                color: "#ffffff",
                title: 'Transaction confirmed',
                html:'Your transaction hash is:<br/><br/>'+ 
                `<a href=${"https://goerli.etherscan.io/tx/"+addresstake2.transactionHash} target="_blank" style="color:white;">${addresstake2.transactionHash}</a>` ,
                confirmButtonColor: "#3b3ad6"
            })
        } 
        else if(window.ethereum.chainId == '0x61') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            window.web3 = new Web3(window.ethereum);
            const stakercontract = new web3js.eth.Contract(stakeAbi, '0x14759fe0580beac6ffeb918e7ed31999e482b7f7');
            const erc1contract = new web3js.eth.Contract(ercAbi, '0xCdFdd77C14213DfaDe4FbF1255F7e6fbD50F00Fb');
            var inputvalueb = ((this.state.setInputValue * 126) / 100).toFixed(3);
            const amountA = web3js.utils.toWei(this.state.setInputValue, 'ether');
            const amountB = web3js.utils.toWei(inputvalueb, 'ether');
            await erc1contract.methods.approve("0x14759fe0580beac6ffeb918e7ed31999e482b7f7", amountA).send({
                from: account[0]
            })
            const addresstake2 = await stakercontract.methods.unstake(amountA, amountB, '0xd02CeF627e900B8C5da2d99d7E5D879f0F10996e').send({
                from: account[0]
            })
            Swal.fire({
                icon: 'success',
                background: 'linear-gradient(268deg,#3988f9,#3b3ad6)',
                color: "#ffffff",
                title: 'Transaction confirmed',
                html:'Your transaction hash is: <br/><br/>'+
                `<a href=${"https://testnet.bscscan.com/tx/"+addresstake2.transactionHash} target="_blank" style="color:white;">${addresstake2.transactionHash}</a>` ,
                confirmButtonColor: "#3b3ad6"
            })
        }
        else if(window.ethereum.chainId == '0x13881') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            window.web3 = new Web3(window.ethereum);
            const stakercontract = new web3js.eth.Contract(stakeAbi, '0x095a5a2ae12cd9a213c42213e07a443784919972');
            const erc1contract = new web3js.eth.Contract(ercAbi, '0x666a3307ADc39846cbB1d4A999f69DA632dE1572');
            var inputvalueb = ((this.state.setInputValue * 126) / 100).toFixed(3);
            const amountA = web3js.utils.toWei(this.state.setInputValue, 'ether');
            const amountB = web3js.utils.toWei(inputvalueb, 'ether');
            await erc1contract.methods.approve("0x095a5a2ae12cd9a213c42213e07a443784919972", amountA).send({
                from: account[0]
            })
            const addresstake2 = await stakercontract.methods.unstake(amountA, amountB, '0x689de884308670995918B9c4DC1eAa168FF8F878').send({
                from: account[0]
            })
            Swal.fire({
                icon: 'success',
                background: 'linear-gradient(268deg,#3988f9,#3b3ad6)',
                color: "#ffffff",
                title: 'Transaction confirmed',
                html:'Your transaction hash is: <br/><br/>'+
                `<a href=${"https://mumbai.polygonscan.com/tx/"+addresstake2.transactionHash} target="_blank" style="color:white;">${addresstake2.transactionHash}</a>` ,
                confirmButtonColor: "#3b3ad6"
            })
        } else {
            MySwal.fire('This is a demo Please connect to required network')
        }
    };

    inputHandler = (received) => {
        this.setState({
            setInputValue:received
        })
    };

    inputChangeHandler = (event) => {
        event.preventDefault();
        this.inputHandler(event.target.value)
    };

    goMax = () => {
        this.setState({
            setInputValue:this.state.userbalance
        })
        this.inputHandler(this.state.userbalance)
    };

    totalStaked = async () => {
        if(window.ethereum.chainId == '0x5') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const stakercontract = new web3js.eth.Contract(stakeAbi, '0x148a0054CfC12a8742CBCD2fB668dd1Cd606CeF3');
            const erc1contract = new web3js.eth.Contract(ercAbi, '0xc7a10920d8F16294B34CA9923F2a6340F1B2047F');
            const ercbal = await erc1contract.methods.balanceOf(account[0]).call()
            const tokenBalance = web3js.utils.fromWei(ercbal, 'ether');
            const tl2contractbalance = await parseFloat(tokenBalance).toFixed(4);
            var fixedApy = await stakercontract.methods.fixedAPY().call();  
            this.setState({
                setTotalTL2contractBalance: tl2contractbalance,
                setAPY: fixedApy
            })
            this.getAccountBalance();
        } 
        else if(window.ethereum.chainId == '0x61') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const stakercontract = new web3js.eth.Contract(stakeAbi, '0x14759fe0580beac6ffeb918e7ed31999e482b7f7');
            const erc1contract = new web3js.eth.Contract(ercAbi, '0xd02CeF627e900B8C5da2d99d7E5D879f0F10996e');
            const ercbal = await erc1contract.methods.balanceOf(account[0]).call()
            const tokenBalance = web3js.utils.fromWei(ercbal, 'ether');
            const tl2contractbalance = await parseFloat(tokenBalance).toFixed(4);
            var fixedApy = await stakercontract.methods.fixedAPY().call();  
            this.setState({
                setTotalTL2contractBalance: tl2contractbalance,
                setAPY: fixedApy
            })
            this.getAccountBalance();
        }
        else if(window.ethereum.chainId == '0x13881') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const stakercontract = new web3js.eth.Contract(stakeAbi, '0x095a5a2ae12cd9a213c42213e07a443784919972');
            const erc1contract = new web3js.eth.Contract(ercAbi, '0x689de884308670995918B9c4DC1eAa168FF8F878');
            const ercbal = await erc1contract.methods.balanceOf(account[0]).call()
            const tokenBalance = web3js.utils.fromWei(ercbal, 'ether');
            const tl2contractbalance = await parseFloat(tokenBalance).toFixed(4);
            var fixedApy = await stakercontract.methods.fixedAPY().call();  
            this.setState({
                setTotalTL2contractBalance: tl2contractbalance,
                setAPY: fixedApy
            })
            this.getAccountBalance();
        }   
    }

    getAccountBalance = async () => {
        if(window.ethereum.chainId == '0x5') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const erc1contract = new web3js.eth.Contract(ercAbi, '0xc7a10920d8F16294B34CA9923F2a6340F1B2047F');
            const ercbal = await erc1contract.methods.balanceOf(account[0]).call()
            const tokenBalance = ethers.utils.formatEther(ercbal);
            var tokenAbalance = await parseFloat(tokenBalance).toFixed(4);
            const erc1contract2 = new web3js.eth.Contract(ercAbi, '0xEe8173922F2b7C0B35DD0140456e45f6f286a834');
            const ercbal2 = await erc1contract2.methods.balanceOf(account[0]).call()
            const tokenBalance2 = ethers.utils.formatEther(ercbal2);
            var tokenBbalance = await parseFloat(tokenBalance2).toFixed(4);
            this.setState({
                setUserbalance: tokenAbalance,
                setUserbalanceB: tokenBbalance
            })
        } 
        else if(window.ethereum.chainId == '0x61') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const erc1contract = new web3js.eth.Contract(ercAbi, '0xd02CeF627e900B8C5da2d99d7E5D879f0F10996e');
            const ercbal = await erc1contract.methods.balanceOf(account[0]).call()
            const tokenBalance = ethers.utils.formatEther(ercbal);
            var tokenAbalance = await parseFloat(tokenBalance).toFixed(4);
            const erc1contract2 = new web3js.eth.Contract(ercAbi, '0xCdFdd77C14213DfaDe4FbF1255F7e6fbD50F00Fb');
            const ercbal2 = await erc1contract2.methods.balanceOf(account[0]).call()
            const tokenBalance2 = ethers.utils.formatEther(ercbal2);
            var tokenBbalance = await parseFloat(tokenBalance2).toFixed(4);
            this.setState({
                setUserbalance: tokenAbalance,
                setUserbalanceB: tokenBbalance
            })
        }
        else if(window.ethereum.chainId == '0x13881') {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const erc1contract = new web3js.eth.Contract(ercAbi, '0x689de884308670995918B9c4DC1eAa168FF8F878');
            const ercbal = await erc1contract.methods.balanceOf(account[0]).call()
            const tokenBalance = ethers.utils.formatEther(ercbal);
            var tokenAbalance = await parseFloat(tokenBalance).toFixed(4);
            const erc1contract2 = new web3js.eth.Contract(ercAbi, '0x666a3307ADc39846cbB1d4A999f69DA632dE1572');
            const ercbal2 = await erc1contract2.methods.balanceOf(account[0]).call()
            const tokenBalance2 = ethers.utils.formatEther(ercbal2);
            var tokenBbalance = await parseFloat(tokenBalance2).toFixed(4);
            this.setState({
                setUserbalance: tokenAbalance,
                setUserbalanceB: tokenBbalance
            })
        } else {
            MySwal.fire('This is a demo Please connect to required network')
        }   
    };

    metamask = async () => {          
        var web3js = new Web3(window.ethereum);
        const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
        this.setState({
            accountaddress: account[0]
        })        
    }

    componentDidMount() {
        window.addEventListener('load', this.metamask());
        this.totalStaked();
        $(function () {
            $('.slippage-settings-button-05').css('background', 'transparent');
            $('.slippage-settings-button-1').css('background', '#0000 linear-gradient(268deg,#3989f9be,#3a3ad64f) 0 0 no-repeat');
            $('.slippage-settings-button-3').css('background', 'transparent');
            $('.slippage-percentage-section').css('border', '1px solid #7c859f');
            $('.slippage-settings-button-05').click(function () {
                $('.slippage-settings-button-1').css('background', 'transparent');
                $('.slippage-settings-button-05').css('background', '#0000 linear-gradient(268deg,#3989f9be,#3a3ad64f) 0 0 no-repeat');
                $('.slippage-settings-button-3').css('background', 'transparent');
                $('.slippage-percentage-section').css('border', '1px solid #7c859f');
            });
            $('.slippage-settings-button-1').click(function () {
                $('.slippage-settings-button-05').css('background', 'transparent');
                $('.slippage-settings-button-1').css('background', '#0000 linear-gradient(268deg,#3989f9be,#3a3ad64f) 0 0 no-repeat');
                $('.slippage-settings-button-3').css('background', 'transparent');
                $('.slippage-percentage-section').css('border', '1px solid #7c859f');
            });
            $('.slippage-settings-button-3').click(function () {
                $('.slippage-settings-button-05').css('background', 'transparent');
                $('.slippage-settings-button-3').css('background', '#0000 linear-gradient(268deg,#3989f9be,#3a3ad64f) 0 0 no-repeat');
                $('.slippage-settings-button-1').css('background', 'transparent');
                $('.slippage-percentage-section').css('border', '1px solid #7c859f');
            });
            $('.slippage-percentage-section').click(function () {
                $('.slippage-settings-button-05').css('background', 'transparent');
                $('.slippage-settings-button-3').css('background', 'transparent');
                $('.slippage-settings-button-1').css('background', 'transparent');
                $('.slippage-percentage-section').css('border', '2px solid #3988f9');
            });
        });
        $(function () {
            $(".fee-fast-button").addClass("slippage-settings-color");
            $('.fee-normal-button').click(function () {
                $(".fee-normal-button").addClass("slippage-settings-color");
                $(".fee-fast-button").removeClass("slippage-settings-color");
                $(".fee-instant-button").removeClass("slippage-settings-color");
            });
            $('.fee-fast-button').click(function () {
                $(".fee-fast-button").addClass("slippage-settings-color");
                $(".fee-normal-button").removeClass("slippage-settings-color");
                $(".fee-instant-button").removeClass("slippage-settings-color");
            });
            $('.fee-instant-button').click(function () {
                $(".fee-instant-button").addClass("slippage-settings-color");
                $(".fee-fast-button").removeClass("slippage-settings-color");
                $(".fee-normal-button").removeClass("slippage-settings-color");
            });
        });
        $(document).ready(function () {
            $(".expert-mode-off-button").addClass("on-off-bg-color");
            $(".expert-mode-off-button").click(function () {
                $(".expert-mode-off-button").addClass("on-off-bg-color");
                $(".expert-mode-on-button").removeClass("on-off-bg-color");
            });
            $(".expert-mode-on-button").click(function () {
                $(".expert-mode-on-button").addClass("on-off-bg-color");
                $(".expert-mode-off-button").removeClass("on-off-bg-color");
            });
            $(".recipient-off-button").addClass("on-off-bg-color");
            $(".recipient-off-button").click(function () {
                $(".recipient-off-button").addClass("on-off-bg-color");
                $(".recipient-on-button").removeClass("on-off-bg-color");
            });
            $(".recipient-on-button").click(function () {
                $(".recipient-on-button").addClass("on-off-bg-color");
                $(".recipient-off-button").removeClass("on-off-bg-color");
            });
        });
        $(document).ready(function () {
            $(".swap-select-form-filter-button-1").addClass("swap-select-form-filter-button-color");
            $(".swap-select-form-filter-button-1").click(function () {
                $(".swap-select-form-filter-button-1").addClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").removeClass("swap-select-form-filter-button-color");
            });
            $(".swap-select-form-filter-button-2").click(function () {
                $(".swap-select-form-filter-button-1").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").addClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").removeClass("swap-select-form-filter-button-color");
            });
            $(".swap-select-form-filter-button-3").click(function () {
                $(".swap-select-form-filter-button-1").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").addClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").removeClass("swap-select-form-filter-button-color");
            });
            $(".swap-select-form-filter-button-4").click(function () {
                $(".swap-select-form-filter-button-1").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").addClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").removeClass("swap-select-form-filter-button-color");
            });
            $(".swap-select-form-filter-button-5").click(function () {
                $(".swap-select-form-filter-button-1").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-2").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-3").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-4").removeClass("swap-select-form-filter-button-color");
                $(".swap-select-form-filter-button-5").addClass("swap-select-form-filter-button-color");
            });
        });
    }


    render() {
        return (
        <div className="App">
        <Topnav />
                <section className="stake-top-banner-section">
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-5 ms-lg-5'>
                                <div className="d-flex flex-row justify-content-between px-2">
                                    <div className="">
                                        <span className='swap-section-text-1'>Stake CLARI for VERSE</span>
                                    </div>
                                </div>

                                <div className="d-flex flex-row justify-content-between px-2">
                                    <div className="">
                                        <span className='staking-section-text-1'>Stake CLARI to earn staking rewards and trading discounts</span>
                                    </div>
                                    <div className="">
                                        <LightTooltip title="When you stake $CLARI (ERC-20), you&amp;#x27;ll receive $VERSE - a composable and transferable token that represents your staking position.Your Stake will automatically receive auto-compounding Staking Rewards consisting of (i) block reward emissions, and (ii) 75% of all WHITELABELSWAP revenues.When you unstake $CLARI, you will receive Staking Rewards proportional to your unstake size." placement="right" >
                                            <BsInfoCircle className='staking-info-icon' />
                                        </LightTooltip>
                                    </div>
                                </div>
                                <div className='your-stake-details-section mt-2'>
                                    <div className="d-flex flex-row align-items-center justify-content-between staking-border-bottom pb-3">
                                        <div className="">
                                            <span className='staking-section-text-2'>Your Stake</span>
                                        </div>
                                        <div className="">
                                            <button className='staking-section-addwyzth-button' onClick={this.addmetamask}>Add VERSE<img className='metamask-logo' src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png" alt="metamak-logo" /></button>
                                            <AiOutlineReload className='staking-reload-icon ms-3' />
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-7'>
                                            <p className='staking-section-text-3'>Staking APY<LightTooltip title="Rates shown are estimates, and may fluctuate." placement="top" >

                                                <BsInfoCircle className='staking-info-icon-2' />

                                            </LightTooltip></p>
                                            <p className='staking-section-text-3'>Total CLARI<LightTooltip title="Rates shown are estimates, and may fluctuate." placement="top" >

                                                <BsInfoCircle className='staking-info-icon-2' />

                                            </LightTooltip></p>
                                            <p className='staking-section-text-3'>VERSE Balance<LightTooltip title="Rates shown are estimates, and may fluctuate." placement="top" >

                                                <BsInfoCircle className='staking-info-icon-2' />

                                            </LightTooltip></p>
                                        </div>
                                        <div className='col-5 text-end'>
                                            <p className='staking-section-text-4'>{this.state.setAPY}%</p>
                                            <p className='staking-section-text-4'>{this.state.setTotalTL2contractBalance}</p>
                                            <p className='staking-section-text-4'>-</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='swap-section-background mt-4'>
                                    <div className='deposit-switch-tabs-section'>
                                        <div className='row'>
                                            <div className='col'>
                                                <ul className="nav nav-pills nav-justified deposit-switch-tabs-section-button" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="pills-stake-tab" data-bs-toggle="pill" data-bs-target="#pills-stake" type="button" role="tab" aria-controls="pills-stake" aria-selected="true" onClick={() => this.staking("stake")}>Stake</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-unstake-tab" data-bs-toggle="pill" data-bs-target="#pills-unstake" type="button" role="tab" aria-controls="pills-unstake" aria-selected="false" onClick={() => this.staking("unstake")}>Unstake</button>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="tab-content mt-3" id="pills-tabContent">

                                                {/* ================================ BTC ============================ */}
                                                <div className="tab-pane fade show active" id="pills-stake" role="tabpanel" aria-labelledby="pills-stake-tab">
                                                    <div className='row from-section-swap-top'>
                                                        <div className='col'>
                                                            <div className='row mb-2'>
                                                                <span className='staking-section-text-5'>Send</span>
                                                            </div>
                                                            <div className='row'>
                                                            <input type="text" className="form-control-swap-text" placeholder='0' onChange={this.inputChangeHandler} id='depositAmount' value={this.state.setInputValue} />
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='row mb-2'>
                                                                <div className='col text-end'>
                                                                <span className='balance-text-1 me-2' onClick={this.goMax}>Balance: {this.state.setUserbalance}</span>
                                                                    <button type="button" className="swap-from-btn-max">Max</button>
                                                                </div>
                                                            </div>
                                                            <div className='row justify-content-end'>
                                                                <div className="col-12 from-section-coin-background" data-bs-toggle="modal" data-bs-target="#Swapcoinfrom">
                                                                    <div className="d-flex flex-row align-items-center">
                                                                        <div className="px-2">
                                                                            <p className='swap-section-text-3'>CLARI</p>
                                                                        </div>
                                                                        <div className="">
                                                                            <span><img src={logo} className='swap-from-image' /></span></div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className='row swap-button-rotate-section'>
                                                        <div className='col text-center'>
                                                            <HiArrowDown className='swap-button-rotate' />
                                                        </div>
                                                    </div>


                                                    <div className='row from-section-swap-top m-22'>
                                                        <div className='col'>
                                                            <div className='row mb-2'>
                                                                <span className='staking-section-text-5'>Receive:</span>
                                                            </div>
                                                            <div className='row'>
                                                            <input type="email" className="form-control-swap-text" placeholder='0' onChange={this.inputChangeHandler} id='depositAmount' value={((this.state.setInputValue * 79) / 100).toFixed(3)}/>
                                                            </div>

                                                        </div>
                                                        <div className='col'>
                                                            <div className='row justify-content-end mt-4'>
                                                                <div className="col-12 from-section-coin-background">
                                                                    <div className="d-flex flex-row align-items-center">
                                                                        <div className="px-2">
                                                                            <p className='swap-section-text-3'>VERSE</p>
                                                                        </div>
                                                                        <div className="">
                                                                            <span><img src={logo} className='swap-from-image' /></span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-row justify-content-between from-section-swap-top mt-2">
                                                        <div className="">
                                                            <span className='more-info-swap-accordian-text-1 me-auto'>
                                                                <CgArrowsExchangeAlt className='more-info-swap-accordian-icons me-1' />
                                                                1 VERSE = 1.255 CLARI </span>
                                                        </div>
                                                        <div className="">
                                                            <LightTooltip title="The ratio between CLARI and VERSE. As Staking Rewards accumulate, this ratio will change over time." placement="top" >
                                                                <BsInfoCircle className='staking-info-icon' />
                                                            </LightTooltip>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* ================================ BTC+CLARI ============================ */}
                                                <div className="tab-pane fade" id="pills-unstake" role="tabpanel" aria-labelledby="pills-unstake-tab">
                                                    <div className='row from-section-swap-top'>
                                                        <div className='col'>
                                                            <div className='row mb-2'>
                                                                <span className='staking-section-text-5'>Send</span>
                                                            </div>
                                                            <div className='row'>
                                                            <input type="email" className="form-control-swap-text" placeholder='0' onChange={this.inputChangeHandler} id='depositAmount' value={this.state.setInputValue}/>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='row mb-2'>
                                                                <div className='col text-end'>
                                                                    <span className='balance-text-1 me-2'>Balance: {this.state.setUserbalanceB}</span>
                                                                    <button type="button" className="swap-from-btn-max">Max</button>
                                                                </div>
                                                            </div>
                                                            <div className='row justify-content-end'>
                                                                <div className="col-12 from-section-coin-background" data-bs-toggle="modal" data-bs-target="#Swapcoinfrom">
                                                                    <div className="d-flex flex-row align-items-center">
                                                                        <div className="px-2">
                                                                            <p className='swap-section-text-3'>VERSE</p>
                                                                        </div>
                                                                        <div className="">
                                                                            <span><img src={logo} className='swap-from-image' /></span></div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row swap-button-rotate-section'>
                                                        <div className='col text-center'>
                                                            <HiArrowDown className='swap-button-rotate' />
                                                        </div>
                                                    </div>
                                                    <div className='row from-section-swap-top m-22'>
                                                        <div className='col'>
                                                            <div className='row mb-2'>
                                                                <span className='staking-section-text-5'>Receive:</span>
                                                            </div>
                                                            <div className='row'>
                                                                <input type="email" className="form-control-swap-text" placeholder='0' onChange={this.inputChangeHandler} id='depositAmount' value={((this.state.setInputValue * 126) / 100).toFixed(3)} />
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='row justify-content-end mt-4'>
                                                                <div className="col-12 from-section-coin-background">
                                                                    <div className="d-flex flex-row align-items-center">
                                                                        <div className="px-2">
                                                                            <p className='swap-section-text-3'>CLARI</p>
                                                                        </div>
                                                                        <div className="">
                                                                            <span><img src={logo} className='swap-from-image' /></span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-between from-section-swap-top mt-2">
                                                        <div className="">
                                                            <span className='more-info-swap-accordian-text-1 me-auto'>
                                                                <CgArrowsExchangeAlt className='more-info-swap-accordian-icons me-1' />
                                                                1 VERSE = 1.255 CLARI </span>
                                                        </div>
                                                        <div className="">
                                                            <LightTooltip title="The ratio between CLARI and VERSE. As Staking Rewards accumulate, this ratio will change over time." placement="top" >

                                                                <BsInfoCircle className='staking-info-icon' />
                                                            </LightTooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row connect-wallet-button-section mt-lg-4 my-4 mx-2'>
                                        <button className="btn btn-dark-blue button btn-lg d-block" onClick={() => {
                                        this.stake();
                                        this.state.setInputValue(''); 
                                    }}>CONNECT WALLET</button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>

        )
    }
}

export default Deposit;